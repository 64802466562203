import {
    ADD_NEW_FILETYPE, DELETE_FILETYPE, DELETE_FILETYPE_DISMISS, DELETE_FILETYPE_PREVIEW,
    DISABLE_FILETYPE, DISABLE_FILETYPE_DISMISS, DISABLE_FILETYPE_PREVIEW,
    EDIT_FILETYPE, EDIT_FILETYPE_DISMISS, EDIT_FILETYPE_PREVIEW, SET_FILETYPE_DATA,
    FILETYPE_FETCHING_COMPLETE, FILETYPE_FETCHING_INIT, FILETYPE_FETCHING_DISMISS, SET_FILETYPE_PAGE,
    ADD_NEW_FILETYPE_DISMISS, ADD_NEW_FILETYPE_PREVIEW, SET_FILETYPE_ROLES
} from "./types";

const initialState = {
    filetype_list: [],//holds file type list array
    filetypelist_fetching: false,//indicaction for fetch in progress
    filetypelist_refetch: false,//indication to refetch current file type list array
    filetypelist_refetch_force: false,//indication to force refetch file type list array from beginning 
    edit_filetype: null,//holds initial data of file type being edited
    delete_filetype: null,//holds initial data of file type opted to be deleted
    disable_filetype: null,//holds initial data of file type opted to be disabled,
    search: "",//search keyword on file type listing
    page: 1,//page number on file type listing
    perpage: 10,//perpage limit on file type listing
    total_filetypes: 0,//total count
    //new file type params
    file_type_desc: "",
    //edit file type params
    eFile_type_desc: ""
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_FILETYPE_DATA:
            return { ...state, ...action.payload };
        case FILETYPE_FETCHING_INIT: {
            return { ...state, filetypelist_fetching: true, filetypelist_refetch: false, filetypelist_refetch_force: false };
        }
        case FILETYPE_FETCHING_COMPLETE: {
            return { ...state, filetypelist_fetching: false, ...action.payload };
        }
        case FILETYPE_FETCHING_DISMISS: {
            return { ...state, filetypelist_fetching: false };
        }
        case SET_FILETYPE_PAGE: {
            return { ...state, filetypelist_refetch: true, page: action.payload };
        }
        case ADD_NEW_FILETYPE: {
            return { ...state, filetypelist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_FILETYPE_PREVIEW: {
            return { ...state, new_filetype_popup: true, file_type_desc: "" }
        }
        case ADD_NEW_FILETYPE_DISMISS: {
            return { ...state, new_filetype_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_FILETYPE_PREVIEW: {
            return { ...state, eFile_error: "", ...action.payload }
        }
        case EDIT_FILETYPE: {
            return { ...state, filetypelist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_FILETYPE_DISMISS: {
            return { ...state, edit_filetype: null }
        }
        case DELETE_FILETYPE_PREVIEW: {
            return { ...state, delete_filetype: action.payload }
        }
        case DELETE_FILETYPE: {
            return { ...state, filetypelist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_FILETYPE_DISMISS: {
            return { ...state, delete_filetype: null }
        }
        case DISABLE_FILETYPE_PREVIEW: {
            return { ...state, disable_filetype: action.payload }
        }
        case DISABLE_FILETYPE: {
            return { ...state, disable_filetype: null, filetypelist_refetch: true }
        }
        case DISABLE_FILETYPE_DISMISS: {
            return { ...state, disable_filetype: null }
        }
        case SET_FILETYPE_ROLES: {
            return { ...state, filetype_roles: action.payload }
        }
        default:
            return state;
    }
};
