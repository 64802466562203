export const SET_LOCATIONTYPE_DATA = "SET_LOCATIONTYPE_DATA";
export const LOCATIONTYPE_FETCHING_INIT = "LOCATIONTYPE_FETCHING_INIT"
export const LOCATIONTYPE_FETCHING_COMPLETE = "LOCATIONTYPE_FETCHING_COMPLETE"
export const LOCATIONTYPE_FETCHING_DISMISS = "LOCATIONTYPE_FETCHING_DISMISS"
export const ADD_NEW_LOCATIONTYPE = "ADD_NEW_LOCATIONTYPE"
export const ADD_NEW_LOCATIONTYPE_PREVIEW = "ADD_NEW_LOCATIONTYPE_PREVIEW"
export const ADD_NEW_LOCATIONTYPE_DISMISS = "ADD_NEW_LOCATIONTYPE_DISMISS"
export const EDIT_LOCATIONTYPE_PREVIEW = "EDIT_LOCATIONTYPE_PREVIEW"
export const EDIT_LOCATIONTYPE = "EDIT_LOCATIONTYPE"
export const EDIT_LOCATIONTYPE_DISMISS = "EDIT_LOCATIONTYPE_DISMISS"
export const DELETE_LOCATIONTYPE_PREVIEW = "DELETE_LOCATIONTYPE_PREVIEW"
export const DELETE_LOCATIONTYPE_DISMISS = "DELETE_LOCATIONTYPE_DISMISS"
export const DELETE_LOCATIONTYPE = "DELETE_LOCATIONTYPE"
export const DISABLE_LOCATIONTYPE_PREVIEW = "DISABLE_LOCATIONTYPE_PREVIEW"
export const DISABLE_LOCATIONTYPE_DISMISS = "DISABLE_LOCATIONTYPE_DISMISS"
export const DISABLE_LOCATIONTYPE = "DISABLE_LOCATIONTYPE"
export const SET_LOCATIONTYPE_ROLES = "SET_LOCATIONTYPE_ROLES"
export const SET_LOCATIONTYPE_PAGE = "SET_LOCATIONTYPE_PAGE"
