export const SET_VAT_DATA = "SET_VAT_DATA";
export const VAT_FETCHING_INIT = "VAT_FETCHING_INIT"
export const VAT_FETCHING_COMPLETE = "VAT_FETCHING_COMPLETE"
export const VAT_FETCHING_DISMISS = "VAT_FETCHING_DISMISS"
export const ADD_NEW_VAT = "ADD_NEW_VAT"
export const ADD_NEW_VAT_PREVIEW = "ADD_NEW_VAT_PREVIEW"
export const ADD_NEW_VAT_DISMISS = "ADD_NEW_VAT_DISMISS"
export const EDIT_VAT_PREVIEW = "EDIT_VAT_PREVIEW"
export const EDIT_VAT = "EDIT_VAT"
export const EDIT_VAT_DISMISS = "EDIT_VAT_DISMISS"
export const DELETE_VAT_PREVIEW = "DELETE_VAT_PREVIEW"
export const DELETE_VAT_DISMISS = "DELETE_VAT_DISMISS"
export const DELETE_VAT = "DELETE_VAT"
export const DISABLE_VAT_PREVIEW = "DISABLE_VAT_PREVIEW"
export const DISABLE_VAT_DISMISS = "DISABLE_VAT_DISMISS"
export const DISABLE_VAT = "DISABLE_VAT"
export const SET_VAT_ROLES = "SET_VAT_ROLES"
export const SET_VAT_PAGE = "SET_VAT_PAGE"
