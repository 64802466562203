import {
    ADD_NEW_USER_ROLE, DELETE_USER_ROLE, DELETE_USER_ROLE_DISMISS, DELETE_USER_ROLE_PREVIEW,
    DISABLE_USER_ROLE, DISABLE_USER_ROLE_DISMISS, DISABLE_USER_ROLE_PREVIEW,
    EDIT_USER_ROLE, EDIT_USER_ROLE_DISMISS, EDIT_USER_ROLE_PREVIEW, SET_USER_ROLE_DATA,
    USER_ROLE_FETCHING_COMPLETE, USER_ROLE_FETCHING_INIT, USER_ROLE_FETCHING_DISMISS, SET_USER_ROLE_PAGE,
    ADD_NEW_USER_ROLE_DISMISS, ADD_NEW_USER_ROLE_PREVIEW, SET_USER_ROLES,SET_USER_ROLE_ORGANISATION, SET_DASHBOARD_WIDGET_LIST
} from "./types";

const initialState = {
    user_role_roles: [],
    user_role_list: [],//holds user list array
    organisation_type_list: [],//holds organisation_type array
    userrolelist_fetching: false,//indicaction for fetch in progress
    userrolelist_refetch: false,//indication to refetch current user list array
    userrolelist_refetch_force: false,//indication to force refetch user list array from beginning 
    edit_user_role: null,//holds initial data of user being edited
    delete_user_role: null,//holds initial data of user opted to be deleted
    disable_user_role: null,//holds initial data of user opted to be disabled,
    search: "",//search keyword on user listing
    page: 1,//page number on user listing
    perpage: 10,//perpage limit on user listing
    total_user_roles: 0,//total count
    new_user_role_popup:false,
    //new user params
    nU_name: "",
    nU_organisation:"",
    //edit user params
    eU_name: "",
    eU_organisation: "",
    dashboardWidgetList: [],
    dashBoardWidgetOption: []
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_USER_ROLE_DATA:
            return { ...state, ...action.payload };
        case USER_ROLE_FETCHING_INIT: {
            return { ...state, userrolelist_fetching: true , userrolelist_refetch: false, userrolelist_refetch_force: false };
        }
        case USER_ROLE_FETCHING_COMPLETE: {
            return { ...state, userrolelist_fetching: false, ...action.payload };
        }
        case USER_ROLE_FETCHING_DISMISS: {
            return { ...state, userrolelist_fetching: false };
        }
        case SET_USER_ROLE_PAGE: {
            return { ...state, userrolelist_refetch: true, page: action.payload };
        }
        case ADD_NEW_USER_ROLE: {
            return { ...state, userrolelist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_USER_ROLE_PREVIEW: {
         return { ...state, new_user_role_popup: true, nU_name: "",nU_organisation:"", nU_error: "", dashBoardWidgetOption: []  }
        }
        case ADD_NEW_USER_ROLE_DISMISS: {
            return { ...state, new_user_role_popup: false, ...action.payload, add_btn_disable: false,  dashBoardWidgetOption: [] }
        }
        case EDIT_USER_ROLE_PREVIEW: {
            return { ...state, eU_error: "", ...action.payload }
        }
        case EDIT_USER_ROLE: {
            return { ...state, userrolelist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_USER_ROLE_DISMISS: {
            return { ...state, edit_user_role: null }
        }
        case DELETE_USER_ROLE_PREVIEW: {
            return { ...state, delete_user_role: action.payload }
        }
        case DELETE_USER_ROLE: {
            return { ...state, userrolelist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_USER_ROLE_DISMISS: {
            return { ...state, delete_user_role: null }
        }
        case DISABLE_USER_ROLE_PREVIEW: {
            return { ...state, disable_user_role: action.payload }
        }
        case DISABLE_USER_ROLE: {
            return { ...state, disable_user_role: null, userrolelist_refetch: true }
        }
        case DISABLE_USER_ROLE_DISMISS: {
            return { ...state, disable_user_role: null }
        }
        case SET_USER_ROLES: {
            return { ...state, user_roles: action.payload }
        }
        case SET_USER_ROLE_ORGANISATION: {
            return { ...state, organisation_type_list: action.payload }
        }
        case SET_DASHBOARD_WIDGET_LIST: {
            return {...state, dashboardWidgetList: action.payload}
        }
        default:
            return state;
    }
};
