export const SET_ASSETMODEL_DATA = "SET_ASSETMODEL_DATA";
export const ASSETMODEL_FETCHING_INIT = "ASSETMODEL_FETCHING_INIT"
export const ASSETMODEL_FETCHING_COMPLETE = "ASSETMODEL_FETCHING_COMPLETE"
export const ASSETMODEL_FETCHING_DISMISS = "ASSETMODEL_FETCHING_DISMISS"
export const ADD_NEW_ASSETMODEL = "ADD_NEW_ASSETMODEL"
export const ADD_NEW_ASSETMODEL_PREVIEW = "ADD_NEW_ASSETMODEL_PREVIEW"
export const ADD_NEW_ASSETMODEL_DISMISS = "ADD_NEW_ASSETMODEL_DISMISS"
export const EDIT_ASSETMODEL_PREVIEW = "EDIT_ASSETMODEL_PREVIEW"
export const EDIT_ASSETMODEL = "EDIT_ASSETMODEL"
export const EDIT_ASSETMODEL_DISMISS = "EDIT_ASSETMODEL_DISMISS"
export const DELETE_ASSETMODEL_PREVIEW = "DELETE_ASSETMODEL_PREVIEW"
export const DELETE_ASSETMODEL_DISMISS = "DELETE_ASSETMODEL_DISMISS"
export const DELETE_ASSETMODEL = "DELETE_ASSETMODEL"
export const DISABLE_ASSETMODEL_PREVIEW = "DISABLE_ASSETMODEL_PREVIEW"
export const DISABLE_ASSETMODEL_DISMISS = "DISABLE_ASSETMODEL_DISMISS"
export const DISABLE_ASSETMODEL = "DISABLE_ASSETMODEL"
export const SET_ASSETMODEL_ROLES = "SET_ASSETMODEL_ROLES"
export const SET_ASSETMODEL_PAGE = "SET_ASSETMODEL_PAGE"
export const ASSETMODEL_CREATE_DETAILS_FETCHING_COMPLETE = "ASSETMODEL_CREATE_DETAILS_FETCHING_COMPLETE"
