import {
    FETCH_ASNFILES, FETCH_ASNFILES_DISMISS, FETCH_ASNFILES_INIT, SET_VALIDATIONREQUIRED_DATA, SET_ASNFILES_LIST_PAGE, VIEW_VALIDATION_FILE_DETAILS, HIDE_VALIDATION_FILE_DETAILS,
    REJECT_VALIDATION_COMPLETE, HIDE_VALIDATION_FILE_REJECT, OPEN_REJECT_VALIDATION_POPUP, ASN_APPROVE_COMPLETE,
    FETCH_ASNFILE_RECORDS_INIT, FETCH_ASNFILE_RECORDS_COMPLETE, FETCH_ASNFILE_RECORDS_FAILED, SET_RECORD_LIST_PAGE, FILE_DOWNLOADING,
    FETCH_BULKFILE_RECORDS_COMPLETE, FETCH_REPAT_UPDATE_RECORDS, REJECT_ASSET_VALIDATION_SUCCESS, ACCEPT_ASSET_VALIDATION_SUCCESS
} from "./types";

const initialState = {
    asnfiles_list: [],//holds asn files list array
    asn_records_list: [],//holds asn file records list array
    bulk_records_list: [],//holds bulk update file records list array
    asnfileslist_fetching: false,//indicaction for fetch in progress
    asnfileslist_refetch: false,//indication to refetch current asn files list array
    recordslist_refetch: false,//indication to refetch current asn file records list array
    asnfileslist_refetch_force: false,//indication to force refetch asn files list array from beginning 
    recordslist_refetch_force: false,//indication to force refetch asn file records list array from beginning 
    delete_asnfiles: null,//holds initial data of asn files opted to be deleted
    search: "",//search keyword on asn files listing
    page: 1,//page number on asn files listing
    record_page: 1,//page number on asn files records listing
    perpage: 10,//perpage limit on asn files listing
    record_perpage: 15,//perpage limit on asn files records listing
    total_asnfiles: 0,//total count
    data_fetching: false,
    view_details: 0,
    disable_remove_btn: true,
    validationfile_details: null,
    view_reject_popup: false,
    asn_file_id: null,
    approve_btn_disable: false,
    download_btn_disable: false,
    bulk_records_refetch: false,
    bulk_update_records_list: [],
    bulk_records_count: {},
    bulk_validation_file_data: {},
    reject_file_type: "",
    repat_records_list: [],
    total_repat_records: null,
    repat_records_refetch: false,
    view_approve_popup: false,
    approve_validation_id: ""
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case FILE_DOWNLOADING:
            return { ...state, }
        case REJECT_ASSET_VALIDATION_SUCCESS:
            return { ...state, ...action.payload }
        case ACCEPT_ASSET_VALIDATION_SUCCESS:
            return { ...state, ...action.payload }
        case FETCH_REPAT_UPDATE_RECORDS:
            return { ...state, ...action.payload }
        case SET_RECORD_LIST_PAGE:
            return { ...state, recordslist_refetch: true, record_page: action.payload }
        case FETCH_BULKFILE_RECORDS_COMPLETE:
            return { ...state, ...action.payload }
        case FETCH_ASNFILE_RECORDS_FAILED:
            return { ...state, }
        case FETCH_ASNFILE_RECORDS_COMPLETE:
            return { ...state, ...action.payload, recordslist_refetch: false, }
        case FETCH_ASNFILE_RECORDS_INIT:
            return { ...state, recordslist_refetch: false, recordslist_refetch_force: false }
        case SET_VALIDATIONREQUIRED_DATA:
            return { ...state, ...action.payload };
        case VIEW_VALIDATION_FILE_DETAILS:
            return { ...state, ...action.payload, };
        case HIDE_VALIDATION_FILE_DETAILS:
            return { ...state, view_details: 0 }
        case HIDE_VALIDATION_FILE_REJECT:
            return { ...state, view_reject_popup: false, view_details: 1, }
        case ASN_APPROVE_COMPLETE:
            return { ...state, ...action.payload, view_details: 0, asnfileslist_refetch: true }
        case OPEN_REJECT_VALIDATION_POPUP:
            return { ...state, ...action.payload, view_reject_popup: true }
        case REJECT_VALIDATION_COMPLETE:
            return { ...state, ...action.payload, view_reject_popup: false, view_details: 0, asnfileslist_refetch: true }
        case SET_ASNFILES_LIST_PAGE:
            return { ...state, asnfileslist_refetch: true, page: action.payload };
        case FETCH_ASNFILES_INIT:
            return { ...state, asnfileslist_refetch: false, asnfileslist_refetch_force: false };
        case FETCH_ASNFILES_DISMISS:
            return { ...state, };
        case FETCH_ASNFILES:
            return { ...state, asnfileslist_refetch: false, ...action.payload };
        default:
            return state;
    }
};
