export const SET_SUPPLIERCODE_DATA = "SET_SUPPLIERCODE_DATA";
export const SUPPLIERCODE_FETCHING_INIT = "SUPPLIERCODE_FETCHING_INIT"
export const SUPPLIERCODE_FETCHING_COMPLETE = "SUPPLIERCODE_FETCHING_COMPLETE"
export const SUPPLIERCODE_FETCHING_DISMISS = "SUPPLIERCODE_FETCHING_DISMISS"
export const ADD_NEW_SUPPLIERCODE = "ADD_NEW_SUPPLIERCODE"
export const ADD_NEW_SUPPLIERCODE_PREVIEW = "ADD_NEW_SUPPLIERCODE_PREVIEW"
export const ADD_NEW_SUPPLIERCODE_DISMISS = "ADD_NEW_SUPPLIERCODE_DISMISS"
export const EDIT_SUPPLIERCODE_PREVIEW = "EDIT_SUPPLIERCODE_PREVIEW"
export const EDIT_SUPPLIERCODE = "EDIT_SUPPLIERCODE"
export const EDIT_SUPPLIERCODE_DISMISS = "EDIT_SUPPLIERCODE_DISMISS"
export const DELETE_SUPPLIERCODE_PREVIEW = "DELETE_SUPPLIERCODE_PREVIEW"
export const DELETE_SUPPLIERCODE_DISMISS = "DELETE_SUPPLIERCODE_DISMISS"
export const DELETE_SUPPLIERCODE = "DELETE_SUPPLIERCODE"
export const DISABLE_SUPPLIERCODE_PREVIEW = "DISABLE_SUPPLIERCODE_PREVIEW"
export const DISABLE_SUPPLIERCODE_DISMISS = "DISABLE_SUPPLIERCODE_DISMISS"
export const DISABLE_SUPPLIERCODE = "DISABLE_SUPPLIERCODE"
export const SET_SUPPLIERCODE_ROLES = "SET_SUPPLIERCODE_ROLES"
export const SET_SUPPLIERCODE_PAGE = "SET_SUPPLIERCODE_PAGE"
