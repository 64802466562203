import React from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import Loading from "../components/Loading/index"
function AuthRoute(props) {
    const { component: Component, ...rest } = props;
    //load user authentication status from redux
    let isAuthenticated = props.authenticated || false;
    let role_permission = props.role_permission
    let path_name = "/dashboard/settings"
    role_permission.map(item => {
        if (item.module_name == "View Dashboard") {
            path_name = "/dashboard"
        }
    })
    
    if (props.authLoading) return <Loading />;

    return (
        <Route
            {...rest}
            render={() => {
                return isAuthenticated ? (
                    <Redirect
                        to={{
                            pathname: path_name,
                        }}
                    />
                ) : (
                    <Component {...props} />
                );
            }}
        />
    );
}
AuthRoute.propTypes = {
    component: PropTypes.object.isRequired,
    authenticated: PropTypes.bool,
    role_permission: PropTypes.object.isRequired, 
    authLoading: PropTypes.bool,
  };
const mapStateToProps = ({ auth }) => ({
    ...auth,
});

export default connect(mapStateToProps)(withRouter(AuthRoute));
