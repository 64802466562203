export const SET_EVENTTYPE_DATA = "SET_EVENTTYPE_DATA";
export const EVENTTYPE_FETCHING_INIT = "EVENTTYPE_FETCHING_INIT"
export const EVENTTYPE_FETCHING_COMPLETE = "EVENTTYPE_FETCHING_COMPLETE"
export const EVENTTYPE_FETCHING_DISMISS = "EVENTTYPE_FETCHING_DISMISS"
export const ADD_NEW_EVENTTYPE = "ADD_NEW_EVENTTYPE"
export const ADD_NEW_EVENTTYPE_PREVIEW = "ADD_NEW_EVENTTYPE_PREVIEW"
export const ADD_NEW_EVENTTYPE_DISMISS = "ADD_NEW_EVENTTYPE_DISMISS"
export const EDIT_EVENTTYPE_PREVIEW = "EDIT_EVENTTYPE_PREVIEW"
export const EDIT_EVENTTYPE = "EDIT_EVENTTYPE"
export const EDIT_EVENTTYPE_DISMISS = "EDIT_EVENTTYPE_DISMISS"
export const DELETE_EVENTTYPE_PREVIEW = "DELETE_EVENTTYPE_PREVIEW"
export const DELETE_EVENTTYPE_DISMISS = "DELETE_EVENTTYPE_DISMISS"
export const DELETE_EVENTTYPE = "DELETE_EVENTTYPE"
export const DISABLE_EVENTTYPE_PREVIEW = "DISABLE_EVENTTYPE_PREVIEW"
export const DISABLE_EVENTTYPE_DISMISS = "DISABLE_EVENTTYPE_DISMISS"
export const DISABLE_EVENTTYPE = "DISABLE_EVENTTYPE"
export const SET_EVENTTYPE_ROLES = "SET_EVENTTYPE_ROLES"
export const SET_EVENTTYPE_PAGE = "SET_EVENTTYPE_PAGE"
