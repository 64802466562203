import {
    ADD_NEW_USER, DELETE_USER, DELETE_USER_DISMISS, DELETE_USER_PREVIEW,
    DISABLE_USER, DISABLE_USER_DISMISS, DISABLE_USER_PREVIEW,
    EDIT_USER, EDIT_USER_DISMISS, EDIT_USER_PREVIEW, SET_USER_DATA,
    USER_FETCHING_COMPLETE, USER_FETCHING_INIT, USER_FETCHING_DISMISS, SET_USER_PAGE,
    ADD_NEW_USER_DISMISS, ADD_NEW_USER_PREVIEW, SET_USER_ROLES,ADD_NEW_USER_ERROR,SET_USER_COMPANY,
} from "./types";

const initialState = {
    user_roles: [],
    user_list: [],//holds user list array
    userlist_fetching: false,//indicaction for fetch in progress
    userlist_refetch: false,//indication to refetch current user list array
    userlist_refetch_force: false,//indication to force refetch user list array from beginning 
    edit_user: null,//holds initial data of user being edited
    delete_user: null,//holds initial data of user opted to be deleted
    disable_user: null,//holds initial data of user opted to be disabled,
    search: "",//search keyword on user listing
    page: 1,//page number on user listing
    perpage: 10,//perpage limit on user listing
    total_users: 0,//total count
    //new user params
    showPassword:false,
    nU_fname: "",nU_lname: "",nU_name: "",nU_mobile: "", nU_email: "", nU_password: "",nU_company:"", nU_role: "", nU_error: "",
    //edit user params
    eU_fname: "",eU_lname: "",eU_name: "",eU_mobile: "", eU_email: "", eU_password: "",eU_company:"", eU_role: "", eU_error: "",eU_role_id:""
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_USER_DATA:
            return { ...state, ...action.payload };
        case USER_FETCHING_INIT: {
            return { ...state, userlist_fetching: true, userlist_refetch: false, userlist_refetch_force: false };
        }
        case USER_FETCHING_COMPLETE: {
            return { ...state, userlist_fetching: false, ...action.payload };
        }
        case USER_FETCHING_DISMISS: {
            return { ...state, userlist_fetching: false };
        }
        case SET_USER_PAGE: {
            return { ...state, userlist_refetch: true, page: action.payload };
        }
        case ADD_NEW_USER: {
            return { ...state, userlist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_USER_PREVIEW: {
            return { ...state, new_user_popup: true, nU_name: "", nU_email: "",nU_company:"", nU_phone: "", nU_role: "", nU_error: "" }
        }
        case ADD_NEW_USER_DISMISS: {
            return { ...state, new_user_popup: false,nU_fname: "",nU_lname: "",nU_name: "",nU_mobile: "", nU_email: "", nU_password: "", nU_role: "", nU_error: "",nU_company:"", ...action.payload, add_btn_disable: false }
        }
        case ADD_NEW_USER_ERROR: {
            return { ...state, new_user_popup: true, ...action.payload, add_btn_disable: false }
        }
        case EDIT_USER_PREVIEW: {
            return { ...state, eU_error: "", ...action.payload }
        }
        case EDIT_USER: {
            return { ...state, userlist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_USER_DISMISS: {
            return { ...state, edit_user: null }
        }
        case DELETE_USER_PREVIEW: {
            return { ...state, delete_user: action.payload }
        }
        case DELETE_USER: {
            return { ...state, userlist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_USER_DISMISS: {
            return { ...state, delete_user: null }
        }
        case DISABLE_USER_PREVIEW: {
            return { ...state, disable_user: action.payload }
        }
        case DISABLE_USER: {
            return { ...state, disable_user: null, userlist_refetch: true }
        }
        case DISABLE_USER_DISMISS: {
            return { ...state, disable_user: null }
        }
        case SET_USER_ROLES: {
            return { ...state, user_roles: action.payload }
        }
        case SET_USER_COMPANY: {
            return { ...state, company: action.payload }
        }
        default:
            return state;
    }
};
