export const SET_UPLOAD_SUPPLIER_FILE_DATA = "SET_UPLOAD_SUPPLIER_FILE_DATA";
export const OPEN_NEW_UPLOAD_POPUP = "OPEN_NEW_UPLOAD_POPUP";
export const CLOSE_NEW_UPLOAD_POPUP = "CLOSE_NEW_UPLOAD_POPUP";
export const DATA_FETCHING_INIT = "DATA_FETCHING_INIT";
export const DATA_FETCHING_DISMISS = "DATA_FETCHING_DISMISS";
export const FETCH_INSTALL_SUPPLIER_FILE_LIST_COMPLETE = "FETCH_INSTALL_SUPPLIER_FILE_LIST_COMPLETE";
export const SET_FILTER_STATUS = "SET_FILTER_STATUS";
export const SET_SUPPLIER_FILE_DATA = "SET_SUPPLIER_FILE_DATA";
export const CLOSE_POP_UP = "CLOSE_POP_UP";
export const OPEN_ERR_FILE_DOWNLOAD_POPUP = "OPEN_ERR_FILE_DOWNLOAD_POPUP";
export const CLOSE_ERR_FILE_DOWNLOAD_POPUP = "CLOSE_ERR_FILE_DOWNLOAD_POPUP";
export const DATA_SUBMIT_INIT = "DATA_SUBMIT_INIT";
export const UPLOAD_SUPPLIER_FILE_COMPLETE = "UPLOAD_SUPPLIER_FILE_COMPLETE";
export const DATA_SUBMIT_DISMISS = "DATA_SUBMIT_DISMISS";
export const ACCEPT_SUPPLIER_VALIDATION_SUCCESS = "ACCEPT_SUPPLIER_VALIDATION_SUCCESS";
export const HIDE_SUPPLIER_VALIDATION_FILE_REJECT ="HIDE_SUPPLIER_VALIDATION_FILE_REJECT";
export const SUPPLIER_REJECT_VALIDATION_COMPLETE ="SUPPLIER_REJECT_VALIDATION_COMPLETE";
export const SET_COS_FILE_DATA = "SET_COS_FILE_DATA";
export const VIEW_DATA_FETCHING_INIT="VIEW_DATA_FETCHING_INIT";
export const VIEW_DATA_FETCHING_DISMISS="VIEW_DATA_FETCHING_DISMISS";
export const FETCH_VIEW_LIST_COMPLETE="FETCH_VIEW_LIST_COMPLETE";
export const OPEN_NEW_PROCESS_FAILED = "OPEN_NEW_PROCESS_FAILED";

const uploadinstauninstaState ={
    up_install_upload_popup:false,
    supplier_file:[],
    supplier_file_title:"",
    search:"",
    page:1,
    perpage:10,
    filter_status_upload:"",
    install_supplier:[],
    insta_supplier_list_refetch: false,
    details_view:0,
    open_popup:false,
    add_btn_disable: false,
    view_supplier_reject_popup : false,
    supplier_confirm_btn_disable : false,
    data_fetching:true,
    view_page:1,
    view_perpage:10,
    view_data_list:[],
    view_insta_supplier_list_refetch:true,
    download_popup:false,
    supplier_download_data:[],
    search_flag:0,
    supplier_filter_list:[],
    installer_filter_list:[],
    installer_filter:'',
    supplier_filter:'',
    view_search:''
};

export default (state = uploadinstauninstaState, action = {}) => {
    switch (action.type) {
        case SET_UPLOAD_SUPPLIER_FILE_DATA:
            return { ...state, ...action.payload };
        case DATA_FETCHING_INIT:
            return { ...state, ...action.payload, data_fetching: true, };
        case DATA_FETCHING_DISMISS:
            return { ...state, ...action.payload, data_fetching: false };
        case OPEN_NEW_UPLOAD_POPUP:
            return { ...state, ...action.payload };
        case CLOSE_NEW_UPLOAD_POPUP:
            return { ...state, ...action.payload };
        case FETCH_INSTALL_SUPPLIER_FILE_LIST_COMPLETE:
            return {...state,...action.payload};
        case UPLOAD_SUPPLIER_FILE_COMPLETE:
            return {...state,...action.payload};
        case SET_FILTER_STATUS:
            return { ...state, ...action.payload };
        case SET_SUPPLIER_FILE_DATA:
            return { ...state, ...action.payload};
        case CLOSE_POP_UP:
            return {...state,...action.payload};
        case OPEN_ERR_FILE_DOWNLOAD_POPUP:
            return { ...state, ...action.payload };
        case DATA_SUBMIT_INIT:
            return { ...state, ...action.payload, add_btn_disable:true };
        case CLOSE_ERR_FILE_DOWNLOAD_POPUP:
            return { ...state, ...action.payload };
        case DATA_SUBMIT_DISMISS:
            return { ...state, ...action.payload, add_btn_disable: false};
        case ACCEPT_SUPPLIER_VALIDATION_SUCCESS:
            return { ...state, ...action.payload };
        case HIDE_SUPPLIER_VALIDATION_FILE_REJECT:
            return { ...state, view_supplier_reject_popup: false, details_view: 1, }
        case SUPPLIER_REJECT_VALIDATION_COMPLETE:
            return { ...state, ...action.payload, view_supplier_reject_popup: false, details_view: 0,  }
        case SET_COS_FILE_DATA:
            return { ...state, ...action.payload}
        case VIEW_DATA_FETCHING_INIT:
            return{...state,...action.payload,view_data_fetching: true,};
        case VIEW_DATA_FETCHING_DISMISS:
            return{...state,...action.payload,view_data_fetching: false,view_insta_supplier_list_refetch:false};
        case FETCH_VIEW_LIST_COMPLETE:
            return{...state,...action.payload};
        case OPEN_NEW_PROCESS_FAILED:
            return { ...state,...action.payload };
        default:
            return state;
        
    }
}