export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";
export const MAP_USER_FETCHING_INIT = "MAP_USER_FETCHING_INIT";
export const MAP_USER_FETCHING_COMPLETE = "MAP_USER_FETCHING_COMPLETE";
export const MAP_USER_FETCHING_DISMISS = "MAP_USER_FETCHING_DISMISS";
export const TRIAGE_USER_FETCHING_INIT = "TRIAGE_USER_FETCHING_INIT";
export const TRIAGE_USER_FETCHING_COMPLETE = "TRIAGE_USER_FETCHING_COMPLETE";
export const TRIAGE_USER_FETCHING_DISMISS = "TRIAGE_USER_FETCHING_DISMISS";
export const DATA_FETCHING_INIT = "DATA_FETCHING_INIT";
export const DATA_FETCHING_DISMISS = "DATA_FETCHING_DISMISS";
export const DASHBOARD_FETCH_COMPLETE="DASHBOARD_FETCH_COMPLETE";
const dashboardState = {
    to: "",
    from: "",
    month:"",
    year:"",
    map_user_data: [],
    company_id: "",
    date: [],
    date_popup: false,
    add_btn_disable: false,
    showDate: false,
    triage_user_data:[],
    selectedLabel:"",
    dashboard_refetch:false,
    date_prefer:"",
    data_fetching:false,
    triage_fetching:false,
    dashboardWidgets:[]
};
export default (state = dashboardState, action = {}) => {
    switch (action.type) {
        case SET_DASHBOARD_DATA:
            return { ...state, ...action.payload };
        case MAP_USER_FETCHING_INIT:
            return { ...state, ...action.payload ,data_fetching:true};
        case MAP_USER_FETCHING_COMPLETE:
            return { ...state, ...action.payload };
        case MAP_USER_FETCHING_DISMISS:
            return { ...state, ...action.payload , data_fetching:false};
        case TRIAGE_USER_FETCHING_INIT:
            return { ...state, ...action.payload ,triage_fetching:true};
        case TRIAGE_USER_FETCHING_COMPLETE:
            return { ...state, ...action.payload };
        case TRIAGE_USER_FETCHING_DISMISS:
            return { ...state, ...action.payload , triage_fetching:false};
            case DATA_FETCHING_INIT:
                return { ...state, ...action.payload };
            case DATA_FETCHING_DISMISS:
                return { ...state, ...action.payload };
            case DASHBOARD_FETCH_COMPLETE:
                return{...state,...action.payload}
        default:
            return state;
    }
};