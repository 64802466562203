import {
    ADD_NEW_PROPERTYFUELCATEGORY, DELETE_PROPERTYFUELCATEGORY, DELETE_PROPERTYFUELCATEGORY_DISMISS, DELETE_PROPERTYFUELCATEGORY_PREVIEW,
    DISABLE_PROPERTYFUELCATEGORY, DISABLE_PROPERTYFUELCATEGORY_DISMISS, DISABLE_PROPERTYFUELCATEGORY_PREVIEW,
    EDIT_PROPERTYFUELCATEGORY, EDIT_PROPERTYFUELCATEGORY_DISMISS, EDIT_PROPERTYFUELCATEGORY_PREVIEW, SET_PROPERTYFUELCATEGORY_DATA,
    PROPERTYFUELCATEGORY_FETCHING_COMPLETE, PROPERTYFUELCATEGORY_FETCHING_INIT, PROPERTYFUELCATEGORY_FETCHING_DISMISS, SET_PROPERTYFUELCATEGORY_PAGE,
    ADD_NEW_PROPERTYFUELCATEGORY_DISMISS, ADD_NEW_PROPERTYFUELCATEGORY_PREVIEW, SET_PROPERTYFUELCATEGORY_ROLES
} from "./types";

const initialState = {
    propertyfuelcategory_list: [],//holds propertyfuel category list array
    propertyfuelcategorylist_fetching: false,//indicaction for fetch in progress
    propertyfuelcategorylist_refetch: false,//indication to refetch current propertyfuel category list array
    propertyfuelcategorylist_refetch_force: false,//indication to force refetch propertyfuel category list array from beginning 
    edit_propertyfuelcategory: null,//holds initial data of propertyfuel category being edited
    delete_propertyfuelcategory: null,//holds initial data of propertyfuel category opted to be deleted
    disable_propertyfuelcategory: null,//holds initial data of propertyfuel category opted to be disabled,
    search: "",//search keyword on propertyfuel category listing
    page: 1,//page number on propertyfuel category listing
    perpage: 10,//perpage limit on propertyfuel category listing
    total_propertyfuelcategories: 0,//total count
    //new propertyfuel category params
    category: "",
    description: "",
    //edit propertyfuel category params
    eCategory: "",
    eDescription: ""
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_PROPERTYFUELCATEGORY_DATA:
            return { ...state, ...action.payload };
        case PROPERTYFUELCATEGORY_FETCHING_INIT: {
            return { ...state, propertyfuelcategorylist_fetching: true, propertyfuelcategorylist_refetch: false, propertyfuelcategorylist_refetch_force: false };
        }
        case PROPERTYFUELCATEGORY_FETCHING_COMPLETE: {
            return { ...state, propertyfuelcategorylist_fetching: false, ...action.payload };
        }
        case PROPERTYFUELCATEGORY_FETCHING_DISMISS: {
            return { ...state, propertyfuelcategorylist_fetching: false };
        }
        case SET_PROPERTYFUELCATEGORY_PAGE: {
            return { ...state, propertyfuelcategorylist_refetch: true, page: action.payload };
        }
        case ADD_NEW_PROPERTYFUELCATEGORY: {
            return { ...state, propertyfuelcategorylist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_PROPERTYFUELCATEGORY_PREVIEW: {
            return { ...state, new_propertyfuelcategory_popup: true, category: "", description: "" }
        }
        case ADD_NEW_PROPERTYFUELCATEGORY_DISMISS: {
            return { ...state, new_propertyfuelcategory_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_PROPERTYFUELCATEGORY_PREVIEW: {
            return { ...state, eCategory_error: "", ...action.payload }
        }
        case EDIT_PROPERTYFUELCATEGORY: {
            return { ...state, propertyfuelcategorylist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_PROPERTYFUELCATEGORY_DISMISS: {
            return { ...state, edit_propertyfuelcategory: null }
        }
        case DELETE_PROPERTYFUELCATEGORY_PREVIEW: {
            return { ...state, delete_propertyfuelcategory: action.payload }
        }
        case DELETE_PROPERTYFUELCATEGORY: {
            return { ...state, propertyfuelcategorylist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_PROPERTYFUELCATEGORY_DISMISS: {
            return { ...state, delete_propertyfuelcategory: null }
        }
        case DISABLE_PROPERTYFUELCATEGORY_PREVIEW: {
            return { ...state, disable_propertyfuelcategory: action.payload }
        }
        case DISABLE_PROPERTYFUELCATEGORY: {
            return { ...state, disable_propertyfuelcategory: null, propertyfuelcategorylist_refetch: true }
        }
        case DISABLE_PROPERTYFUELCATEGORY_DISMISS: {
            return { ...state, disable_propertyfuelcategory: null }
        }
        case SET_PROPERTYFUELCATEGORY_ROLES: {
            return { ...state, propertyfuelcategory_roles: action.payload }
        }
        default:
            return state;
    }
};
