export const SET_OUTSTANDING_QUERY_DATA = "SET_OUTSTANDING_QUERY_DATA";
export const DATA_FETCHING_INIT = "DATA_FETCHING_INIT";
export const DATA_FETCHING_DISMISS = "DATA_FETCHING_DISMISS";
export const SET_OPEN_VIEW_FILE = "SET_OPEN_VIEW_FILE";
export const OUTSTANDING_QUERY_ASSET_FETCHING_DISMISS = "OUTSTANDING_QUERY_ASSET_FETCHING_DISMISS";
export const OUTSTANDING_QUERY_DATA = "OUTSTANDING_QUERY_DATA";
export const OUTSTANDING_SUMMARY_DATA="OUTSTANDING_SUMMARY_DATA";
export const SET_OPEN_VIEW_QUERY="SET_OPEN_VIEW_QUERY";
export const SET_OPEN_VIEW_ASSET="SET_OPEN_VIEW_ASSET";
export const DELETE_FILE_PREVIEW="DELETE_FILE_PREVIEW";
export const DELETE_FILE_DISMISS ="DELETE_FILE_DISMISS"
export const DELETE_OUTSTANDING_QUERY_PREVIEW="DELETE_OUTSTANDING_QUERY_PREVIEW";
export const DELETE_OUTSTANDING_QUERY_DISMISS="DELETE_OUTSTANDING_QUERY_DISMISS";
export const DELETE_OUTSTANDING_QUERY="DELETE_OUTSTANDING_QUERY";
export const DELETE_FILE_QUERY = "DELETE_FILE_QUERY";
export const EDIT_FILE_PREVIEW ="EDIT_FILE_PREVIEW";
export const EDIT_FILE_DISMISS ="EDIT_FILE_DISMISS";
export const EDIT_FILE ="EDIT_FILE";
export const EDIT_QUERY_PREVIEW ="EDIT_QUERY_PREVIEW";
export const EDIT_QUERY_DISMISS ="EDIT_QUERY_DISMISS";
export const EDIT_QUERY ="EDIT_QUERY";
export const UNKN_EDIT_QUERY ="UNKN_EDIT_QUERY";
export const CSV_INSTALL_GENERATE_COMPLETE ="CSV_INSTALL_GENERATE_COMPLETE"
export const OPEN_INSTALL_REJECT_VALIDATION_POPUP ="OPEN_INSTALL_REJECT_VALIDATION_POPUP"
export const HIDE_INSTALL_VALIDATION_FILE_REJECT ="HIDE_INSTALL_VALIDATION_FILE_REJECT"
export const INSTALL_REJECT_VALIDATION_COMPLETE  = "INSTALL_REJECT_VALIDATION_COMPLETE"
export const ACCEPT_INSTALL_VALIDATION_SUCCESS = "ACCEPT_INSTALL_VALIDATION_SUCCESS"
export const COMMENT_PREVIEW="COMMENT_PREVIEW"
export const COMMENT_DISMISS="COMMENT_DISMISS"
export const ADD_NEW_COMMENT="ADD_NEW_COMMENT"
export const SET_SUPPORTING_DOC_DETAILS = "SET_SUPPORTING_DOC_DETAILS"
export const CLOSE_ERR_FILE_DOWNLOAD_POPUP = "CLOSE_ERR_FILE_DOWNLOAD_POPUP"
export const CLOSE_SUPPORT_ERR_FILE_DOWNLOAD_POPUP ="CLOSE_SUPPORT_ERR_FILE_DOWNLOAD_POPUP"
export const SUPPORT_EDIT_QUERY_PREVIEW  = "SUPPORT_EDIT_QUERY_PREVIEW "
export const CLOSE_SUPPORTDOC_PREVIEW  = "CLOSE_SUPPORTDOC_PREVIEW "
export const UPLOAD_SUPPORTDOC_PREVIEW = "UPLOAD_SUPPORTDOC_PREVIEW"
export const UNKN_EDIT_QUERY_PREVIEW= "UNKN_EDIT_QUERY_PREVIEW";
export const UNKN_EDIT_QUERY_DISMISS = "UNKN_EDIT_QUERY_DISMISS"
export const DELETE_UNKOWN_QUERY="DELETE_UNKOWN_QUERY";
export const UNKNOWN_QUERY_ASSET_FETCHING_DISMISS="UNKNOWN_QUERY_ASSET_FETCHING_DISMISS"
export const UNKNOWN_QUERY_FETCH_COMPLETE="UNKNOWN_QUERY_FETCH_COMPLETE"
const initialState = {
    query_loading_flag:false,
    outstandingquery_list: [], // all outstanding queries 
    outstandingqueryasset_list:[],
    data_fetching: false, //indicaction for fetch in progress
    outstandingquerylist_refetch: false,//indication to refetch current outstanding query list array
    outstandingqueryassetlist: false, //indicaction for fetch in progress
    outstandingqueryassetlist_refetch: false, //indicaction for fetch in progress
    outstandingqueryassetlist_refetch_force: false, //indicaction for Force fetch in progress
    details_view: 0, // 0 -> show index, 1-> view outstanding file
    search: "",//search keyword on outstanding file name listing
    page: 1,//page number on list
    perpage: 10,//perpage limit on list
    total_outstandingqueries: 0,//total count
    file_details: {},
    query_data:{},
    query_type: "",
    refetchviewqueryinfile_list:false,
    show_outstandingquery_list:true,
    installationfile_list: [], // all installation files 
    // total_installationfiles: 0,//total count
    // installationfilelist_refetch: false,//indication to refetch current installation file list array
    query_details:[],
    queryinfile_list:{},
    outstandingsummary_column:0,
    outstandingsummary_type:0,
    active_installation_file:{},
    active_file_query: {},
    totalqueryinfile_list: 0,
    refetchqueryinfile_list: true,
    ref_search : "",
    query_page : 1,
    query_perpage : 10,
    have_queries:0,
    fetchinstallfile_refetch:false,
    fetchinstallfile_refetch_force: false,
    delete_file:null,
    confirm_file_delete_popup:false,
    viewquery_details:"",
    confirm_delete_popup: false,
    install_page : 0,
    install_perpage : 10,
    edit_file: null,//holds initial data of user being edited
    edit_query:null,
     //edit file params
    ef_install_location: "" , ef_postcode: "" , ef_mpan: "" , ef_mprn: "" , ef_document_type: "" , ef_contact_id: "" , ef_job_date: "" ,
    eq_install_location:"",eq_postcode:"",eq_mpan:"",eq_mprn:"",eq_new_elec_msn:"",eq_new_gas_msn:"",eq_ihdserial:"",eq_regulator_serial:"",eq_old_elec_msn:"",eq_old_gas_msn:"",eq_job_date:"",
    is_record_exist:"", date_sent:"", bau_flag:"" ,energy_supplier_value:"" ,map_value:"", file_uploaded_date:"",address:"",postcode:"",mpan:"",elec_msn:"",elec_msn_installation_date:"",ihd_serial:"",ihd_serial_installation_date:"",mprn:"",gas_msn:"",gas_msn_installation_date:"",
    filelist_refetch: false,
    delete_file_btn_disable: false,
    edit_file_btn_disable : false,
    total_installfileasset : 0,
    totalquery_list:0,
    export_install_data : [], //initial export file data
    export_install_flag : false,
    download_install_btn_disable : false,
    installation_file_id : null,
    view_install_reject_popup : false,
    view_install_approve_popup :false,
    install_approve_btn_disable :false,
    install_confirm_btn_disable : false,
    checkbox_disable : false,
    revoke_checkbox : [],
    revoke_checkbox_flag : false,
    is_history_text:"",
    comment_popup:false,
    comment_text:"",
    move_review_btn_disable : false,
    resolve_comment:"",
    loading_flag:false,
    force_resolve_comment_flag:"",
    //Support document params
    supportDocFlag : false,
    unverifiedFlag:false,
    support_file_query : {},
    support_document_details : [],
    fetchsupportdoc_refetch : false,
    edit_support_doc : {},
    query_details_complete_flag : false,
    suppotdoc_error_popup : false,
    total_supportdoc_count : 0,
    supportdoc_perpage : 10,
    supportdoc_page :1,
    support_error_download_data : {},
    export_support_flag : false,
    export_support_doc_data : [],
    compare_popup:false,
    supportdoc:{},
    editsupportdoc:null,
    //initial value for master data
    ma_installation_file_id:"",ma_is_record_exist:"",ma_date_sent:"", ma_bau_flag:"", ma_energy_supplier_value:"", ma_map_value:"", ma_file_uploaded_date:"", ma_address:"", ma_postcode:"", ma_mpan:"", ma_elec_msn:"", ma_elec_msn_installation_date:"", ma_ihd_serial:"", ma_ihd_serial_installation_date:"", ma_mprn:"", ma_gas_msn:"", ma_gas_msn_installation_date:"",
    // intial value for sfe
    sfe_installation_file_id:"",sfe_is_record_exist:"", sfe_date_sent:"",sfe_bau_flag:"",sfe_energy_supplier_value:"",sfe_map_value:"", sfe_file_uploaded_date:"", sfe_address:"", sfe_postcode:"", sfe_mpan:"", sfe_elec_msn:"", sfe_elec_msn_installation_date:"", sfe_ihd_serial:"", sfe_ihd_serial_installation_date:"", sfe_mprn:"", sfe_gas_msn:"", sfe_gas_msn_installation_date:"",
    dcc_date_sent:"", dcc_bau_flag:"", dcc_energy_supplier_value:"", dcc_map_value:"",dcc_installation_file_id:"",dcc_is_record_exist:"", dcc_file_uploaded_date:"", dcc_address:"", dcc_postcode:"", dcc_mpan:"", dcc_elec_msn:"", dcc_elec_msn_installation_date:"", dcc_ihd_serial:"", dcc_ihd_serial_installation_date:"", dcc_mprn:"", dcc_gas_msn:"", dcc_gas_msn_installation_date:"",
    xoserve_date_sent:"", xoserve_bau_flag:"", xoserve_energy_supplier_value:"", xoserve_map_value:"",ecoes_date_sent:"", ecoes_bau_flag:"", ecoes_energy_supplier_value:"", ecoes_map_value:"", ecoes_is_record_exist:"", ecoes_file_uploaded_date:"", ecoes_address:"", ecoes_postcode:"", ecoes_mpan:"", ecoes_elec_msn:"", ecoes_elec_msn_installation_date:"", ecoes_ihd_serial:"", ecoes_ihd_serial_installation_date:"", ecoes_mprn:"", ecoes_gas_msn:"", ecoes_gas_msn_installation_date:"",xoserve_installation_file_id:"",ecoes_installation_file_id:"",xoserve_is_record_exist:"", xoserve_file_uploaded_date:"", xoserve_address:"", xoserve_postcode:"", xoserve_mpan:"", xoserve_elec_msn:"", xoserve_elec_msn_installation_date:"", xoserve_ihd_serial:"", xoserve_ihd_serial_installation_date:"", xoserve_mprn:"", xoserve_gas_msn:"", xoserve_gas_msn_installation_date:"",
    other_date_sent:"", other_bau_flag:"", other_energy_supplier_value:"", other_map_value:"",other_installation_file_id:"",other_is_record_exist:"", other_file_uploaded_date:"", other_address:"", other_postcode:"", other_mpan:"", other_elec_msn:"", other_elec_msn_installation_date:"", other_ihd_serial:"", other_ihd_serial_installation_date:"", other_mprn:"", other_gas_msn:"", other_gas_msn_installation_date:"",
    refetch_list:true,
    refresh_flag:false,
    download_support_flag :false,
    download_csv :"",
    export_support_flag_value:1,
    core_file_uploaded_date:"",core_address:"",core_postcode:"",core_mpan:"",core_elec_msn:"",core_elec_msn_installation_date:"",core_ihd_serial:"",core_ihd_serial_installation_date:"",core_mprn:"",core_gas_msn:"",core_gas_msn_installation_date:"",
    mpan_mprn_search : "",
    edit_support_doc_count:0,
    revoke_edit_action:[],
    toUpload_installation_file_id:"",
    toUpload_file_uploaded_date:"", 
    toUpload_address:"",
    toUpload_postcode:"", 
    toUpload_mpan:"", 
    toUpload_elec_msn:"", 
    toUpload_elec_msn_installation_date:"", 
    toUpload_ihd_serial:"", 
    toUpload_ihd_serial_installation_date:"", 
    toUpload_mprn:"", 
    toUpload_gas_msn:"", 
    toUpload_gas_msn_installation_date  :"",
    toUpload_date_sent:"",
    toUpload_bau_flag:"",toUpload_map_value:"",
    toUpload_is_record_exist:"",
    support_file_type_desc:"",
    support_file_type:"",
    match:"",
    toUpload_energy_supplier_value:"",
    address_validate:"",
    bau_flag_validate:"",
    date_sent_validate:"",
    elec_msn_installation_date_validate:"",
    energy_supplier_value_validate:"",
    gas_msn_installation_date_validate:"",
    gas_msn_validate:"",
    ihd_serial_installation_date_validate:"",
    ihd_serial_validate:"",
    map_validate:"",
    mpan_validate:"",
    mprn_validate:"",
    postcode_validate:"",
    elec_msn_validate:"",
    is_loader_required:0,
    unverified_page:1,
    unverified_perpage:10, 
    unverified_search:"",
    support_unverifiedfile_query : {},
    support_unverifiedfile_type_desc:"",
    viewSupportList:false,
    edit_query_popup:false,
    showQueryList:false,
    edit_disable:false,
    defaultindex:null,
    query_data_fetching:false,
    show_query:0,
    unkn_edit_query: null,
    unkn_install_location:"",
    unkn_postcode:"",
    unkn_mpan:"",
    unkn_mprn:"",
    unkn_new_elec_msn:"",
    unkn_new_gas_msn:"",
    unkn_ihdserial:"",
    unkn_regulator_serial:"",
    unkn_old_elec_msn:"",
    unkn_old_gas_msn:"",
    unkn_edit_query_popup:false,
    unkn_edit_disable:false,
    unkn_confirm_delete_popup:false,
    unkn_del_btn_disable:false,
    is_history_exist:0, // 0 if history not exist
    queryinfile_list_flag:0,
    unkn_query_page:1,
    unkn_query_perpage:10,
    unknqueryinfile_list: [],
    total_unkn_query_list:0,
    unkn_query_refetch: false,
    unkn_data_fetching: false,
    unkn_job_date:"",
    socket_refresh:false,
    install_data:[],
    back_to_checks_page:false,
    
};

export default (state = initialState, action = {}) => {
    const actionObject = {
        SET_OUTSTANDING_QUERY_DATA: () => ({ ...state, ...action.payload }),
        SET_OPEN_VIEW_QUERY: () => ({ ...state, ...action.payload }),
        OUTSTANDING_QUERY_DATA: () => ({ ...state, ...action.payload }),
        SET_OPEN_VIEW_FILE: () => ({ ...state, ...action.payload }),
        OUTSTANDING_SUMMARY_DATA: () => ({ ...state, ...action.payload }),
        DELETE_OUTSTANDING_QUERY_PREVIEW: () => ({ ...state, ...action.payload }),
        DELETE_OUTSTANDING_QUERY_DISMISS: () => ({ ...state, confirm_delete_popup: false,is_history_exist:0 }),
        DATA_FETCHING_INIT: () => ({ ...state, ...action.payload, data_fetching: true }),
        DATA_FETCHING_DISMISS: () => ({ ...state, ...action.payload, data_fetching: false }),
        DELETE_OUTSTANDING_QUERY: () => ({ ...state, ...action.payload, outstandingqueryassetlist_refetch_force: true, is_loader_required: 1, del_btn_disable: false }),
        OUTSTANDING_QUERY_ASSET_FETCHING_DISMISS: () => ({ ...state, ...action.payload, data_fetching: false, outstandingqueryassetlist: false, outstandingqueryassetlist_refetch: false, show_outstandingquery_list: false }),
        SET_OPEN_VIEW_ASSET: () => ({ ...state, ...action.payload }),
        DELETE_FILE_PREVIEW: () => ({ ...state, ...action.payload }),
        DELETE_FILE_DISMISS: () => ({ ...state, confirm_file_delete_popup: false }),
        DELETE_FILE_QUERY: () => ({ ...state, ...action.payload, fetchinstallfile_refetch_force: true, fetchinstallfile_refetch: true }),
        EDIT_FILE_PREVIEW: () => ({ ...state, ...action.payload }),
        EDIT_FILE_DISMISS: () => ({ ...state, edit_file: null }),
        EDIT_FILE: () => ({ ...state, fetchinstallfile_refetch: true, edit_btn_disable: false, search: "" }),
        EDIT_QUERY_PREVIEW: () => ({ ...state, ...action.payload }),
        EDIT_QUERY_DISMISS: () => ({ ...state, unkn_edit_query: null }),
        EDIT_QUERY: () => ({ ...state, ...action.payload ,unkn_edit_query:null,unkn_edit_disable:false,unkn_edit_query_popup:false,unkn_data_fetching:false}),
        CSV_INSTALL_GENERATE_COMPLETE: () => ({ ...state, ...action.payload }),
        OPEN_INSTALL_REJECT_VALIDATION_POPUP: () => ({ ...state, ...action.payload, view_install_reject_popup: true }),
        HIDE_INSTALL_VALIDATION_FILE_REJECT: () => ({ ...state, view_install_reject_popup: false, view_details: 1 }),
        INSTALL_REJECT_VALIDATION_COMPLETE: () => ({ ...state, ...action.payload, view_install_reject_popup: false, view_details: 0 }),
        ACCEPT_INSTALL_VALIDATION_SUCCESS: () => ({ ...state, ...action.payload }),
        COMMENT_PREVIEW: () => ({ ...state, ...action.payload }),
        COMMENT_DISMISS: () => ({ ...state, comment_text: "", comment_popup: false, ...action.payload }),
        ADD_NEW_COMMENT: () => ({ ...state, refetchqueryinfile_list: true, search: "", add_btn_disable: false }),
        SET_SUPPORTING_DOC_DETAILS: () => ({ ...state, ...action.payload }),
        CLOSE_ERR_FILE_DOWNLOAD_POPUP: () => ({ ...state, ...action.payload }),
        CLOSE_SUPPORT_ERR_FILE_DOWNLOAD_POPUP: () => ({ ...state, ...action.payload }),
        SUPPORT_EDIT_QUERY_PREVIEW: () => ({ ...state, ...action.payload }),
        CLOSE_SUPPORTDOC_PREVIEW: () => ({ ...state, ...action.payload }),
        UPLOAD_SUPPORTDOC_PREVIEW: () => ({ ...state, ...action.payload }),
        UNKN_EDIT_QUERY_PREVIEW: () => ({ ...state, ...action.payload }),
        UNKN_EDIT_QUERY_DISMISS: () => ({ ...state,unkn_data_fetching:false,unkn_edit_disable:false}),
        DELETE_UNKOWN_QUERY: () => ({ ...state, ...action.payload, outstandingqueryassetlist_refetch_force: true, is_loader_required: 1, unkn_del_btn_disable: false,unkn_data_fetching:false,unkn_confirm_delete_popup:false }),
        UNKNOWN_QUERY_ASSET_FETCHING_DISMISS:()=> ({...state, ...action.payload,unkn_data_fetching:false,unkn_query_refetch: false}),
        UNKNOWN_QUERY_FETCH_COMPLETE:()=> ({...state, ...action.payload,unkn_data_fetching:false,unkn_query_refetch: false,revoke_checkbox_flag: false,}),
        UNKN_EDIT_QUERY: () => ({ ...state, ...action.payload ,unkn_edit_query:null,unkn_edit_disable:false,unkn_edit_query_popup:false,unkn_data_fetching:false}),
    };
    if (actionObject[action.type] === undefined) {
        return state; // Return unchanged state
    } else {
        // Call the appropriate action handler and return the updated state
        return actionObject[action.type]();
    }
};
