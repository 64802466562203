export const SET_ASSETTYPE_DATA = "SET_ASSETTYPE_DATA";
export const ASSETTYPE_FETCHING_INIT = "ASSETTYPE_FETCHING_INIT"
export const ASSETTYPE_FETCHING_COMPLETE = "ASSETTYPE_FETCHING_COMPLETE"
export const ASSETTYPE_FETCHING_DISMISS = "ASSETTYPE_FETCHING_DISMISS"
export const ADD_NEW_ASSETTYPE = "ADD_NEW_ASSETTYPE"
export const ADD_NEW_ASSETTYPE_PREVIEW = "ADD_NEW_ASSETTYPE_PREVIEW"
export const ADD_NEW_ASSETTYPE_DISMISS = "ADD_NEW_ASSETTYPE_DISMISS"
export const EDIT_ASSETTYPE_PREVIEW = "EDIT_ASSETTYPE_PREVIEW"
export const EDIT_ASSETTYPE = "EDIT_ASSETTYPE"
export const EDIT_ASSETTYPE_DISMISS = "EDIT_ASSETTYPE_DISMISS"
export const DELETE_ASSETTYPE_PREVIEW = "DELETE_ASSETTYPE_PREVIEW"
export const DELETE_ASSETTYPE_DISMISS = "DELETE_ASSETTYPE_DISMISS"
export const DELETE_ASSETTYPE = "DELETE_ASSETTYPE"
export const DISABLE_ASSETTYPE_PREVIEW = "DISABLE_ASSETTYPE_PREVIEW"
export const DISABLE_ASSETTYPE_DISMISS = "DISABLE_ASSETTYPE_DISMISS"
export const DISABLE_ASSETTYPE = "DISABLE_ASSETTYPE"
export const SET_ASSETTYPE_ROLES = "SET_ASSETTYPE_ROLES"
export const SET_ASSETTYPE_PAGE = "SET_ASSETTYPE_PAGE"
