import React, { useEffect, lazy, Suspense } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCurrentUser } from "../redux/auth/index";
import PropTypes from 'prop-types';

//Private route only available if authentcated 
import PrivateRoute from "./PrivateRoute";
//Guest route only available if not-authentcated 
import AuthRoute from "./AuthRoute"

//lazy loading fallback
import Loading from "../components/Loading/index"


//lazy load components
const SignIn = lazy(() => import('../views/signin/index'));
const Dashboard = lazy(() => import('../components/dashboard/index'));
const NotFound = lazy(() => import('../views/404/notfound'));
const ForgotPassword = lazy(() => import('../views/forgot-password'));
const PasswordReset = lazy(() => import('../views/password-reset/index'));
const SignOut = lazy(() => import('../views/signout/index'));

const PrintJob = lazy(() => import('../views/repatriation-job/print-job'));
const PrintContainer = lazy(() => import('../views/repatriation-container/print-container'));
const PrintTriageJob = lazy(() => import('../views/triage-job/print-job'));
const PrintTriageContainer = lazy(() => import('../views/triage-container/print-container'));
const TermsCondition = lazy(() => import('../views/terms-condition'));
const Policy = lazy(() => import('../views/privacy-policy'));
const ExternalView = lazy(() => import('../views/file-management/external-user-view/index'));
const FTP = lazy(() => import('../views/file-management/FTP/index'));

const PrintTriageFailReason = lazy(() => import('../views/triage-fail-reason/print-triage-fail-reason'));

function Routes(props) {
  useEffect(() => {
    props.getCurrentUser()
  }, [])
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Switch>
          <AuthRoute path="/sign-in" exact component={SignIn} />
          <AuthRoute path="/forgot-password" exact component={ForgotPassword} />
          <AuthRoute path="/password-reset" exact component={PasswordReset} />
          <AuthRoute path="/reset-password" exact component={PasswordReset} />
          <AuthRoute path="/" exact component={SignIn} />
          <AuthRoute path="/sign-out" exact component={SignOut} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/triage-print-job" component={PrintTriageJob} />
          <PrivateRoute path="/print-job" component={PrintJob} />
          <PrivateRoute path="/print-container" component={PrintContainer} />
          <PrivateRoute path="/triage-print-container" component={PrintTriageContainer} />
          <AuthRoute path="/terms-condition" exact component={TermsCondition} />
          <AuthRoute path="/privacy-policy" exact component={Policy} />
          <AuthRoute path="/folder/external" exact component={ExternalView} />
          <AuthRoute path="/ftp-image-excel" exact component={FTP} />
          <PrivateRoute path="/print-triage-fail-reason" exact component={PrintTriageFailReason} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

Routes.propTypes = {
  getCurrentUser: PropTypes.func,
};

const mapStateToProps = ({ auth }) => ({
  ...auth,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCurrentUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
