import {
    FETCH_COMPANY_REQUEST,
    CHANGE_PAGE_NO,
    SET_COMPANY_REQUEST_DATA,
    MARK_REQUEST_DONE,
    ADD_NEW_COMPANY_REQUEST,
    SET_EDIT_DATA,
    EDIT_COMPANY_REQUEST,
    DELETE_COMPANY_REQUEST_POPUP,
    DELETE_COMPANY_REQUEST
} from "./types";

const initialState = {
    companyrequest_list: [],//holds requests list array
    companyrequestlist_fetching: false,//indicaction for fetch in progress
    companyrequestlist_refetch: false,//indication to refetch current request list array
    search: "",//search keyword on contract type listing
    page: 1,//page number on requests listing
    perpage: 10,//perpage limit on requests listing
    total_companyrequests: 0,//total count
    description: '',
    add_btn_disable: false,
    edit_btn_disable: false,
    edit_company_request_popup: false,
    eDescription: '',
    edit_companyrequest: {},
    delete_companyrequest_popup: false,
    delete_companyrequest: {}
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case DELETE_COMPANY_REQUEST:
            return { ...state, ...action.payload }
        case DELETE_COMPANY_REQUEST_POPUP:
            return { ...state, ...action.payload }
        case EDIT_COMPANY_REQUEST:
            return { ...state, ...action.payload }
        case SET_EDIT_DATA:
            return { ...state, ...action.payload }
        case ADD_NEW_COMPANY_REQUEST:
            return { ...state, ...action.payload, description: "", companyrequestlist_refetch: true, add_btn_disable: false, }
        case FETCH_COMPANY_REQUEST:
            return { ...state, ...action.payload };
        case CHANGE_PAGE_NO:
            return { ...state, companyrequestlist_refetch: true, page: action.payload };
        case SET_COMPANY_REQUEST_DATA:
            return { ...state, ...action.payload };
        case MARK_REQUEST_DONE:
            return { ...state, ...action.payload }
        default:
            return state;
    }
};
