import axios from "axios";
import Promise from "promise";
import cogoToast from "cogo-toast";
import secureLocalStorage from "react-secure-storage";

let env;

if (process.env.REACT_APP_ENV == 'local') {
  env = process.env.REACT_APP_API_BASE_URL_DEV;
} else if (process.env.REACT_APP_ENV == 'prod') {
  env = process.env.REACT_APP_API_BASE_URL_PROD;
} else {
  env = process.env.REACT_APP_API_BASE_URL_UAT;
}

const http = axios.create({
  baseURL: env,
});

http.defaults.timeout = 100000000;

const avoidshowError = [];
http.interceptors.request.use(
  function (config) {
    let token; //changed let token=null due to sonar issues
    if (typeof secureLocalStorage.getItem("auth") === "undefined") {
      token=null
    } else {
      token = secureLocalStorage.getItem("auth");
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token["access_token"]}`;
    }

    return config;
  },
  function (error) {
    return Promise.resolve({ error });
  }
);
http.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    return new Promise(function (resolve, reject) {
      if (err.response && err.response.status === 401) {
        if (err.response.data.error === "invalid_token") {
          secureLocalStorage.removeItem("auth");
          cogoToast.error("Session expired, please login again.");
          toastFix();
          setTimeout(() => {
            window.location.reload();
          }, 6000);
          return false;
        } else {
          return false;
        }
      } else if (
        (typeof err === "object" || typeof err === "function") &&
        err !== null
      ) {
        if (!err.response) {
          return false;
        }
        if (err.response.status === 500) {
          setTimeout(() => {
            window.location.replace("/");
          });
          return false;
        }
      }

      throw err;
    });
  }
);

export default http;
