export const SET_TRIAGE_REPORT_FILE_DATA = "SET_TRIAGE_REPORT_FILE_DATA";
export const SET_FILTER_DATA = "SET_FILTER_DATA";
export const DATA_FETCHING_INIT = "DATA_FETCHING_INIT";
export const DATA_FETCHING_DISMISS = "DATA_FETCHING_DISMISS";
export const FETCH_TRIAGE_REPORT_FILE_LIST_COMPLETE = "FETCH_TRIAGE_REPORT_FILE_LIST_COMPLETE";

const triageReportState = {
    date:[],
    export_support_flag_value_report:1,
    search:'',
    date2:[],
    page:1,
    perpage:10,
    supplier:[],
    data_fetching:false,
    report_list:[],
    triage_report_list_refetch:false,
    supplier_arr:[],
}

export default (state = triageReportState, action ={}) => {

    switch(action.type){
        case SET_TRIAGE_REPORT_FILE_DATA:
            return { ...state, ...action.payload }
        case SET_FILTER_DATA:
            return { ...state, ...action.payload}
        case FETCH_TRIAGE_REPORT_FILE_LIST_COMPLETE: 
            return { ...state, ...action.payload}
        case DATA_FETCHING_INIT:
            return { ...state, ...action.payload, data_fetching: true };
        case DATA_FETCHING_DISMISS:
            return { ...state, ...action.payload, data_fetching: false };
        default:
            return state;
    }
};