import {
    ADD_NEW_SUPPLIER, DELETE_SUPPLIER, DELETE_SUPPLIER_DISMISS, DELETE_SUPPLIER_PREVIEW,
    DISABLE_SUPPLIER, DISABLE_SUPPLIER_DISMISS, DISABLE_SUPPLIER_PREVIEW,
    EDIT_SUPPLIER, EDIT_SUPPLIER_DISMISS, EDIT_SUPPLIER_PREVIEW, SET_SUPPLIER_DATA,
    SUPPLIER_FETCHING_COMPLETE, SUPPLIER_FETCHING_INIT, SUPPLIER_FETCHING_DISMISS, SET_SUPPLIER_PAGE,
    ADD_NEW_SUPPLIER_DISMISS, ADD_NEW_SUPPLIER_PREVIEW, SET_SUPPLIER_ROLES, SET_NEW_SUPPLIER_LOCATION,
    REMOVE_SUPPLIER_LOCATION
} from "./types";

const initialState = {
    supplier_roles: [],
    supplier_list: [],//holds user list array
    supplierlist_fetching: false,//indicaction for fetch in progress
    supplierlist_refetch: false,//indication to refetch current user list array
    supplierlist_refetch_force: false,//indication to force refetch user list array from beginning 
    edit_supplier: null,//holds initial data of user being edited
    delete_supplier: null,//holds initial data of user opted to be deleted
    disable_supplier: null,//holds initial data of user opted to be disabled,
    search: "",//search keyword on user listing
    page: 1,//page number on user listing
    perpage: 10,//perpage limit on user listing
    total_suppliers: 0,//total count
    //new user params
    supl_name: "", supl_desc: "", supl_type_desc: "", supl_type_id: "",
    //edit user params
    eSupl_name: "", eSupl_desc: "", eSupl_type_desc: "", eSupl_type_id: "",
    //for add supplier preview
    Supl_type_id: "", add_supplier: null,
    locationArr: [{ location_desc: "", contact_name: "", address: "", postal_code: "", }],
    eSupl_location: null,
    initialLocation: false,
    removeConfirmModal: false,
    removeIndex: "",
    new_supplier_popup: null,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_SUPPLIER_DATA:
            return { ...state, ...action.payload };
        case SET_NEW_SUPPLIER_LOCATION:
            return { ...state, ...action.payload };
        case REMOVE_SUPPLIER_LOCATION:
            return { ...state, ...action.payload };
        case SUPPLIER_FETCHING_INIT: {
            return { ...state, supplierlist_fetching: true, supplierlist_refetch: false, supplierlist_refetch_force: false };
        }
        case SUPPLIER_FETCHING_COMPLETE: {
            return { ...state, supplierlist_fetching: false, ...action.payload };
        }
        case SUPPLIER_FETCHING_DISMISS: {
            return { ...state, supplierlist_fetching: false };
        }
        case SET_SUPPLIER_PAGE: {
            return { ...state, supplierlist_refetch: true, page: action.payload };
        }
        case ADD_NEW_SUPPLIER: {
            return { ...state, supplierlist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_SUPPLIER_PREVIEW: {
            return { ...state, new_supplier_popup: true, ...action.payload }
        }
        case ADD_NEW_SUPPLIER_DISMISS: {
            return { ...state, new_supplier_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_SUPPLIER_PREVIEW: {
            return { ...state, eSupl_error: "", ...action.payload }
        }
        case EDIT_SUPPLIER: {
            return { ...state, supplierlist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_SUPPLIER_DISMISS: {
            return { ...state, edit_supplier: null }
        }
        case DELETE_SUPPLIER_PREVIEW: {
            return { ...state, delete_supplier: action.payload }
        }
        case DELETE_SUPPLIER: {
            return { ...state, supplierlist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_SUPPLIER_DISMISS: {
            return { ...state, delete_supplier: null }
        }
        case DISABLE_SUPPLIER_PREVIEW: {
            return { ...state, disable_supplier: action.payload }
        }
        case DISABLE_SUPPLIER: {
            return { ...state, disable_supplier: null, supplierlist_refetch: true }
        }
        case DISABLE_SUPPLIER_DISMISS: {
            return { ...state, disable_supplier: null }
        }
        case SET_SUPPLIER_ROLES: {
            return { ...state, supplier_roles: action.payload }
        }
        default:
            return state;
    }
};
