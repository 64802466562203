export const SET_TEMPLATE_DATA = "SET_TEMPLATE_DATA";
export const DATA_FETCHING_INT = "DATA_FETCHING_INT";
export const DATA_FETCHING_DISMISS = "DATA_FETCHING_DISMISS";
export const FETCH_TEMPLATE_LIST_COMPLETE = "FETCH_TEMPLATE_LIST_COMPLETE";
export const CREATE_TEMPLATE_INT = "CREATE_TEMPLATE_INT";
export const CREATE_COMPLETED = "CREATE_COMPLETED";
export const EDIT_TEMPLATE_INTI = "EDIT_TEMPLATE_INTI";
export const EDIT_TEMPLATE = "EDIT_TEMPLATE";
export const EDIT_TEMPLATE_DISMISS = "EDIT_TEMPLATE_DISMISS";
export const DELETE_TEMPLATE_INTI = " DELETE_TEMPLATE_INTI";
export const DELETE_TEMPLATE_COMPLETE = "DELETE_TEMPLATE_COMPLETE";
export const DELETE_TEMPLATE_DISMISS = "DELETE_TEMPLATE_DISMISS";

const templateState = {
    data_fetching:false,
    template_list:[],
    search:"",
    page:1,
    perpage:10,
    template_refetch:true,
    single_data_fetching:false,
    index_flag:-1,
    template_title:"",
    htmlCode:'',
    save_btn_disable:false,
    template_details:null,
    edit_btn_disable:false,
    delete_template_popup: false,
    del_btn_disable:false,
    guid_popup:false
};

export default (state = templateState, action ={}) => {
    switch (action.type) {
        case SET_TEMPLATE_DATA:
            return { ...state, ...action.payload};
        case DATA_FETCHING_INT:
            return { ...state, ...action.payload, data_fetching: true };
        case DATA_FETCHING_DISMISS:
            return { ...state, ...action.payload, data_fetching: false, template_refetch:false };
        case FETCH_TEMPLATE_LIST_COMPLETE:
            return { ...state, ...action.payload, data_fetching: false,template_refetch:false };
        case CREATE_TEMPLATE_INT:
            return { ...state, ...action.payload, data_fetching: true, save_btn_disable:true };
        case CREATE_COMPLETED:
            return { ...state, ...action.payload, data_fetching: false,template_refetch:true, save_btn_disable:false,index_flag:0 ,template_title:'',htmlCode:'' }
        case EDIT_TEMPLATE_INTI:
            return { ...state, ...action.payload, data_fetching: true, edit_btn_disable:true, };
        case EDIT_TEMPLATE:
            return { ...state, ...action.payload, data_fetching: false,template_refetch:true, edit_btn_disable:false,index_flag:0,template_title:'',htmlCode:'' };
       case EDIT_TEMPLATE_DISMISS:
            return {...state, ...action.payload, data_fetching:false,edit_btn_disable:false}
        case DELETE_TEMPLATE_INTI:
            return {...state, ...action.payload, del_btn_disable:true}
        case DELETE_TEMPLATE_COMPLETE:
            return {...state, ...action.payload, del_btn_disable:false,delete_template_popup:false,template_refetch:true}
        case DELETE_TEMPLATE_DISMISS:
            return {...state, ...action,payload, del_btn_disable:false}
        default:
            return state;
    }
}