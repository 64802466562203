export const SET_SEARCH_ASSET_DATA_HISTORY = "SET_SEARCH_ASSET_DATA_HISTORY";
export const DATA_FETCHING_HISTORY_INIT = "DATA_FETCHING_HISTORY_INIT";
export const DATA_HISTORY_FETCHING_DISMISS = "DATA_HISTORY_FETCHING_DISMISS";
export const ASSET_HISTORY_SEARCH_LIST_COMPLETE = "ASSET_HISTORY_SEARCH_LIST_COMPLETE";
export const OPEN_NEW_UPLOAD_POPUP = "OPEN_NEW_UPLOAD_POPUP";
export const CLOSE_NEW_UPLOAD_POPUP = "CLOSE_NEW_UPLOAD_POPUP";
export const DATA_FETCHING_INIT="DATA_FETCHING_INIT";
export const DATA_FETCHING_DISMISS="DATA_FETCHING_DISMISS";
const searchHistoryAsset ={
    qsearch:"",
    refresh_flag:false,
    assetHistory:[],
    assetHistorySerch_refetch:false,
    export_support_flag_value:1,
    download_support_flag :false,
    viewImagepopup:false,
    tableFlag:false,
    messegeFlag:true,
    location:true,
    energySuplier:false,
    ownerShip:false,
    finance:false,
    firmware:false,
    Photo:false,
    option:[
        // { key: '1', text: 'Location'},
        { key: '2', text: 'Energy Supplier'},{ key: '3', text: 'Ownership'},{ key: '4', text: 'Finance'},
        { key: '5', text: 'Firmware'},{key:'6',text:'Photo'}
      ],
    dissable_button:false,
    defaultValue:['1'],
    header:['date','event','details','locations'],
    total_asset_count:" ",   
    count:100,
    list_files: ["date","event","mpan","mprn","location"],
    ShowModal:false,
    total_image_list:"",
    image_list:[],
    eventType:"",
    assetId:"",
    showPopup:false,
    showDetails:"",
    image_page:1,
    image_perpage:24,
    image_refetch:false,
    data_fetching:false,
    event:"",
    ex_image_url:"",
    Excel_icon_Popup:false,
    Excel_popup_details:[],
    Csv_icon_Popup:false,
    Csv_popup_details:[],

    
}

export default (state = searchHistoryAsset, action = {}) => {
    switch (action.type) {
        case SET_SEARCH_ASSET_DATA_HISTORY:
            return { ...state, ...action.payload };
        case DATA_FETCHING_HISTORY_INIT:
            return { ...state, ...action.payload, refresh_flag: true,dissable_button:true };
        case DATA_HISTORY_FETCHING_DISMISS:
            return { ...state, ...action.payload, refresh_flag: false,dissable_button:false};
        case ASSET_HISTORY_SEARCH_LIST_COMPLETE:
            return { ...state, ...action.payload };
        case OPEN_NEW_UPLOAD_POPUP:
            return { ...state, ...action.payload };
        case CLOSE_NEW_UPLOAD_POPUP:
            return { ...state, ...action.payload };
            case DATA_FETCHING_INIT:
                return { ...state, ...action.payload, data_fetching: true };
            case DATA_FETCHING_DISMISS:
                return { ...state, ...action.payload, data_fetching: false };
        default:
            return state;
    }
}