export const SET_CONTAINERS_DATA = "SET_CONTAINERS_DATA"
export const FETCH_CONTAINERS = "FETCH_CONTAINERS"
export const CREATE_NEW_CONTAINER = "CREATE_NEW_CONTAINER"
export const DETAILS_FETCHING_COMPLETE = "DETAILS_FETCHING_COMPLETE"
export const CONTAINERS_FETCHING_COMPLETE = "CONTAINERS_FETCHING_COMPLETE"
export const SET_CONTAINERS_PAGE = "SET_CONTAINERS_PAGE"
export const CONFIRM_CREATE_CONTAINER = "CONFIRM_CREATE_CONTAINER"
export const PALLETS_OPERATORS_FETCHING_COMPLETE = "PALLETS_OPERATORS_FETCHING_COMPLETE"
export const FETCH_ASSET_CONTAINER_FILE_DETAILS = "FETCH_ASSET_CONTAINER_FILE_DETAILS"
export const PALLET_MAIL_SENT_COMPLETE = "PALLET_MAIL_SENT_COMPLETE"
export const SET_PRINT_PAGE_DATA = "SET_PRINT_PAGE_DATA"
export const DELETE_SNAPSHOT_OBJ_SUCCESS = "DELETE_SNAPSHOT_OBJ_SUCCESS"
export const SET_PALLET_ARRIVAL_DATE = "SET_PALLET_ARRIVAL_DATE"
export const CONTAINER_UPDATE_SUCCESS = "CONTAINER_UPDATE_SUCCESS"
export const DELETE_CONTAINER = "DELETE_CONTAINER"
export const CLOSE_CREATE_CONTAINER_POPUP = "CLOSE_CREATE_CONTAINER_POPUP"
export const CONTAINER_DETAILS_FETCH_COMPLETE = "CONTAINER_DETAILS_FETCH_COMPLETE"
export const CONTAINER_LOCATION_LIST_FETCH_COMPLETE = "CONTAINER_LOCATION_LIST_FETCH_COMPLETE"

