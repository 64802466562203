export const SET_FILETYPE_DATA = "SET_FILETYPE_DATA";
export const FILETYPE_FETCHING_INIT = "FILETYPE_FETCHING_INIT"
export const FILETYPE_FETCHING_COMPLETE = "FILETYPE_FETCHING_COMPLETE"
export const FILETYPE_FETCHING_DISMISS = "FILETYPE_FETCHING_DISMISS"
export const ADD_NEW_FILETYPE = "ADD_NEW_FILETYPE"
export const ADD_NEW_FILETYPE_PREVIEW = "ADD_NEW_FILETYPE_PREVIEW"
export const ADD_NEW_FILETYPE_DISMISS = "ADD_NEW_FILETYPE_DISMISS"
export const EDIT_FILETYPE_PREVIEW = "EDIT_FILETYPE_PREVIEW"
export const EDIT_FILETYPE = "EDIT_FILETYPE"
export const EDIT_FILETYPE_DISMISS = "EDIT_FILETYPE_DISMISS"
export const DELETE_FILETYPE_PREVIEW = "DELETE_FILETYPE_PREVIEW"
export const DELETE_FILETYPE_DISMISS = "DELETE_FILETYPE_DISMISS"
export const DELETE_FILETYPE = "DELETE_FILETYPE"
export const DISABLE_FILETYPE_PREVIEW = "DISABLE_FILETYPE_PREVIEW"
export const DISABLE_FILETYPE_DISMISS = "DISABLE_FILETYPE_DISMISS"
export const DISABLE_FILETYPE = "DISABLE_FILETYPE"
export const SET_FILETYPE_ROLES = "SET_FILETYPE_ROLES"
export const SET_FILETYPE_PAGE = "SET_FILETYPE_PAGE"
