export const SET_USER_ROLE_DATA = "SET_USER_ROLE_DATA";
export const USER_ROLE_FETCHING_INIT = "USER_ROLE_FETCHING_INIT"
export const USER_ROLE_FETCHING_COMPLETE = "USER_ROLE_FETCHING_COMPLETE"
export const USER_ROLE_FETCHING_DISMISS = "USER_ROLE_FETCHING_DISMISS"
export const ADD_NEW_USER_ROLE = "ADD_NEW_USER_ROLE"
export const ADD_NEW_USER_ROLE_PREVIEW = "ADD_NEW_USER_ROLE_PREVIEW"
export const ADD_NEW_USER_ROLE_DISMISS = "ADD_NEW_USER_ROLE_DISMISS"
export const EDIT_USER_ROLE_PREVIEW = "EDIT_USER_ROLE_PREVIEW"
export const EDIT_USER_ROLE = "EDIT_USER_ROLE"
export const EDIT_USER_ROLE_DISMISS = "EDIT_USER_ROLE_DISMISS"
export const DELETE_USER_ROLE_PREVIEW = "DELETE_USER_ROLE_PREVIEW"
export const DELETE_USER_ROLE_DISMISS = "DELETE_USER_ROLE_DISMIS"
export const DELETE_USER_ROLE = "DELETE_USER_ROLE"
export const DISABLE_USER_ROLE_PREVIEW = "DISABLE_USER_ROLE_PREVIEW"
export const DISABLE_USER_ROLE_DISMISS = "DISABLE_USER_ROLE_DISMISS"
export const DISABLE_USER_ROLE = "DISABLE_USER_ROLE"
export const SET_USER_ROLES = "SET_USER_ROLES"
export const SET_USER_ROLE_PAGE = "SET_USER_ROLE_PAGE"
export const SET_USER_ROLE_ORGANISATION = "SET_USER_ROLE_ORGANISATION"
export const SET_DASHBOARD_WIDGET_LIST = "SET_DASHBOARD_WIDGET_LIST"

