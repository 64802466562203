import {
    SET_ASSET_TITILES,
    ASSETS_FETCHING_COMPLETE,
    SET_ASSET_DATA,
    SET_ASSET_PAGE,
    CSV_GENERATE_COMPLETE,
    FILTER_OPTIONS_FETCHING_COMPLETE,
    CLOSE_ADVANCED_FILTER_POPUP,
    ASSET_MODEL_FETCHING_COMPLETE
} from "./types";

const initialState = {
    search: "",                     // search keyword on propertyfuel category listing
    page: 1,                        // page number on propertyfuel category listing
    perpage: 10,                    // perpage limit on propertyfuel category listing
    records_refetch: false,         // flag for conditional refetch
    list_titles: [{ minWidth: 80, isMultiline: true, key: 1, ismultiline: true, isResizable: true, selected: false, name: "Serial No", fieldName: "serial_no", text: "Serial No", disabled: true },
                  { minWidth: 100, isMultiline: true, key: 2, ismultiline: true, isResizable: true, selected: false, name: "Asset Type", fieldName: "asset_type_desc", text: "Asset Type" },
                  { minWidth: 120, isMultiline: true, key: 3, ismultiline: true, isResizable: true, selected: false, name: "Asset Owner", fieldName: "asset_owner", text: "Asset Owner" },
                  { minWidth: 100, isMultiline: true, key: 4, ismultiline: true, isResizable: true, selected: false, name: "Asset Model", fieldName: "asset_model_desc", text: "Asset Model" },
                  { minWidth: 120, isMultiline: true, key: 5, ismultiline: true, isResizable: true, selected: false, name: "Manufacturer", fieldName: "manufacturer", text: "Manufacturer" },
                  { minWidth: 150, isMultiline: true, key: 6, ismultiline: true, isResizable: true, selected: false, name: "Event", fieldName: "event_type_desc", text: "Event" }],                // holds asset table initial column titles array
    total_records: null,            // holds total count of assets for the logged in user
    assets_records: [],             // holds initial asset records
    clear_filter_btn: false,        // To handle clear action reload records array
    download_btn_disable: false,
    filter_asset_model_id: null,
    filter_asset_manu_id: null,
    advanced_filters_popup: false,
    columns: [
        { minWidth: 80, isMultiline: true, key: 1, ismultiline: true, isResizable: true, selected: false, name: "Serial No", fieldName: "serial_no", text: "Serial No", disabled: true, isFilter: true},
        { minWidth: 100, isMultiline: true, key: 2, ismultiline: true, isResizable: true, selected: false, name: "Asset Type", fieldName: "asset_type_desc", text: "Asset Type", isFilter: true },
        { minWidth: 120, isMultiline: true, key: 3, ismultiline: true, isResizable: true, selected: false, name: "Asset Owner", fieldName: "asset_owner", text: "Asset Owner", isFilter: true },
        { minWidth: 100, isMultiline: true, key: 4, ismultiline: true, isResizable: true, selected: false, name: "Asset Model", fieldName: "asset_model_desc", text: "Asset Model", isFilter: true },
        { minWidth: 120, isMultiline: true, key: 5, ismultiline: true, isResizable: true, selected: false, name: "Manufacturer", fieldName: "manufacturer", text: "Manufacturer", isFilter: true },
        { minWidth: 150, isMultiline: true, key: 6, ismultiline: true, isResizable: true, selected: false, name: "Latest Event", fieldName: "event_type_desc", text: "Latest Event", isFilter: true },
        { minWidth: 120, isMultiline: true, key: 7, ismultiline: true, isResizable: true, selected: false, className: 'column-align-center', headerClassName: "column-align-center", name: "Warranty Period", fieldName: "warranty_period", text: "Warranty Period", isFilter: true},
        { minWidth: 140, isMultiline: true, key: 8, ismultiline: true, isResizable: true, selected: false, name: "Warranty End Date", fieldName: "warranty_end_date", text: "Warranty End Date", isFilter: true },
        { minWidth: 110, isMultiline: true, key: 9, ismultiline: true, isResizable: true, selected: false, name: "Energy Type", fieldName: "energy_type_desc", text: "Energy Type" , isFilter: true},
        { minWidth: 110, isMultiline: true, key: 10, ismultiline: true, isResizable: true, selected: false, name: "Meter Type", fieldName: "meter_type_desc", text: "Meter Type", isFilter: true },
        { minWidth: 110, isMultiline: true, key: 11, ismultiline: true, isResizable: true, selected: false, name: "MPAN", fieldName: "mpan", text: "MPAN", isFilter: true },
        { minWidth: 110, isMultiline: true, key: 12, ismultiline: true, isResizable: true, selected: false, name: "MPRN", fieldName: "mprn", text: "MPRN", isFilter: true },
        { minWidth: 120, isMultiline: true, key: 13, ismultiline: true, isResizable: true, selected: false, name: "Financed status", fieldName: "financed", text: "Financed status", isFilter: true },
        { minWidth: 120, isMultiline: true, key: 14, ismultiline: true, isResizable: true, selected: false, name: "Removal Reason", fieldName: "removal_reason", text: "Removal Reason", isFilter: true },
        { minWidth: 100, isMultiline: true, key: 15, ismultiline: true, isResizable: true, selected: false, name: "Return Reason", fieldName: "return_reason", text: "Return Reason", isFilter: true },
        { minWidth: 60, isMultiline: true, key: 16, ismultiline: true, isResizable: true, selected: false, name: "Mac Id", fieldName: "mac_id", text: "Mac Id", isFilter: true },
        { minWidth: 140, isMultiline: true, key: 17, ismultiline: true, isResizable: true, selected: false, name: "Purchase Date", fieldName: "purchase_date", text: "Purchase Date", isFilter: true },
        { minWidth: 110, isMultiline: true, key: 18, ismultiline: true, isResizable: true, selected: false, name: "Address", fieldName: "address", text: "Address", isFilter: true },
        { minWidth: 140, isMultiline: true, key: 19, ismultiline: true, isResizable: true, selected: false, name: "Invoice No", fieldName: "invoice_no", text: "Invoice No", isFilter: true },
        { minWidth: 140, isMultiline: true, key: 20, ismultiline: true, isResizable: true, selected: false, name: "Latest Event Date", fieldName: "event_date", text: "Latest Event Date", isFilter: true },
        { minWidth: 140, isMultiline: true, key: 21, ismultiline: true, isResizable: true, selected: false, name: "Uninstall Date", fieldName: "uninstall_date", text: "Uninstall Date",isFilter: true },
        { minWidth: 140, isMultiline: true, key: 22, ismultiline: true, isResizable: true, selected: false, name: "Installation Address", fieldName: "installation_address", text: "Installation Address",isFilter: true },
        { minWidth: 100, isMultiline: true, key: 23, ismultiline: true, isResizable: true, selected: false, name: "Installation", fieldName: "installation", text: "Installation",isFilter: true },
        { minWidth: 100, isMultiline: true, key: 24, ismultiline: true, isResizable: true, selected: false, name: "Supplier", fieldName: "supl_name", text: "Supplier",isFilter: true },
        { minWidth: 100, isMultiline: true, key: 25, ismultiline: true, isResizable: true, selected: false, name: "Postal Code", fieldName: "postal_code", text: "Postal Code",isFilter: false },
        { minWidth: 100, isMultiline: true, key: 26, ismultiline: true, isResizable: true, selected: false, name: "Supply Description", fieldName: "supl_desc", text: "Supply Description",isFilter: false },
        { minWidth: 100, isMultiline: true, key: 27, ismultiline: true, isResizable: true, selected: false, name: "Install Code", fieldName: "install_code", text: "Install Code",isFilter: false },
        { minWidth: 100, isMultiline: true, key: 28, ismultiline: true, isResizable: true, selected: false, name: "Gateway Serial No", fieldName: "gateway_serial_no", text: "Gateway Serial No",isFilter: false },
        { minWidth: 200, isMultiline: true, key: 29, ismultiline: true, isResizable: true, selected: false, name: "Device Configuration Id", fieldName: "device_configuration_id", text: "Device Configuration Id",isFilter: false },
        { minWidth: 200, isMultiline: true, key: 30, ismultiline: true, isResizable: true, selected: false, name: "Engineering Menu Password", fieldName: "engineering_menu_password", text: "Engineering Menu Password",isFilter: false },
        { minWidth: 200, isMultiline: true, key: 31, ismultiline: true, isResizable: true, selected: false, name: "Delivery Location", fieldName: "delivery_location", text: "Delivery Location",isFilter: false },
        { minWidth: 200, isMultiline: true, key: 32, ismultiline: true, isResizable: true, selected: false, name: "Delivery Reference", fieldName: "delivery_reference", text: "Delivery Reference",isFilter: false },
        { minWidth: 200, isMultiline: true, key: 33, ismultiline: true, isResizable: true, selected: false, name: "Order Reference", fieldName: "order_reference", text: "Order Reference",isFilter: false },
        { minWidth: 200, isMultiline: true, key: 34, ismultiline: true, isResizable: true, selected: false, name: "Carton Identifier", fieldName: "carton_identifier", text: "Carton Identifier",isFilter: false },
        { minWidth: 100, isMultiline: true, key: 35, ismultiline: true, isResizable: true, selected: false, name: "Manufacture Month", fieldName: "manu_month", text: "Manufacture Month" ,isFilter: false},
        { minWidth: 200, isMultiline: true, key: 36, ismultiline: true, isResizable: true, selected: false, name: "Manufacture Year", fieldName: "manu_year", text: "Manufacture Year",isFilter: false },
        { minWidth: 200, isMultiline: true, key: 37, ismultiline: true, isResizable: true, selected: false, name: "MPID", fieldName: "mpid", text: "MPID",isFilter: false },
        { minWidth: 200, isMultiline: true, key: 38, ismultiline: true, isResizable: true, selected: false, name: "Zigbee Mac Address", fieldName: "zigbee_mac_address", text: "Zigbee Mac Address" ,isFilter: false },
        { minWidth: 100, isMultiline: true, key: 39, ismultiline: true, isResizable: true, selected: false, name: "Zigbee Key", fieldName: "zigbee_key", text: "Zigbee Key",isFilter: false  },
        { minWidth: 100, isMultiline: true, key: 40, ismultiline: true, isResizable: true, selected: false, name: "DLMS Key", fieldName: "dlms_key", text: "DLMS Key",isFilter: false  },
        { minWidth: 100, isMultiline: true, key: 41, ismultiline: true, isResizable: true, selected: false, name: "ESME Variant", fieldName: "esme_letiant", text: "ESME Variant",isFilter: false  },
        { minWidth: 100, isMultiline: true, key: 42, ismultiline: true, isResizable: true, selected: false, name: "Order No", fieldName: "order_no", text: "Order No",isFilter: false  },
        { minWidth: 200, isMultiline: true, key: 43, ismultiline: true, isResizable: true, selected: false, name: "Scheduled Delivery Date", fieldName: "sched_delivery_date", text: "Scheduled Delivery Date",isFilter: false  },
        { minWidth: 100, isMultiline: true, key: 44, ismultiline: true, isResizable: true, selected: false, name: "Certificate No", fieldName: "certificate_no", text: "Certificate No",isFilter: false  },
        { minWidth: 100, isMultiline: true, key: 45, ismultiline: true, isResizable: true, selected: false, name: "Certificate Date", fieldName: "certificate_date", text: "Certificate Date" ,isFilter: false },
        { minWidth: 100, isMultiline: true, key: 46, ismultiline: true, isResizable: true, selected: false, name: "Sim Provider", fieldName: "sim_provider", text: "Sim Provider" ,isFilter: false },
        { minWidth: 100, isMultiline: true, key: 47, ismultiline: true, isResizable: true, selected: false, name: "Device Id", fieldName: "device_id", text: "Device Id",isFilter: false  },
        { minWidth: 200, isMultiline: true, key: 48, ismultiline: true, isResizable: true, selected: false, name: "Device Firmware Version", fieldName: "device_firmware_version", text: "Device Firmware Version",isFilter: false  },
        { minWidth: 100, isMultiline: true, key: 49, ismultiline: true, isResizable: true, selected: false, name: "Firmware Id", fieldName: "firmware_id", text: "Firmware Id",isFilter: false  },
        { minWidth: 200, isMultiline: true, key: 50, ismultiline: true, isResizable: true, selected: false, name: "Purchase Cost", fieldName: "purchase_cost", text: "Purchase Cost",isFilter: false  },
        { minWidth: 110, isMultiline: true, key: 51, ismultiline: true, isResizable: true, selected: false, name: "Asset Code", fieldName: "asset_code", text: "Asset Code", isFilter: true },
        { minWidth: 110, isMultiline: true, key: 52, ismultiline: true, isResizable: true, selected: false, name: "Sold date", fieldName: "sold_date", text: "Sold date", isFilter: true },
        { minWidth: 110, isMultiline: true, key: 53, ismultiline: true, isResizable: true, selected: false, name: "Sold to", fieldName: "sold_to", text: "Sold to", isFilter: true },
    ],
    // { minWidth: 60, isMultiline: true, key: 0, ismultiline: true, isResizable: true, selected: false, name: "asset_id"), fieldName: "asset_id", text: "asset_id" },
    // { minWidth: 90, isMultiline: true, key: 13, ismultiline: true, isResizable: true, selected: false, name: "firmware_id", fieldName: "firmware_id", text: "firmware_id" },
    // { minWidth: 110, isMultiline: true, key: 14, ismultiline: true, isResizable: true, selected: false, name: "price_rental_id", fieldName: "price_rental_id", text: "price_rental_id" },
    // { minWidth: 140, isMultiline: true, key: 15, ismultiline: true, isResizable: true, selected: false, name: "asset_custodian_id", fieldName: "asset_custodian_id", text: "asset_custodian_id" },
    // { minWidth: 80, isMultiline: true, key: 16, ismultiline: true, isResizable: true, selected: false, name: "asn_id"), fieldName: "asn_id", text: "asn_id" },
    // { minWidth: 90, isMultiline: true, key: 18, ismultiline: true, isResizable: true, selected: false, name: "asset_status", fieldName: "asset_status", text: "asset_status" },
    warranty_from_dt: "",
    warranty_to_dt: "",
    purchase_to_dt: "",
    purchase_from_dt: "",
    asset_model_arr: [],
    asset_manu_arr: [],
    asset_type_arr: [],
    asset_owner_arr: [],
    energy_type_arr: [],
    meter_type_arr: [],
    event_type_arr: [],
    asset_supl_arr:[],
    apply_filter_btn: false,
    search_data: "",
    export_data :[],
    export_flag: false,
    generate_csv_flag:1,
    downUrl:"", //portfolio download url
    total_count :null,
    clearFlag : false,
    data_fetching:false,
    data_fetch_portfolio:true
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case FILTER_OPTIONS_FETCHING_COMPLETE:
            return { ...state, ...action.payload };
        case ASSET_MODEL_FETCHING_COMPLETE:
            return { ...state, ...action.payload };
        case CLOSE_ADVANCED_FILTER_POPUP:
            return { ...state, ...action.payload };
        case SET_ASSET_TITILES:
            return { ...state, ...action.payload };
        case ASSETS_FETCHING_COMPLETE:
            return { ...state, ...action.payload };
        case SET_ASSET_DATA:
            return { ...state, ...action.payload };
        case SET_ASSET_PAGE:
            return { ...state, ...action.payload };
        case CSV_GENERATE_COMPLETE:
            return { ...state, ...action.payload}
        default:
            return state;
    }
};