export const SET_SUPPLIERTYPE_DATA = "SET_SUPPLIERTYPE_DATA";
export const SUPPLIERTYPE_FETCHING_INIT = "SUPPLIERTYPE_FETCHING_INIT"
export const SUPPLIERTYPE_FETCHING_COMPLETE = "SUPPLIERTYPE_FETCHING_COMPLETE"
export const SUPPLIERTYPE_FETCHING_DISMISS = "SUPPLIERTYPE_FETCHING_DISMISS"
export const ADD_NEW_SUPPLIERTYPE = "ADD_NEW_SUPPLIERTYPE"
export const ADD_NEW_SUPPLIERTYPE_PREVIEW = "ADD_NEW_SUPPLIERTYPE_PREVIEW"
export const ADD_NEW_SUPPLIERTYPE_DISMISS = "ADD_NEW_SUPPLIERTYPE_DISMISS"
export const EDIT_SUPPLIERTYPE_PREVIEW = "EDIT_SUPPLIERTYPE_PREVIEW"
export const EDIT_SUPPLIERTYPE = "EDIT_SUPPLIERTYPE"
export const EDIT_SUPPLIERTYPE_DISMISS = "EDIT_SUPPLIERTYPE_DISMISS"
export const DELETE_SUPPLIERTYPE_PREVIEW = "DELETE_SUPPLIERTYPE_PREVIEW"
export const DELETE_SUPPLIERTYPE_DISMISS = "DELETE_SUPPLIERTYPE_DISMISS"
export const DELETE_SUPPLIERTYPE = "DELETE_SUPPLIERTYPE"
export const DISABLE_SUPPLIERTYPE_PREVIEW = "DISABLE_SUPPLIERTYPE_PREVIEW"
export const DISABLE_SUPPLIERTYPE_DISMISS = "DISABLE_SUPPLIERTYPE_DISMISS"
export const DISABLE_SUPPLIERTYPE = "DISABLE_SUPPLIERTYPE"
export const SET_SUPPLIERTYPE_ROLES = "SET_SUPPLIERTYPE_ROLES"
export const SET_SUPPLIERTYPE_PAGE = "SET_SUPPLIERTYPE_PAGE"
