export const SET_BULK_ASN_FILE_DATA = "SET_BULK_ASN_FILE_DATA";
export const DATA_FETCHING_INIT = "DATA_FETCHING_INIT";
export const DATA_FETCHING_DISMISS = "DATA_FETCHING_DISMISS";
export const FETCH_BULK_ASN_FILE_LIST_COMPLETE = "FETCH_BULK_ASN_FILE_LIST_COMPLETE";
export const OPEN_NEW_UPLOAD_POPUP = "OPEN_NEW_UPLOAD_POPUP";
export const SET_REVIEW_BULK_ASN_FILE_DATA="SET_REVIEW_BULK_ASN_FILE_DATA"
export const DATA_SUBMIT_INIT = "DATA_SUBMIT_INIT";
export const DATA_SUBMIT_DISMISS = "DATA_SUBMIT_DISMISS";
export const UPLOAD_BULK_ASN_FILE_COMPLETE = "UPLOAD_BULK_ASN_FILE_COMPLETE";
export const CLOSE_NEW_UPLOAD_POPUP = "CLOSE_NEW_UPLOAD_POPUP";
export const OPEN_ERR_FILE_DOWNLOAD_POPUP = "OPEN_ERR_FILE_DOWNLOAD_POPUP";
export const CLOSE_ERR_FILE_DOWNLOAD_POPUP = "CLOSE_ERR_FILE_DOWNLOAD_POPUP";
export const SET_FILTER_STATUS = "SET_FILTER_STATUS";
export const BULK_ASN_ASSET_FETCHING_INIT = "BULK_ASN_ASSET_FETCHING_INIT";
export const BULK_ASN_ASSET_FETCHING_DISMISS = "BULK_ASN_ASSET_FETCHING_DISMISS";
export const BULK_ASN_ASSET_FETCHING_COMPLETE = "BULK_ASN_ASSET_FETCHING_COMPLETE";
export const ACCEPT_BULK_ASN_VALIDATION_SUCCESS = "ACCEPT_BULK_ASN_VALIDATION_SUCCESS";
export const OPEN_BULK_ASN_REJECT_VALIDATION_POPUP ="OPEN_BULK_ASN_REJECT_VALIDATION_POPUP";
export const HIDE_BULK_ASN_VALIDATION_FILE_REJECT =" HIDE_BULK_ASN_VALIDATION_FILE_REJECT";
export const BULK_ASN_REJECT_VALIDATION_COMPLETE =" BULK_ASN_REJECT_VALIDATION_COMPLETE";
const bulkASN={
    search:"",
    page:1, 
    perpage:10, 
    filter_status_bulkasn:"",
    data_fetching:false,
    bulk_asnfile_list:[],
    bulk_asnfilelist_refetch:false,
    details_view:0,
    bulk_asn_upload_popup:false,
    download_popup:false,
    asn_bulk_file:[],
    asn_bulk_file_title:"",
    bulkAsn_download_data:[],
    bulk_asnfile_details:[],
    search_view:"",
    page_view:1,
    perpage_view:10,
    bulk_asn_fileasset_list:[],
    bulk_asn_filelist_view_refetch:false,
    view_Bulk_asn_approve_popup :false,
    bulk_asn_approve_btn_disable:false,
    view_bulk_asn_reject_popup : false,
    bulk_asn_confirm_btn_disable : false,
    total_bulk_asnfile:0
}


export default (state = bulkASN, action={})=>{

    switch(action.type){
        case SET_BULK_ASN_FILE_DATA:
            return { ...state, ...action.payload };
        case DATA_FETCHING_INIT:
            return { ...state, ...action.payload, data_fetching: true, };
        case DATA_FETCHING_DISMISS:
            return { ...state, ...action.payload, data_fetching: false };
        case FETCH_BULK_ASN_FILE_LIST_COMPLETE:
            return { ...state, ...action.payload };
        case SET_REVIEW_BULK_ASN_FILE_DATA:
            return { ...state, ...action.payload };
        case OPEN_NEW_UPLOAD_POPUP:
            return { ...state, ...action.payload };
        case OPEN_ERR_FILE_DOWNLOAD_POPUP:
            return { ...state, ...action.payload };
        case DATA_SUBMIT_INIT:
            return { ...state, ...action.payload, add_btn_disable:true };
        case DATA_SUBMIT_DISMISS:
            return { ...state, ...action.payload, add_btn_disable: false };
        case UPLOAD_BULK_ASN_FILE_COMPLETE:
            return { ...state, ...action.payload };
        case CLOSE_NEW_UPLOAD_POPUP:
            return { ...state, ...action.payload };
        case CLOSE_ERR_FILE_DOWNLOAD_POPUP:
            return { ...state, ...action.payload };
        case SET_FILTER_STATUS:
            return { ...state, ...action.payload };
        case BULK_ASN_ASSET_FETCHING_INIT:
            return { ...state, ...action.payload,bulk_asn_filelist_view_refetch: false };
        case BULK_ASN_ASSET_FETCHING_DISMISS:
            return {...state, ...action.payload, data_fetching: false,bulk_asnfilelist_view_list: false};
        case BULK_ASN_ASSET_FETCHING_COMPLETE:
            return { ...state, ...action.payload, data_fetching: false};
        case ACCEPT_BULK_ASN_VALIDATION_SUCCESS: 
            return { ...state,  ...action.payload } 
        case OPEN_BULK_ASN_REJECT_VALIDATION_POPUP:
            return { ...state, ...action.payload, view_bulk_asn_reject_popup : true }
        case HIDE_BULK_ASN_VALIDATION_FILE_REJECT:
            return { ...state, view_bulk_asn_reject_popup: false, view_details: 1, }
        case BULK_ASN_REJECT_VALIDATION_COMPLETE:
            return { ...state, ...action.payload, view_bulk_asn_reject_popup: false, view_details: 0, bulk_asn_confirm_btn_disable:true }
        default:
            return state;
    }

}
