export const SET_RENTALTYPE_DATA = "SET_RENTALTYPE_DATA";
export const RENTALTYPE_FETCHING_INIT = "RENTALTYPE_FETCHING_INIT"
export const RENTALTYPE_FETCHING_COMPLETE = "RENTALTYPE_FETCHING_COMPLETE"
export const RENTALTYPE_FETCHING_DISMISS = "RENTALTYPE_FETCHING_DISMISS"
export const ADD_NEW_RENTALTYPE = "ADD_NEW_RENTALTYPE"
export const ADD_NEW_RENTALTYPE_PREVIEW = "ADD_NEW_RENTALTYPE_PREVIEW"
export const ADD_NEW_RENTALTYPE_DISMISS = "ADD_NEW_RENTALTYPE_DISMISS"
export const EDIT_RENTALTYPE_PREVIEW = "EDIT_RENTALTYPE_PREVIEW"
export const EDIT_RENTALTYPE = "EDIT_RENTALTYPE"
export const EDIT_RENTALTYPE_DISMISS = "EDIT_RENTALTYPE_DISMISS"
export const DELETE_RENTALTYPE_PREVIEW = "DELETE_RENTALTYPE_PREVIEW"
export const DELETE_RENTALTYPE_DISMISS = "DELETE_RENTALTYPE_DISMISS"
export const DELETE_RENTALTYPE = "DELETE_RENTALTYPE"
export const DISABLE_RENTALTYPE_PREVIEW = "DISABLE_RENTALTYPE_PREVIEW"
export const DISABLE_RENTALTYPE_DISMISS = "DISABLE_RENTALTYPE_DISMISS"
export const DISABLE_RENTALTYPE = "DISABLE_RENTALTYPE"
export const SET_RENTALTYPE_ROLES = "SET_RENTALTYPE_ROLES"
export const SET_RENTALTYPE_PAGE = "SET_RENTALTYPE_PAGE"
