export const SET_INSTALLATIONJOBTYPE_DATA = "SET_INSTALLATIONJOBTYPE_DATA";
export const INSTALLATIONJOBTYPE_FETCHING_INIT = "INSTALLATIONJOBTYPE_FETCHING_INIT"
export const INSTALLATIONJOBTYPE_FETCHING_COMPLETE = "INSTALLATIONJOBTYPE_FETCHING_COMPLETE"
export const INSTALLATIONJOBTYPE_FETCHING_DISMISS = "INSTALLATIONJOBTYPE_FETCHING_DISMISS"
export const ADD_NEW_INSTALLATIONJOBTYPE = "ADD_NEW_INSTALLATIONJOBTYPE"
export const ADD_NEW_INSTALLATIONJOBTYPE_PREVIEW = "ADD_NEW_INSTALLATIONJOBTYPE_PREVIEW"
export const ADD_NEW_INSTALLATIONJOBTYPE_DISMISS = "ADD_NEW_INSTALLATIONJOBTYPE_DISMISS"
export const EDIT_INSTALLATIONJOBTYPE_PREVIEW = "EDIT_INSTALLATIONJOBTYPE_PREVIEW"
export const EDIT_INSTALLATIONJOBTYPE = "EDIT_INSTALLATIONJOBTYPE"
export const EDIT_INSTALLATIONJOBTYPE_DISMISS = "EDIT_INSTALLATIONJOBTYPE_DISMISS"
export const DELETE_INSTALLATIONJOBTYPE_PREVIEW = "DELETE_INSTALLATIONJOBTYPE_PREVIEW"
export const DELETE_INSTALLATIONJOBTYPE_DISMISS = "DELETE_INSTALLATIONJOBTYPE_DISMISS"
export const DELETE_INSTALLATIONJOBTYPE = "DELETE_INSTALLATIONJOBTYPE"
export const DISABLE_INSTALLATIONJOBTYPE_PREVIEW = "DISABLE_INSTALLATIONJOBTYPE_PREVIEW"
export const DISABLE_INSTALLATIONJOBTYPE_DISMISS = "DISABLE_INSTALLATIONJOBTYPE_DISMISS"
export const DISABLE_INSTALLATIONJOBTYPE = "DISABLE_INSTALLATIONJOBTYPE"
export const SET_INSTALLATIONJOBTYPE_ROLES = "SET_INSTALLATIONJOBTYPE_ROLES"
export const SET_INSTALLATIONJOBTYPE_PAGE = "SET_INSTALLATIONJOBTYPE_PAGE"
export const INSTALLATIONJOBTYPE_CREATE_DETAILS_FETCHING_COMPLETE = "INSTALLATIONJOBTYPE_CREATE_DETAILS_FETCHING_COMPLETE"
