export const SET_STEPONE_DATA = "SET_STEPONE_DATA";
export const FETCH_ASN_ERROR_HISTORY_COMPLETE = "FETCH_ASN_ERROR_HISTORY_COMPLETE";
export const MAIL_SENT_COMPLETE = "MAIL_SENT_COMPLETE";
export const FILE_DOWNLOADING = "FILE_DOWNLOADING";
export const FETCH_ASN_SUPPORT_DATA = "FETCH_ASN_SUPPORT_DATA";

const initialState = {
  authLoading: true, // initial status that indicates user checking is executed
  steponestatus: 1,
  steponeresp:[],
  desc:"",
  disable_session_add_btn:true,
  process_session_btn:false,
  energy_supplier_option:[],
  energy_manufacture_option:[],
  energy_installer_option:[],
  energy_type_option:[],
  energy_typelist_fetch: false,//indication to fetch energy type
  meter_type_option:[],
  meter_typelist_fetch: false,//indication to fetch meter type
  asset_type_option:[],
  asn_update_status:[],
  asset_typelist_fetch: false,//indication to fetch meter type
  asn_error_files: [], //initial state to store asn file details list
  mail_send_arr: [], // stores status for each asn files
  asn_download_arr: [], // stores status for each asn files
  asn_error_detail:[],
  invoice_no:"",
  purchase_cost:"",
  purchase_date:"",
  warranty_period:"",
  map_id:"",
  batch_size:"",
  delivery_location:"",
  cartonsqty_on_pallet:"",
  devicesqty_in_carton:"",
  palletsqty_in_consignment:"",
  energy_supplier:"",
  installer:"",
  energy_type:"",
  originalname:"",
  mail_send_status:false,
  download_error_status:false,
  display_progress_bar:false,
  display_progress_bar_initial:true,
  display_count_start:false,
  fetchAsnDetails:false,
  meter_type:"",
  asset_type:"",
  asset_model_option: [],
  asset_owner_option: [],
  start_counter_bar:false,
  asset_model_refetch: false,
  selected_manu: "",
  selected_manu_text: "",
  selected_model:""
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_ASN_SUPPORT_DATA:
      return { ...state, ...action.payload }
    case SET_STEPONE_DATA:
      return { ...state, ...action.payload };
    case FETCH_ASN_ERROR_HISTORY_COMPLETE:
      return { ...state, ...action.payload };
    case MAIL_SENT_COMPLETE:
      return { ...state, ...action.payload, };
    case FILE_DOWNLOADING:
      return { ...state, ...action.payload }
    default:
      return state;
  }
};
