export const SET_COSTTYPE_DATA = "SET_COSTTYPE_DATA";
export const COSTTYPE_FETCHING_INIT = "COSTTYPE_FETCHING_INIT"
export const COSTTYPE_FETCHING_COMPLETE = "COSTTYPE_FETCHING_COMPLETE"
export const COSTTYPE_FETCHING_DISMISS = "COSTTYPE_FETCHING_DISMISS"
export const ADD_NEW_COSTTYPE = "ADD_NEW_COSTTYPE"
export const ADD_NEW_COSTTYPE_PREVIEW = "ADD_NEW_COSTTYPE_PREVIEW"
export const ADD_NEW_COSTTYPE_DISMISS = "ADD_NEW_COSTTYPE_DISMISS"
export const EDIT_COSTTYPE_PREVIEW = "EDIT_COSTTYPE_PREVIEW"
export const EDIT_COSTTYPE = "EDIT_COSTTYPE"
export const EDIT_COSTTYPE_DISMISS = "EDIT_COSTTYPE_DISMISS"
export const DELETE_COSTTYPE_PREVIEW = "DELETE_COSTTYPE_PREVIEW"
export const DELETE_COSTTYPE_DISMISS = "DELETE_COSTTYPE_DISMISS"
export const DELETE_COSTTYPE = "DELETE_COSTTYPE"
export const DISABLE_COSTTYPE_PREVIEW = "DISABLE_COSTTYPE_PREVIEW"
export const DISABLE_COSTTYPE_DISMISS = "DISABLE_COSTTYPE_DISMISS"
export const DISABLE_COSTTYPE = "DISABLE_COSTTYPE"
export const SET_COSTTYPE_ROLES = "SET_COSTTYPE_ROLES"
export const SET_COSTTYPE_PAGE = "SET_COSTTYPE_PAGE"
