export const SET_POSTALCODE_DATA = "SET_POSTALCODE_DATA";
export const POSTALCODE_FETCHING_INIT = "POSTALCODE_FETCHING_INIT"
export const POSTALCODE_FETCHING_COMPLETE = "POSTALCODE_FETCHING_COMPLETE"
export const POSTALCODE_FETCHING_DISMISS = "POSTALCODE_FETCHING_DISMISS"
export const ADD_NEW_POSTALCODE = "ADD_NEW_POSTALCODE"
export const ADD_NEW_POSTALCODE_PREVIEW = "ADD_NEW_POSTALCODE_PREVIEW"
export const ADD_NEW_POSTALCODE_DISMISS = "ADD_NEW_POSTALCODE_DISMISS"
export const EDIT_POSTALCODE_PREVIEW = "EDIT_POSTALCODE_PREVIEW"
export const EDIT_POSTALCODE = "EDIT_POSTALCODE"
export const EDIT_POSTALCODE_DISMISS = "EDIT_POSTALCODE_DISMISS"
export const DELETE_POSTALCODE_PREVIEW = "DELETE_POSTALCODE_PREVIEW"
export const DELETE_POSTALCODE_DISMISS = "DELETE_POSTALCODE_DISMISS"
export const DELETE_POSTALCODE = "DELETE_POSTALCODE"
export const DISABLE_POSTALCODE_PREVIEW = "DISABLE_POSTALCODE_PREVIEW"
export const DISABLE_POSTALCODE_DISMISS = "DISABLE_POSTALCODE_DISMISS"
export const DISABLE_POSTALCODE = "DISABLE_POSTALCODE"
export const SET_POSTALCODE_ROLES = "SET_POSTALCODE_ROLES"
export const SET_POSTALCODE_PAGE = "SET_POSTALCODE_PAGE"
