import {
    ADD_NEW_COMPANY, DELETE_COMPANY, DELETE_COMPANY_DISMISS, DELETE_COMPANY_PREVIEW,
    DISABLE_COMPANY, DISABLE_COMPANY_DISMISS, DISABLE_COMPANY_PREVIEW,
    EDIT_COMPANY, EDIT_COMPANY_DISMISS, EDIT_COMPANY_PREVIEW, SET_COMPANY_DATA,
    COMPANY_FETCHING_COMPLETE, COMPANY_FETCHING_INIT, COMPANY_FETCHING_DISMISS, SET_COMPANY_PAGE,
    ADD_NEW_COMPANY_DISMISS, ADD_NEW_COMPANY_PREVIEW, SET_COMPANY_ORGANISATION,ADD_NEW_COMPANY_ERROR,
    ALL_COMPANY_FETCHING_COMPLETE
} from "./types";

const initialState = {
    company_organisation: [],
    company_list: [],//holds company list array
    companylist_fetching: false,//indicaction for fetch in progress
    companylist_refetch: false,//indication to refetch current company list array
    companylist_refetch_force: false,//indication to force refetch company list array from beginning 
    edit_company: null,//holds initial data of company being edited
    delete_company: null,//holds initial data of company opted to be deleted
    disable_company: null,//holds initial data of company opted to be disabled,
    search: "",//search keyword on company listing
    page: 1,//page number on company listing
    perpage: 10,//perpage limit on company listing
    total_companys: 0,//total count
    data_fetching:false,
    //new company params
    nC_name: "", nC_email: "", nC_organisation: "",nU_company:"", nC_error: "", nC_map_id: "",
    //edit company params
    eC_name: "", eC_email: "", eC_organisation: "", eC_error: "", eC_organisation_type_id:"", eC_map_id: "",
    customer_companys: [], //for customer company options 
    installer_companys: [], //for installer company options 
    installer_owner_companys: [], //for installer company options 
    customerArr: [], //for select customers array in add company
    eCustomerArr: [], //for select customers array in edit company
    company_logo: "", //for holding company logo file
    eC_logo: "", //for holding company logo being updating
    logo_preview: ""
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_COMPANY_DATA:
            return { ...state, ...action.payload };
        case ALL_COMPANY_FETCHING_COMPLETE:
            return { ...state, ...action.payload };
        case COMPANY_FETCHING_INIT: {
            return { ...state, companylist_fetching: true, companylist_refetch: false, companylist_refetch_force: false };
        }
        case COMPANY_FETCHING_COMPLETE: {
            return { ...state, companylist_fetching: false, ...action.payload };
        }
        case COMPANY_FETCHING_DISMISS: {
            return { ...state, companylist_fetching: false };
        }
        case SET_COMPANY_PAGE: {
            return { ...state, companylist_refetch: true, page: action.payload };
        }
        case ADD_NEW_COMPANY: {
            return { ...state, companylist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_COMPANY_PREVIEW: {
            return { ...state, new_company_popup: true, nC_name: "",nC_email: "", nC_organisation: "", nU_error: "" }
        }
        case ADD_NEW_COMPANY_DISMISS: {
            return {
                ...state,
                ...action.payload, 
                new_company_popup: false, 
                nC_name: "", 
                nC_email: "", 
                nC_organisation: "", 
                nU_error: "", 
                add_btn_disable: false, 
                nC_map_id: "", 
                customerArr: [],
                company_logo: "",
                logo_preview: ""
            }
        }
        case ADD_NEW_COMPANY_ERROR: {
            return { ...state, new_company_popup: true, ...action.payload, add_btn_disable: false }
        }
        case EDIT_COMPANY_PREVIEW: {
            return { ...state, eC_error: "", ...action.payload }
        }
        case EDIT_COMPANY: {
            return { ...state, companylist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_COMPANY_DISMISS: {
            return { ...state, edit_company: null, logo_preview: "", company_logo: "", eC_logo: ""  }
        }
        case DELETE_COMPANY_PREVIEW: {
            return { ...state, delete_company: action.payload }
        }
        case DELETE_COMPANY: {
            return { ...state, companylist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_COMPANY_DISMISS: {
            return { ...state, delete_company: null }
        }
        case DISABLE_COMPANY_PREVIEW: {
            return { ...state, disable_company: action.payload }
        }
        case DISABLE_COMPANY: {
            return { ...state, disable_company: null, companylist_refetch: true }
        }
        case DISABLE_COMPANY_DISMISS: {
            return { ...state, disable_company: null }
        }
        case SET_COMPANY_ORGANISATION: {
            return { ...state, company_organisation: action.payload }
        }
        default:
            return state;
    }
};
