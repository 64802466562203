export const SET_RELEASE_DATA = "SET_RELEASE_DATA";
export const DATA_SUBMIT_INIT = "DATA_SUBMIT_INIT";
export const DATA_SUBMIT_DISMISS = "DATA_SUBMIT_DISMISS";
export const DATA_FETCHING_INIT = "DATA_FETCHING_INIT";
export const DATA_FETCHING_DISMISS = "DATA_FETCHING_DISMISS";
export const FETCH_RELEASE_LIST_COMPLETE = "FETCH_RELEASE_LIST_COMPLETE";
export const ADD_NEW_RELEASE = "ADD_NEW_RELEASE";
export const ADD_NEW_RELEASE_PREVIEW = "ADD_NEW_RELEASE_PREVIEW";
export const ADD_NEW_RELEASE_DISMISS = "ADD_NEW_RELEASE_DISMISS";
export const EDIT_RELEASE_PREVIEW = "EDIT_RELEASE_PREVIEW";
export const EDIT_RELEASE_DISMISS = "EDIT_RELEASE_DISMISS";
export const EDIT_RELEASE = "EDIT_RELEASE";
export const SINGLE_DATA_FETCHING_INIT = "SINGLE_DATA_FETCHING_INIT";
export const SINGLE_DATA_FETCHING_DISMISS = "SINGLE_DATA_FETCHING_DISMISS";
export const SINGLE_FETCH_RELEASE_LIST_COMPLETE = "SINGLE_FETCH_RELEASE_LIST_COMPLETE";
export const TEMPLATE_LIST_FETCHING_INIT = "TEMPLATE_LIST_FETCHING_INIT";
export const TEMPLATE_LIST_FETCHING_COMPLETE = "TEMPLATE_LIST_FETCHING_COMPLETE";
export const TEMPLATE_LIST_FETCHING_DISMISS = "TEMPLATE_LIST_FETCHING_DISMISS";

const releaseState = {
  release_list: [],
  total_release_list: 0,
  serach: "",
  page: 1,
  perpage: 10,
  release_list_refetch: false,
  data_fetching: false,
  release_note_title: "",
  start_date: "",
  end_date: "",
  release_note_version: "",
  release_note_desc: "",
  add_btn_disable: false,
  release_popup: false,
  editRelease: null,
  edit_btn_disable: false,
  e_release_note_title: "",
  e_start_dt: "",
  e_end_dt: "",
  e_release_note_version: "",
  e_release_note_desc: "",
  edit_details: [],
  single_release_list: [],
  single_data_fetching: false,
  view_release_details:[],
  view_page:0,
  decodedText:"",
  mail:[],
  save_btn_disable:false,
  template_list:[],
  template_id:null,
  template_list_refetch:false

};

export default (state = releaseState, action = {}) => {
  switch (action.type) {
    case SET_RELEASE_DATA:
      return { ...state, ...action.payload };
    case DATA_SUBMIT_INIT:
      return { ...state, ...action.payload };
    case DATA_SUBMIT_DISMISS:
      return { ...state, ...action.payload };
    case DATA_FETCHING_INIT:
      return { ...state, ...action.payload, data_fetching: true };
    case DATA_FETCHING_DISMISS:
      return { ...state, ...action.payload, data_fetching: false };
    case FETCH_RELEASE_LIST_COMPLETE:
      return { ...state, ...action.payload, data_fetching: false };
    case ADD_NEW_RELEASE:
      return { ...state, release_list_refetch: true, add_btn_disable: false };
    case ADD_NEW_RELEASE_PREVIEW: {
      return {
        ...state, 
        release_popup: true, 
        release_note_title: "",
        start_date: "",
        end_date: "",
        release_note_version: "",
        release_note_desc: "",
        mail:[],
        template_list_refetch:true
      }
    }
    case ADD_NEW_RELEASE_DISMISS: {
      return { ...state, release_popup: false, ...action.payload, add_btn_disable: false,save_btn_disable:false}
    }
    case EDIT_RELEASE_PREVIEW: {
      return { ...state, ...action.payload }
    }
    case EDIT_RELEASE_DISMISS: {
      return { ...state, editRelease: null }
    }
    case EDIT_RELEASE: {
      return { ...state, release_list_refetch: true, add_btn_disable: false,save_btn_disable:false }
    }
    case SINGLE_DATA_FETCHING_INIT:
      return { ...state, ...action.payload, single_data_fetching: true };
    case SINGLE_DATA_FETCHING_DISMISS:
      return { ...state, ...action.payload, single_data_fetching: false };
    case SINGLE_FETCH_RELEASE_LIST_COMPLETE:
      return { ...state, ...action.payload, single_data_fetching: false };
    case TEMPLATE_LIST_FETCHING_INIT:
      return { ...state, ...action.payload}
    case TEMPLATE_LIST_FETCHING_COMPLETE:
      return { ...state, ...action.payload}
    case TEMPLATE_LIST_FETCHING_DISMISS:
      return { ...state, ...action.payload}
    default:
      return state;
  }
};
