export const SET_BULKUPDATE_DATA = "SET_BULKUPDATE_DATA";
export const FETCH_BULKUPDATE_ERROR_HISTORY_COMPLETE = "FETCH_BULKUPDATE_ERROR_HISTORY_COMPLETE";
export const SET_STEPONE_RESPONSE = "SET_STEPONE_RESPONSE";
export const SET_BULK_UPDATE_FILE_TYPE_OPTIONS = "SET_BULK_UPDATE_FILE_TYPE_OPTIONS";
export const SET_PROGRESS_RESPONSE = "SET_PROGRESS_RESPONSE";

const initialState = {
  authLoading: true, // initial status that indicates user checking is executed
  step_view: 1, //  1 => step one(add file),
  //  2=> step two(confirmupload),
  //  3=> upload summary
  disable_session_add_btn: false,
  disable_remove_btn: false,
  export_btn_disable: false,
  download_btn_disable: false,
  remove_file_history_btn_disable: false,
  bulk_update_file: '',
  bulk_update_file_type: null,
  bulk_update_file_type_name: null,
  fetch_bulkupdate_error_files: true,
  bulkupdate_error_files: [], //array to store error files history
  step_one_response: [], //array to store first 10 records from file
  bulk_update_file_details: {},
  upload_summary_details: null,
  bulk_update_file_description: "",
  bulk_update_file_type_options: [], //array to store bulk update types to show on drop down
  display_progress_bar_initial: "", //for progress bar
  fetch_bulk_file_progress: false, //for refetch bulk update progress
  display_progress_bar: true,
  bulk_update_progress_details: "",
  mail_send_btn: true,
  export_csv_btn: true,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_BULKUPDATE_DATA:
      return { ...state, ...action.payload };
    case FETCH_BULKUPDATE_ERROR_HISTORY_COMPLETE:
      return { ...state, ...action.payload }
    case SET_STEPONE_RESPONSE:
      return { ...state, ...action.payload }
    case SET_BULK_UPDATE_FILE_TYPE_OPTIONS:
      return { ...state, ...action.payload }
    case SET_PROGRESS_RESPONSE:
      return { ...state, ...action.payload }
    default:
      return state;
  }
};
