export const SET_STATUSTYPE_DATA = "SET_STATUSTYPE_DATA";
export const STATUSTYPE_FETCHING_INIT = "STATUSTYPE_FETCHING_INIT"
export const STATUSTYPE_FETCHING_COMPLETE = "STATUSTYPE_FETCHING_COMPLETE"
export const STATUSTYPE_FETCHING_DISMISS = "STATUSTYPE_FETCHING_DISMISS"
export const ADD_NEW_STATUSTYPE = "ADD_NEW_STATUSTYPE"
export const ADD_NEW_STATUSTYPE_PREVIEW = "ADD_NEW_STATUSTYPE_PREVIEW"
export const ADD_NEW_STATUSTYPE_DISMISS = "ADD_NEW_STATUSTYPE_DISMISS"
export const EDIT_STATUSTYPE_PREVIEW = "EDIT_STATUSTYPE_PREVIEW"
export const EDIT_STATUSTYPE = "EDIT_STATUSTYPE"
export const EDIT_STATUSTYPE_DISMISS = "EDIT_STATUSTYPE_DISMISS"
export const DELETE_STATUSTYPE_PREVIEW = "DELETE_STATUSTYPE_PREVIEW"
export const DELETE_STATUSTYPE_DISMISS = "DELETE_STATUSTYPE_DISMISS"
export const DELETE_STATUSTYPE = "DELETE_STATUSTYPE"
export const DISABLE_STATUSTYPE_PREVIEW = "DISABLE_STATUSTYPE_PREVIEW"
export const DISABLE_STATUSTYPE_DISMISS = "DISABLE_STATUSTYPE_DISMISS"
export const DISABLE_STATUSTYPE = "DISABLE_STATUSTYPE"
export const SET_STATUSTYPE_ROLES = "SET_STATUSTYPE_ROLES"
export const SET_STATUSTYPE_PAGE = "SET_STATUSTYPE_PAGE"
