import React from "react";
import Routes from "./routes/index";
import { hot } from "react-hot-loader/root";
import WithTheme from "./components/theme";

function App() {
  return (
    
      <WithTheme>
        <Routes />
      </WithTheme>
    
  );
}


export default hot(App);
