export const SET_FINANCED_FILE_DATA = "SET_FINANCED_FILE_DATA";
export const DATA_SUBMIT_INIT = "DATA_SUBMIT_INIT";
export const UPLOAD_FINANCED_FILE_COMPLETE = "UPLOAD_FINANCED_FILE_COMPLETE";
export const DATA_SUBMIT_DISMISS = "DATA_SUBMIT_DISMISS";
export const DATA_FETCHING_INIT = "DATA_FETCHING_INIT";
export const DATA_FETCHING_DISMISS = "DATA_FETCHING_DISMISS";
export const FETCH_FINANCE_FILE_LIST_COMPLETE ="FETCH_FINANCE_FILE_LIST_COMPLETE";
export const ACCEPT_FINANCE_VALIDATION_SUCCESS = "ACCEPT_FINANCE_VALIDATION_SUCCESS";
export const FINANCE_REJECT_VALIDATION_COMPLETE ="FINANCE_REJECT_VALIDATION_COMPLETE";
export const OPEN_FINANCE_REJECT_VALIDATION_POPUP = "OPEN_FINANCE_REJECT_VALIDATION_POPUP";
export const OPEN_ERR_FILE_DOWNLOAD_POPUP = "OPEN_ERR_FILE_DOWNLOAD_POPUP";
export const CLOSE_ERR_FILE_DOWNLOAD_POPUP = "CLOSE_ERR_FILE_DOWNLOAD_POPUP";
export const REPORT_DATA_FETCHING_INIT = "REPORT_DATA_FETCHING_INIT";
export const REPORT_DATA_FETCHING_DISMISS = "REPORT_DATA_FETCHING_DISMISS";
export const FETCH_FINANCE_REPORT_COMPLETE = "FETCH_FINANCE_REPORT_COMPLETE";
export const SET_FINANCE_REPORTS_FILE_DATA="SET_FINANCE_REPORTS_FILE_DATA"
const financedState = {
  financed_file_title: "",
  financed_files: [],
  view: 0,
  financed_file_list_refetch: true,
  disable_btn_flg: false,
  finance_list: [],
  data_fetching: false,
  finance_id: "",
  view_finance_approve_popup: false,
  finance_approve_btn_disable: false,
  view_finance_reject_popup: false,
  finance_confirm_btn_disable: false,
  processing_finance_popup: false,
  complete_flag: false,
  process_finance_refetch: false,
  view_finance_refetch: false,
  view_list_finance: [],
  result: {},
  page: 1,
  perpage: 10,
  download_popup: false,
  finance_download_data: [],
  open_popup: false,
  search: "",
  view_details: [],
  finance_report: [],
  page_report: 1,
  perpage_report: 10,
  financed_report_refetch: false,
  report_data_fetching: false,
  selectedDate:"",
    showSelectedDate:'',
    to: "",
    from: "",
    export_support_flag_value:1,
    // preview_confirm_refetch: false,
    index_loading: false,
    preview_data:[],
    cnfm_value:'',
    confirm_preview_fetching:false,
    preview_page : 0,
    preview_perpage : 10,
    result_flag:false,
    result_count:'',
    finance_value:'',
    event_value:'',
  //reports-->page_report,perpage_report,finance_report,financed_report_refetch,report_data_fetching,REPORT_DATA_FETCHING_INIT,REPORT_DATA_FETCHING_DISMISS,FETCH_FINANCE_REPORT_COMPLETE
  // defaultValue:['1'],
      export_support_flag_value_report:1
};

export default (state = financedState, action = {}) => {
  switch (action.type) {
    case SET_FINANCED_FILE_DATA:
      return { ...state, ...action.payload };
    case UPLOAD_FINANCED_FILE_COMPLETE:
      return { ...state, ...action.payload,preview_fetching:false };
    case OPEN_ERR_FILE_DOWNLOAD_POPUP:
      return { ...state, ...action.payload };
    case CLOSE_ERR_FILE_DOWNLOAD_POPUP:
      return { ...state, ...action.payload };
    case DATA_SUBMIT_INIT:
      return { ...state, ...action.payload, disable_btn_flg: true,index_loading: true };
    case DATA_SUBMIT_DISMISS:
      return { ...state, ...action.payload, disable_btn_flg: false };
    case DATA_FETCHING_INIT:
      return { ...state, ...action.payload, data_fetching: true };
    case DATA_FETCHING_DISMISS:
      return { ...state, ...action.payload, data_fetching: false };
    case FETCH_FINANCE_FILE_LIST_COMPLETE:
      return { ...state, ...action.payload };
    case ACCEPT_FINANCE_VALIDATION_SUCCESS:
      return { ...state, ...action.payload, financed_file_list_refetch: true };
    case OPEN_FINANCE_REJECT_VALIDATION_POPUP:
      return { ...state, ...action.payload, view_finance_reject_popup: true };
    case FINANCE_REJECT_VALIDATION_COMPLETE:
      return {
        ...state,
        ...action.payload,
        view_finance_reject_popup: false,
        view: 0,
        financed_file_list_refetch: true,
      };
    case REPORT_DATA_FETCHING_INIT:
      return { ...state, ...action.payload, report_data_fetching: true};
    case REPORT_DATA_FETCHING_DISMISS:
      return { ...state, ...action.payload, report_data_fetching: false };
    case FETCH_FINANCE_REPORT_COMPLETE:
      return { ...state, ...action.payload };
      case SET_FINANCE_REPORTS_FILE_DATA:
    return {...state, ...action.payload}
    default:
      return state;
  }
};
