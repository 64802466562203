import { combineReducers } from "redux";
import auth from "./auth/reducer";
import users from "./users/reducer";
import suppliers from "./suppliers/reducer";
import assettypes from "./asset-type/reducer";
import eventtypes from "./event-type/reducer";
import energytypes from "./energy-type/reducer";
import metertypes from "./meter-type/reducer";
import statustypes from "./status-type/reducer";
import rentaltypes from "./rental-type/reducer";
import costtypes from './cost-type/reducer';
import contracttypes from './contract-type/reducer';
import filetypes from './file-type/reducer';
import locationtypes from './location-type/reducer';
import invstatustypes from './inv-status-type/reducer';
import postalcodes from './hydro-postalcode/reducer';
import suppliercodes from './supplier-code/reducer';
import propertyfuelcategories from './propertyfuel-category/reducer';
import suppliertypes from './supplier-type/reducer';
import vats from "./vat/reducer";
import userroles from "./user-role/reducer";
import asnfiles from "./asnfile/reducer";
import asnconfig from "./asn-config/reducer";
import permissionmodule from './permission-module/reducer';
import bulkupdate from "./bulk-update/reducer";
import bulkasn from "./bulk-asn/reducer";
import validationsrequired from './validations-required/reducer';
import validationsapproved from './validations-approved/reducer';
import validationsrejected from './validations-rejected/reducer';
import portfolio from './portfolio/reducer'
import companys from './company/reducer'
import companyrequest from './company-request/reducer'
import jobs from './repatriation-job/reducer'
import containers from './repatriation-container/reducer'
import assetmodels from './asset-model/reducer'
import triagejobs from './triage-job/reducer'
import triagecontainers from './triage-container/reducer'
import installationfiles from './installation-file/reducer'
import outstandingqueries from './outstanding-query/reducer'
import installationjobtypes from './installation-job-type/reducer'
import uninstallation from './uninstallation/reducer'
import reports from './reports/reducer'
import assetsearch from './search-history-asset/reducer'
import meterpointsearch from './search-history-meterpoint/reducer'
import assetSearchByProperty from './search-history-property/reducer';
import cosEnhance from './cos/reducer'
import financed from './financed/reducer'
import uploadinstaller from './upload-install-installer/reducer';
import manualupload from './triage-manual-upload/reducer';
import triagereport from './triage-report/reducer';
import folders from './folders/reducer';
import dashboard from "./dashboard/reducer";
import * as History from "history";
import triageFailReason from "./triage-fail-reason/reducer";
import release from "./release/reducer";
import templates from "./email-templates/reducer";
import scrapped from "./scrapped-manual-upload/reducer";
import scrappedreport from "./scrapped-report/reducer";
import scrapLocation from "./scrap-location/reducer";
import installvsremovals  from "./installs-vs-removals/reducer";
export const history = History.createBrowserHistory();
export default () =>
  combineReducers({
    auth,
    users,
    suppliers,
    assettypes,
    eventtypes,
    energytypes,
    metertypes,
    statustypes,
    vats,
    rentaltypes,
    costtypes,
    contracttypes,
    filetypes,
    locationtypes,
    invstatustypes,
    postalcodes,
    userroles,
    suppliercodes,
    propertyfuelcategories,
    asnfiles,
    suppliertypes,
    asnconfig,
    permissionmodule,
    bulkupdate,
    bulkasn,
    validationsrequired,
    validationsapproved,
    validationsrejected,
    portfolio,
    companys,
    companyrequest,
    jobs,
    containers,
    assetmodels,
    triagejobs,
    triagecontainers,
    installationfiles,
    outstandingqueries,
    installationjobtypes,
    uninstallation,
    reports,
    assetsearch,
    meterpointsearch,
    assetSearchByProperty,
    // cos,
    financed,
    uploadinstaller,
    manualupload,
    triagereport,
    folders,
    dashboard,
    triageFailReason,
    release,
    cosEnhance,
    templates,
    scrapped,
    scrappedreport,
    scrapLocation,
    installvsremovals 
  });
