export const SET_METERTYPE_DATA = "SET_METERTYPE_DATA";
export const METERTYPE_FETCHING_INIT = "METERTYPE_FETCHING_INIT"
export const METERTYPE_FETCHING_COMPLETE = "METERTYPE_FETCHING_COMPLETE"
export const METERTYPE_FETCHING_DISMISS = "METERTYPE_FETCHING_DISMISS"
export const ADD_NEW_METERTYPE = "ADD_NEW_METERTYPE"
export const ADD_NEW_METERTYPE_PREVIEW = "ADD_NEW_METERTYPE_PREVIEW"
export const ADD_NEW_METERTYPE_DISMISS = "ADD_NEW_METERTYPE_DISMISS"
export const EDIT_METERTYPE_PREVIEW = "EDIT_METERTYPE_PREVIEW"
export const EDIT_METERTYPE = "EDIT_METERTYPE"
export const EDIT_METERTYPE_DISMISS = "EDIT_METERTYPE_DISMISS"
export const DELETE_METERTYPE_PREVIEW = "DELETE_METERTYPE_PREVIEW"
export const DELETE_METERTYPE_DISMISS = "DELETE_METERTYPE_DISMISS"
export const DELETE_METERTYPE = "DELETE_METERTYPE"
export const DISABLE_METERTYPE_PREVIEW = "DISABLE_METERTYPE_PREVIEW"
export const DISABLE_METERTYPE_DISMISS = "DISABLE_METERTYPE_DISMISS"
export const DISABLE_METERTYPE = "DISABLE_METERTYPE"
export const SET_METERTYPE_ROLES = "SET_METERTYPE_ROLES"
export const SET_METERTYPE_PAGE = "SET_METERTYPE_PAGE"
