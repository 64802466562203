export const SET_ENERGYTYPE_DATA = "SET_ENERGYTYPE_DATA";
export const ENERGYTYPE_FETCHING_INIT = "ENERGYTYPE_FETCHING_INIT"
export const ENERGYTYPE_FETCHING_COMPLETE = "ENERGYTYPE_FETCHING_COMPLETE"
export const ENERGYTYPE_FETCHING_DISMISS = "ENERGYTYPE_FETCHING_DISMISS"
export const ADD_NEW_ENERGYTYPE = "ADD_NEW_ENERGYTYPE"
export const ADD_NEW_ENERGYTYPE_PREVIEW = "ADD_NEW_ENERGYTYPE_PREVIEW"
export const ADD_NEW_ENERGYTYPE_DISMISS = "ADD_NEW_ENERGYTYPE_DISMISS"
export const EDIT_ENERGYTYPE_PREVIEW = "EDIT_ENERGYTYPE_PREVIEW"
export const EDIT_ENERGYTYPE = "EDIT_ENERGYTYPE"
export const EDIT_ENERGYTYPE_DISMISS = "EDIT_ENERGYTYPE_DISMISS"
export const DELETE_ENERGYTYPE_PREVIEW = "DELETE_ENERGYTYPE_PREVIEW"
export const DELETE_ENERGYTYPE_DISMISS = "DELETE_ENERGYTYPE_DISMISS"
export const DELETE_ENERGYTYPE = "DELETE_ENERGYTYPE"
export const DISABLE_ENERGYTYPE_PREVIEW = "DISABLE_ENERGYTYPE_PREVIEW"
export const DISABLE_ENERGYTYPE_DISMISS = "DISABLE_ENERGYTYPE_DISMISS"
export const DISABLE_ENERGYTYPE = "DISABLE_ENERGYTYPE"
export const SET_ENERGYTYPE_ROLES = "SET_ENERGYTYPE_ROLES"
export const SET_ENERGYTYPE_PAGE = "SET_ENERGYTYPE_PAGE"
