export const SET_COMPANY_DATA = "SET_COMPANY_DATA";
export const COMPANY_FETCHING_INIT = "COMPANY_FETCHING_INIT"
export const COMPANY_FETCHING_COMPLETE = "COMPANY_FETCHING_COMPLETE"
export const COMPANY_FETCHING_DISMISS = "COMPANY_FETCHING_DISMISS"
export const ADD_NEW_COMPANY = "ADD_NEW_COMPANY"
export const ADD_NEW_COMPANY_PREVIEW = "ADD_NEW_COMPANY_PREVIEW"
export const ADD_NEW_COMPANY_DISMISS = "ADD_NEW_COMPANY_DISMISS"
export const EDIT_COMPANY_PREVIEW = "EDIT_COMPANY_PREVIEW"
export const EDIT_COMPANY = "EDIT_COMPANY"
export const EDIT_COMPANY_DISMISS = "EDIT_COMPANY_DISMISS"
export const DELETE_COMPANY_PREVIEW = "DELETE_COMPANY_PREVIEW"
export const DELETE_COMPANY_DISMISS = "DELETE_COMPANY_DISMIS"
export const DELETE_COMPANY = "DELETE_COMPANY"
export const DISABLE_COMPANY_PREVIEW = "DISABLE_COMPANY_PREVIEW"
export const DISABLE_COMPANY_DISMISS = "DISABLE_COMPANY_DISMISS"
export const DISABLE_COMPANY = "DISABLE_COMPANY"
export const SET_COMPANY_ORGANISATION = "SET_COMPANY_ORGANISATION"
export const SET_COMPANY_PAGE = "SET_COMPANY_PAGE"
export const ADD_NEW_COMPANY_ERROR = "ADD_NEW_COMPANY_ERROR"
export const ALL_COMPANY_FETCHING_COMPLETE = "ALL_COMPANY_FETCHING_COMPLETE"
