export const SET_FOLDER_DATA = "SET_FOLDER_DATA";
export const OPEN_NEW_UPLOAD_POPUP = "OPEN_NEW_UPLOAD_POPUP";
export const CLOSE_NEW_UPLOAD_POPUP = "CLOSE_NEW_UPLOAD_POPUP";
export const DATA_FETCHING_INIT = "DATA_FETCHING_INIT";
export const DATA_FETCHING_DISMISS = "DATA_FETCHING_DISMISS";
export const FETCH_FOLDER_LIST_COMPLETE = "FETCH_FOLDER_LIST_COMPLETE";
export const DATA_SUBMIT_DISMISS = "DATA_SUBMIT_DISMISS";
export const DATA_SUBMIT_INIT = "DATA_SUBMIT_INIT";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE"
export const SET_IMAGE_LIST = "SET_IMAGE_LIST";
export const GENERATE_LINK_FETCHING_INIT = "GENERATE_LINK_FETCHING_INIT";
export const GENERATE_LINK_FETCHING_COMPLETE = "GENERATE_LINK_FETCHING_COMPLETE";
export const GENERATE_LINK_FETCHING_DISSMISS = "GENERATE_LINK_FETCHING_DISSMISS"
export const EXTERNAL_SHARE_FETCHING_INIT = "EXTERNAL_SHARE_FETCHING_INIT";
export const EXTERNAL_SHARE_FETCHING_COMPLETE = "EXTERNAL_SHARE_FETCHING_COMPLETE";
export const EXTERNAL_SHARE_FETCHING_DISSMISS = "EXTERNAL_SHARE_FETCHING_DISSMISS";
export const FETCH_FOLDER_IMAGE_LIST_COMPLETE = "FETCH_FOLDER_IMAGE_LIST_COMPLETE";
export const SUB_FOLDER_CREATE_COMPLETE = "SUB_FOLDER_CREATE_COMPLETE";
export const FOLDER_CREATE_COMPLETE = "FOLDER_CREATE_COMPLETE";
export const CLOSE_DELETE_POPUP = "CLOSE_DELETE_POPUP";
export const DELETE_FOLDER = "DELETE_FOLDER";
export const DELETE_FETCHING_DISMISS = "DELETE_FETCHING_DISMISS";
export const DELETE_SUBFOLDER = "DELETE_SUBFOLDER";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const UPLOAD_IMAGE_EXCEL_COMPLETE = "UPLOAD_IMAGE_EXCEL_COMPLETE";
export const FETCH_EXCEL_LIST_COMPLETE = "FETCH_EXCEL_LIST_COMPLETE";
export const OPEN_ERR_FILE_DOWNLOAD_POPUP = "OPEN_ERR_FILE_DOWNLOAD_POPUP";
export const CLOSE_ERR_FILE_DOWNLOAD_POPUP = "CLOSE_ERR_FILE_DOWNLOAD_POPUP";
export const SET_FILTER_STATUS = "SET_FILTER_STATUS";
export const ACCEPT_EXCEL_VALIDATION_SUCCESS = "ACCEPT_EXCEL_VALIDATION_SUCCESS"
export const HIDE_FTP_VALIDATION_FILE_REJECT = "HIDE_FTP_VALIDATION_FILE_REJECT";
export const FTP_REJECT_VALIDATION_COMPLETE = "FTP_REJECT_VALIDATION_COMPLETE";
export const CLOSE_POP_UP = "CLOSE_POP_UP";
export const OPEN_PROCESS_FAILED = "OPEN_PROCESS_FAILED";
export const EXCEL_VIEW_FETCHING_COMPLETE = "EXCEL_VIEW_FETCHING_COMPLETE";
export const VIEW_DATA_FETCHING_INIT = "VIEW_DATA_FETCHING_INIT";
export const VIEW_DATA_FETCHING_DISMISS = "VIEW_DATA_FETCHING_DISMISS";
export const FETCH_SHARE_BY_COMPLETE = "FETCH_SHARE_BY_COMPLETE";
export const FETCH_SHARE_SUB_LIST_COMPLETE = "FETCH_SHARE_SUB_LIST_COMPLETE";
export const FETCH_SHARE_IMAGE_LIST_COMPLETE = "FETCH_SHARE_IMAGE_LIST_COMPLETE";
export const FETCH_SHARE_WITH_COMPLETE = "FETCH_SHARE_WITH_COMPLETE";
export const FETCH_SHARE_WITH_SUB_LIST_COMPLETE = "FETCH_SHARE_WITH_SUB_LIST_COMPLETE";
export const ADD_REMOVE_USERS_COMPLETE = "ADD_REMOVE_USERS_COMPLETE";
export const FETCH_SHARE_USER_LIST = "FETCH_SHARE_USER_LIST"
export const SET_SHARE_BY_USER_LIST = "SET_SHARE_BY_USER_LIST";
export const SET_SHARE_USER_SELECTED = "SET_SHARE_USER_SELECTED"
export const PASSCODE_FETCHING_INIT = "PASSCODE_FETCHING_INIT";
export const PASSCODE_FETCHING_COMPLETE = "PASSCODE_FETCHING_COMPLETE";
export const PASSCODE_FETCHING_DISSMISS = "PASSCODE_FETCHING_DISSMISS";
export const EMAIL_SHARE_FETCHING_INIT = "EMAIL_SHARE_FETCHING_INIT";
export const GENERATE_PASSCODE_SUCCESS = "GENERATE_PASSCODE_SUCCESS";
export const DELETE_PASSCODE_SUCCESS = "DELETE_PASSCODE_SUCCESS";
export const FETCH_EXTERNAL_UPLOAD_LIST_COMPLETE = "FETCH_EXTERNAL_UPLOAD_LIST_COMPLETE";
export const EX_VIEW_DATA_FETCHING_INIT = "EX_VIEW_DATA_FETCHING_INIT";
export const EX_VIEW_DATA_FETCHING_DISMISS = "EX_VIEW_DATA_FETCHING_DISMISS";
export const EX_EXCEL_VIEW_FETCHING_COMPLETE = "EX_EXCEL_VIEW_FETCHING_COMPLETE";
export const EX_USER_REJECT_VALIDATION_COMPLETE = "EX_USER_REJECT_VALIDATION_COMPLETE";
export const EX_DATA_FETCHING_INIT = "EX_DATA_FETCHING_INIT";
export const EX_DATA_FETCHING_DISMISS = "EX_DATA_FETCHING_DISMISS";
const folders = {
    folder_name: "",
    folder_popup: false,
    search: "",
    folder_refetch: true,
    view: 0,
    sub_folder_popup: false,
    event_type: "",
    img_upload_popup: false,
    image_upload: [],
    serial_no: "",
    data_fetching: false,
    page: 1,
    perpage: 24,
    folder_list: [],
    onDrop: false,
    folderName: "",
    sub_folder_name: "",
    folder_count: 0,
    folder_count2: 1,
    excel_upload_popup: false,
    excel_add_btn_disable: false,
    image_file: [],
    image_file_title: "",
    Folderdetails: [],
    add_btn_disable: false,
    folder_view_refetch: true,
    parent_folder: 0,
    sub_folder_refetch: true,
    sub_folder_serach: "",
    sub_folder_page: 1,
    sub_folder_perpage: 24,
    sub_folder_list: [],
    temp: 0,
    temp_parent: 0,
    subsearch: "",
    imageList: [],
    subImageSearch: "",
    subFolderList: [],
    selected_sub_folder: "",
    image_sub_folder_page: 1,
    image_sub_folder_perpage: 24,
    image_sub_folder_list: [],
    total_image_list: "",
    current_folder_id: "",
    isFolder: 0,
    image_folder_view_refetch: true,
    share_folder_popup: false,
    is_private: true,
    subFolderId: "",
    imageUrl: "",
    showPopup: false,
    imageName: "",
    delete_popup: false,
    event_type_list: [],
    eventType: "",
    eventDate: "",
    serialNum: "",
    detailsPopup: false,
    detailFolderName: "",
    drop_Down: false,
    detailEventDate: "",
    detailEventType: "",
    delete_folder: false,
    del_btn_disable: false,
    delete_subfolder: false,
    imageId: "",
    imageKey: "",
    delete_image_popup: false,
    is_grid_view: true,
    is_folder_grid_view: true,
    is_sub_grid_view: true,
    genrated_key: "",
    gnrt_btn_disable: false,
    external_share_refetch: true,
    external_page: 1,
    external_perpage: 24,
    external_share_list: [],
    external_loading: false,
    external_title: "",
    external_image_page: 1,
    external_image_perpage: 24,
    external_image_list: [],
    external_sub_title: "",
    external_image_refetch: true,
    external_view: 0,
    temp_key: "",
    external_image_popup: false,
    generate_link_dissable_flag: true,
    fileDate: "",
    excel_flag: 0,
    excel_page: 1,
    excel_perpage: 24,
    excel_view_page: 1,
    excel_view_perpage: 24,
    excel_refetch: true,
    excel_view_refetch: true,
    excel_search: "",
    total_excel_list: "",
    excel_list: [],
    download_popup: false,
    excel_upload_download_data: {},
    filterstatus: "",
    excel_view_details: [],
    excel_view: false,
    excel_view_list: [],
    excel_total_view_list: "",
    view_approve_popup: false,
    view_reject_popup: false,
    open_popup: false,
    excel_view_search: "",
    view_data_fetching: false,
    share_by_list: [],
    total_share_by_list: "",
    share_by_refetch: true,
    shareBySearch: "",
    shareBy_page: 1,
    shareBy_perpage: 24,
    shareByView: 0,
    shareby_Sub_search: "",
    shareBy_Sub_page: 1,
    shareBy_Sub_perpage: 24,
    shareBy_Sub_refetch: true,
    shareby_sub_list: [],
    shareby_total_sublist: "",
    share_by_folder_name: "",
    share_by_image_page: 1,
    share_by_image_perpage: 24,
    share_by_image_refetch: true,
    share_by_image_list: [],
    share_by_image_total_list: "",
    share_by_image_search: "",
    share_by_sub_folder_name: "",
    passcode_popup: false,
    parentFolder: 0,
    tempParent: 0,
    sharebyme_grid: true,
    share_with_search: "",
    share_with_page: 1,
    share_with_perpage: 24,
    share_with_refetch: true,
    share_with_list: [],
    share_with_total_list: "",
    share_with_parent_folder: 0,
    share_with_temp_folder: 0,
    share_with_sublist: [],
    share_with_total_sublist: "",
    share_with_subsearch: "",
    share_with_subpage: 1,
    share_with_subperpage: 24,
    share_with_subrefetch: true,
    shareWithView: 0,
    share_with_folder_name: "",
    share_with_sub_folder_name: "",
    share_with_sub_image_search: "",
    share_with_sub_image_page: 1,
    share_with_sub_image_perpage: 24,
    share_with_sub_image_refetch: true,
    share_with_sub_image_list: [],
    share_with_sub_image_total: "",
    share_user_list_page: 1,
    share_user_list_perpage: 10,
    share_user_list: [],
    share_user_total_list: "",
    share_user_selected: [],
    viewFlag: "",
    share_Flag: "",
    emails: [],
    expiry_in: 1,
    show_count: 1,
    passcode_page: 1,
    passcode_perpage: 10,
    passcode_list: [],
    passcode_list_refetch: true,
    resend_mail_popup: false,
    delete_passcode_popup: false,
    shareWithme_grid: true,
    detailImageCount: "",
    share_by_user_refetch: false,
    share_by_user_list: [],
    shareFolderId: "",
    Sharedusers: [],
    deletedUser: [],
    user_id: "",
    selected_user_ids: [],
    External_excel_popup: false,
    External_image_popup: false,
    snd_mail_btn: false,
    delete_btn_passcode: false,
    share_folder_name: "",
    parent_folder_name: "",
    external_serial_no: "",
    excel_file_title: "",
    excel_file: [],
    external_upload_search: "",
    external_upload_page: 1,
    external_upload_perpage: 24,
    external_files_list: [],
    total_external_files_list: "",
    drop_flag: false,
    indexId: "",
    drop_folderview_flag: false,
    indexFolderViewId: "",
    ex_excel_page: 1,
    ex_excel_perpage: 10,
    ex_excel_search: "",
    ex_excel_list: [],
    ex_excel_refetch: true,
    ex_filterstatus: "",
    ex_excel_view_search: "",
    ex_excel_view_page: 1,
    ex_excel_view_perpage: 10,
    ex_view_list: [],
    ex_view_refetch: false,
    ex_view: 0,
    ex_approve_popup: false,
    ex_approve_btn_disable: false,
    ex_reject_popup: false,
    ex_reject_btn_disable: false,
    ex_showPopup: false,
    token: "",
    ex_imageList: [],
    sessionKey: "",
    ex_datafetching: false,
    ex_upload_download_data:{},
    ex_download_popup:false,
    linkExpire:true,
    external_refetch:false,
    status:0,
    ex_event_type:"",
    ex_fileDate:"",
    ex_event_type_list:[],
    processfail_popup:false,
    preview_image_popup: false,
    img_preview:[],
    share_with_me_popup:false,
    Excel_icon_Popup:false,
    Excel_popup_details:[],
    Csv_icon_Popup:false,
    Csv_popup_details:[],
    uploading_btn:false,
    preview_upload_disable: false,
    Ex_Csv_popup_details:[],
    Ex_Csv_icon_Popup:false,
    Ex_Excel_popup_details:[],
    Ex_Excel_icon_Popup:false,
    share_by_btn:false,
    share_with_btn:false,
    folder_share_btn:false,
    preview_image:[],
    preview_internal_image_popup:false,
    
}

export default (state = folders, action = {}) => {
        const actionObject = {
            SET_FOLDER_DATA: () => ({ ...state, ...action.payload }),
            OPEN_NEW_UPLOAD_POPUP: () => ({ ...state, ...action.payload }),
            CLOSE_NEW_UPLOAD_POPUP: () => ({ ...state, ...action.payload }),
            UPLOAD_IMAGE_EXCEL_COMPLETE: () => ({ ...state, ...action.payload }),
            SUB_FOLDER_CREATE_COMPLETE: () => ({ ...state, ...action.payload }),
            FOLDER_CREATE_COMPLETE: () => ({ ...state, ...action.payload }),
            GENERATE_LINK_FETCHING_INIT: () => ({ ...state, ...action.payload, gnrt_btn_disable: true, genrated_key: "" }),
            DATA_SUBMIT_INIT: () => ({ ...state, ...action.payload, add_btn_disable: true, excel_add_btn_disable: true }),
            DATA_SUBMIT_DISMISS: () => ({ ...state, ...action.payload, add_btn_disable: false, excel_add_btn_disable: false }),
            DELETE_FOLDER: () => ({ ...state, ...action.payload, folder_refetch: true, folder_view_refetch: true, del_btn_disable: false }),
            DELETE_SUBFOLDER: () => ({ ...state, ...action.payload, folder_view_refetch: true, del_btn_disable: false }),
            DELETE_FETCHING_DISMISS: () => ({ ...state, ...action.payload }),
            GENERATE_LINK_FETCHING_COMPLETE: () => ({ ...state, ...action.payload, gnrt_btn_disable: false }),
            DATA_FETCHING_INIT: () => ({ ...state, ...action.payload, data_fetching: true }),
            DATA_FETCHING_DISMISS: () => ({ ...state, ...action.payload, data_fetching: false }),
            GENERATE_LINK_FETCHING_DISSMISS: () => ({ ...state, ...action.payload, gnrt_btn_disable: false, genrated_key: "" }),
            FETCH_FOLDER_LIST_COMPLETE: () => ({ ...state, ...action.payload }),
            SET_IMAGE_LIST: () => ({ ...state, ...action.payload }),
            FETCH_FOLDER_IMAGE_LIST_COMPLETE: () => ({ ...state, ...action.payload }),
            CLOSE_DELETE_POPUP: () => ({ ...state, ...action.payload }),
            DELETE_IMAGE: () => ({ ...state, ...action.payload, image_folder_view_refetch: true }),
            EXTERNAL_SHARE_FETCHING_INIT: () => ({ ...state, ...action.payload, external_loading: true }),
            EXTERNAL_SHARE_FETCHING_COMPLETE: () => ({ ...state, ...action.payload, external_loading: false }),
            EXTERNAL_SHARE_FETCHING_DISSMISS: () => ({ ...state, ...action.payload, external_loading: false }),
            PASSCODE_FETCHING_INIT: () => ({ ...state, ...action.payload, data_fetching: true, passcode_list_refetch: true }),
            PASSCODE_FETCHING_COMPLETE: () => ({ ...state, ...action.payload, data_fetching: false, passcode_list_refetch: false }),
            PASSCODE_FETCHING_DISSMISS: () => ({ ...state, ...action.payload, data_fetching: false, passcode_list_refetch: false }),
            GENERATE_PASSCODE_SUCCESS: () => ({ ...state, ...action.payload, passcode_list_refetch: true }),
            EMAIL_SHARE_FETCHING_INIT: () => ({ ...state, ...action.payload, snd_mail_btn: true }),
            FETCH_EXCEL_LIST_COMPLETE: () => ({ ...state, ...action.payload }),
            OPEN_ERR_FILE_DOWNLOAD_POPUP: () => ({ ...state, ...action.payload }),
            CLOSE_ERR_FILE_DOWNLOAD_POPUP: () => ({ ...state, ...action.payload }),
            SET_FILTER_STATUS: () => ({ ...state, ...action.payload }),
            ACCEPT_EXCEL_VALIDATION_SUCCESS: () => ({ ...state, ...action.payload }),
            HIDE_FTP_VALIDATION_FILE_REJECT: () => ({ ...state, ...action.payload, view_reject_popup: false }),
            FTP_REJECT_VALIDATION_COMPLETE: () => ({ ...state, ...action.payload, view_reject_popup: false, excel_view: false }),
            CLOSE_POP_UP: () => ({ ...state, ...action.payload }),
            OPEN_PROCESS_FAILED: () => ({ ...state, ...action.payload }),
            VIEW_DATA_FETCHING_INIT: () => ({ ...state, ...action.payload, view_data_fetching: true }),
            VIEW_DATA_FETCHING_DISMISS: () => ({ ...state, ...action.payload, view_data_fetching: false, excel_view_refetch: false }),
            EXCEL_VIEW_FETCHING_COMPLETE: () => ({ ...state, ...action.payload, data_fetching: false, excel_view_refetch: false }),
            DELETE_PASSCODE_SUCCESS: () => ({ ...state, ...action.payload, delete_passcode_popup: false, delete_btn_passcode: false, passcode_list_refetch: true }),
            FETCH_SHARE_BY_COMPLETE: () => ({ ...state, ...action.payload }),
            FETCH_SHARE_SUB_LIST_COMPLETE: () => ({ ...state, ...action.payload }),
            FETCH_SHARE_IMAGE_LIST_COMPLETE: () => ({ ...state, ...action.payload }),
            FETCH_SHARE_WITH_COMPLETE: () => ({ ...state, ...action.payload }),
            FETCH_SHARE_WITH_SUB_LIST_COMPLETE: () => ({ ...state, ...action.payload }),
            SET_SHARE_USER_SELECTED: () => ({ ...state, ...action.payload }),
            FETCH_SHARE_USER_LIST: () => ({ ...state, ...action.payload }),
            SET_SHARE_BY_USER_LIST: () => ({ ...state, ...action.payload }),
            FETCH_EXTERNAL_UPLOAD_LIST_COMPLETE: () => ({ ...state, ...action.payload }),
            EX_VIEW_DATA_FETCHING_INIT: () => ({ ...state, ...action.payload, view_data_fetching: true }),
            EX_VIEW_DATA_FETCHING_DISMISS: () => ({ ...state, ...action.payload, view_data_fetching: false, ex_view_refetch: false }),
            EX_EXCEL_VIEW_FETCHING_COMPLETE: () => ({ ...state, ...action.payload, view_data_fetching: false, excel_view_refetch: false }),
            EX_USER_REJECT_VALIDATION_COMPLETE: () => ({ ...state, ...action.payload }),
            EX_DATA_FETCHING_INIT: () => ({ ...state, ...action.payload, ex_datafetching: true }),
            EX_DATA_FETCHING_DISMISS: () => ({ ...state, ...action.payload, ex_datafetching: false, linkExpire: false }), 
    };
    
    if (actionObject[action.type] === undefined) {
        return state; // Return unchanged state
    } else {
        // Call the appropriate action handler and return the updated state
        return actionObject[action.type]();
    }
}