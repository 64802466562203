import {
  CONFIRM_CREATE_REASONS,
  CREATE_NEW_REASON,
  DELETE_REASONS,
  DELETE_REASONS_DISMISS,
  DELETE_REASONS_PREVIEW,
  DELETE_SNAPSHOT_OBJ_SUCCESS,
  DETAILS_FETCHING_COMPLETE,
  REASONS_FETCHING_COMPLETE,
  REASONS_UPDATE_SUCCESS,
  SET_PRINT_PAGE_DATA,
  SET_REASONS_DATA,
  SET_REASONS_PAGE,
  SET_STATE_VALUES,
} from "./types";

const initialState = {
  reasons_list: [], //holds reasons list array
  reasonslist_fetching: false, //indication for fetch in progress
  reasonslist_refetch: false, //indication to refetch current reasons list array
  reasonslist_refetch_force: false, //indication to force refetch reasons list array from beginning
  data_fetching: false,
  search: "", //search keyword on reasons listing
  filter_status_triage_reason: 1, // filter by reason status
  page: 1, //page number on reasons listing
  perpage: 10, //per page limit on reasons listing
  total_reasons: 0, //total count
  create_reasons_popup: false, // toggle create reasons popup
  reason_snapshot_popup: false, // toggle snapshot view popup
  view_reason_detail_component: false, // toggle component reason detail view
  view_prod_reason_detail_component: false, // toggle component prod reason detail view
  reason_details: null,
  reason_id: null,
  reason_type: null,
  add_btn_disable: false,
  update_btn_disable: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case REASONS_UPDATE_SUCCESS:
      return { ...state, ...action.payload };
    case SET_STATE_VALUES:
      return { ...state, ...action.payload };
    case DELETE_SNAPSHOT_OBJ_SUCCESS:
      return { ...state, ...action.payload };
    case SET_PRINT_PAGE_DATA:
      return { ...state, ...action.payload };
    case CREATE_NEW_REASON:
      return { ...state, ...action.payload };
    case SET_REASONS_DATA:
      return { ...state, ...action.payload };
    case DETAILS_FETCHING_COMPLETE:
      return { ...state, ...action.payload };
    case REASONS_FETCHING_COMPLETE:
      return { ...state, ...action.payload };
    case SET_REASONS_PAGE:
      return { ...state, ...action.payload };
    case CONFIRM_CREATE_REASONS:
      return { ...state, ...action.payload };
    case DELETE_REASONS_PREVIEW:
      return { ...state, delete_job: action.payload };
    case DELETE_REASONS:
      return {
        ...state,
        joblist_refetch_force: true,
        search: "",
        del_btn_disable: false,
      };
    case DELETE_REASONS_DISMISS:
      return { ...state, delete_job: null };
    default:
      return state;
  }
};
