import {
    ADD_NEW_EVENTTYPE, DELETE_EVENTTYPE, DELETE_EVENTTYPE_DISMISS, DELETE_EVENTTYPE_PREVIEW,
    DISABLE_EVENTTYPE, DISABLE_EVENTTYPE_DISMISS, DISABLE_EVENTTYPE_PREVIEW,
    EDIT_EVENTTYPE, EDIT_EVENTTYPE_DISMISS, EDIT_EVENTTYPE_PREVIEW, SET_EVENTTYPE_DATA,
    EVENTTYPE_FETCHING_COMPLETE, EVENTTYPE_FETCHING_INIT, EVENTTYPE_FETCHING_DISMISS, SET_EVENTTYPE_PAGE,
    ADD_NEW_EVENTTYPE_DISMISS, ADD_NEW_EVENTTYPE_PREVIEW, SET_EVENTTYPE_ROLES
} from "./types";

const initialState = {
    eventtype_list: [],//holds event type list array
    eventtypelist_fetching: false,//indicaction for fetch in progress
    eventtypelist_refetch: false,//indication to refetch current event type list array
    eventtypelist_refetch_force: false,//indication to force refetch event type list array from beginning 
    edit_eventtype: null,//holds initial data of event type being edited
    delete_eventtype: null,//holds initial data of event type opted to be deleted
    disable_eventtype: null,//holds initial data of event type opted to be disabled,
    search: "",//search keyword on event type listing
    page: 1,//page number on event type listing
    perpage: 10,//perpage limit on event type listing
    total_eventtypes: 0,//total count
    //new event type params
    event_type_desc: "",
    //edit event type params
    eEvent_type_desc: ""
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_EVENTTYPE_DATA:
            return { ...state, ...action.payload };
        case EVENTTYPE_FETCHING_INIT: {
            return { ...state, eventtypelist_fetching: true, eventtypelist_refetch: false, eventtypelist_refetch_force: false };
        }
        case EVENTTYPE_FETCHING_COMPLETE: {
            return { ...state, eventtypelist_fetching: false, ...action.payload };
        }
        case EVENTTYPE_FETCHING_DISMISS: {
            return { ...state, eventtypelist_fetching: false };
        }
        case SET_EVENTTYPE_PAGE: {
            return { ...state, eventtypelist_refetch: true, page: action.payload };
        }
        case ADD_NEW_EVENTTYPE: {
            return { ...state, eventtypelist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_EVENTTYPE_PREVIEW: {
            return { ...state, new_eventtype_popup: true, event_type_desc: "" }
        }
        case ADD_NEW_EVENTTYPE_DISMISS: {
            return { ...state, new_eventtype_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_EVENTTYPE_PREVIEW: {
            return { ...state, eEvent_error: "", ...action.payload }
        }
        case EDIT_EVENTTYPE: {
            return { ...state, eventtypelist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_EVENTTYPE_DISMISS: {
            return { ...state, edit_eventtype: null }
        }
        case DELETE_EVENTTYPE_PREVIEW: {
            return { ...state, delete_eventtype: action.payload }
        }
        case DELETE_EVENTTYPE: {
            return { ...state, eventtypelist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_EVENTTYPE_DISMISS: {
            return { ...state, delete_eventtype: null }
        }
        case DISABLE_EVENTTYPE_PREVIEW: {
            return { ...state, disable_eventtype: action.payload }
        }
        case DISABLE_EVENTTYPE: {
            return { ...state, disable_eventtype: null, eventtypelist_refetch: true }
        }
        case DISABLE_EVENTTYPE_DISMISS: {
            return { ...state, disable_eventtype: null }
        }
        case SET_EVENTTYPE_ROLES: {
            return { ...state, eventtype_roles: action.payload }
        }
        default:
            return state;
    }
};
