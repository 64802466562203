export const FETCH_ASNFILES = "FETCH_ASNFILES";
export const FETCH_ASNFILES_DISMISS = "FETCH_ASNFILES_DISMISS";
export const FETCH_ASNFILES_INIT = "FETCH_ASNFILES_INIT";
export const SET_VALIDATIONREQUIRED_DATA = "SET_VALIDATIONREQUIRED_DATA";
export const SET_ASNFILES_LIST_PAGE = "SET_ASNFILES_LIST_PAGE";
export const VIEW_VALIDATION_FILE_DETAILS = "VIEW_VALIDATION_FILE_DETAILS";
export const HIDE_VALIDATION_FILE_DETAILS = "HIDE_VALIDATION_FILE_DETAILS";
export const HIDE_VALIDATION_FILE_REJECT = "HIDE_VALIDATION_FILE_REJECT";
export const REJECT_VALIDATION = "REJECT_VALIDATION";
export const OPEN_REJECT_VALIDATION_POPUP = "OPEN_REJECT_VALIDATION_POPUP";
export const REJECT_VALIDATION_COMPLETE = "REJECT_VALIDATION_COMPLETE";
export const ASN_APPROVE_COMPLETE = "ASN_APPROVE_COMPLETE";
export const FETCH_ASNFILE_RECORDS_INIT = "FETCH_ASNFILE_RECORDS_INIT";
export const FETCH_ASNFILE_RECORDS_COMPLETE = "FETCH_ASNFILE_RECORDS_COMPLETE";
export const FETCH_ASNFILE_RECORDS_FAILED = "FETCH_ASNFILE_RECORDS_FAILED";
export const SET_RECORD_LIST_PAGE = "SET_RECORD_LIST_PAGE";
export const FILE_DOWNLOADING = "FILE_DOWNLOADING";
export const FETCH_BULKFILE_RECORDS_COMPLETE = "FETCH_BULKFILE_RECORDS_COMPLETE";
export const FETCH_REPAT_UPDATE_RECORDS = "FETCH_REPAT_UPDATE_RECORDS";
export const REJECT_ASSET_VALIDATION_SUCCESS = "REJECT_ASSET_VALIDATION_SUCCESS";
export const ACCEPT_ASSET_VALIDATION_SUCCESS = "ACCEPT_ASSET_VALIDATION_SUCCESS";
