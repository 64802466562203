export const SET_PROPERTYFUELCATEGORY_DATA = "SET_PROPERTYFUELCATEGORY_DATA";
export const PROPERTYFUELCATEGORY_FETCHING_INIT = "PROPERTYFUELCATEGORY_FETCHING_INIT"
export const PROPERTYFUELCATEGORY_FETCHING_COMPLETE = "PROPERTYFUELCATEGORY_FETCHING_COMPLETE"
export const PROPERTYFUELCATEGORY_FETCHING_DISMISS = "PROPERTYFUELCATEGORY_FETCHING_DISMISS"
export const ADD_NEW_PROPERTYFUELCATEGORY = "ADD_NEW_PROPERTYFUELCATEGORY"
export const ADD_NEW_PROPERTYFUELCATEGORY_PREVIEW = "ADD_NEW_PROPERTYFUELCATEGORY_PREVIEW"
export const ADD_NEW_PROPERTYFUELCATEGORY_DISMISS = "ADD_NEW_PROPERTYFUELCATEGORY_DISMISS"
export const EDIT_PROPERTYFUELCATEGORY_PREVIEW = "EDIT_PROPERTYFUELCATEGORY_PREVIEW"
export const EDIT_PROPERTYFUELCATEGORY = "EDIT_PROPERTYFUELCATEGORY"
export const EDIT_PROPERTYFUELCATEGORY_DISMISS = "EDIT_PROPERTYFUELCATEGORY_DISMISS"
export const DELETE_PROPERTYFUELCATEGORY_PREVIEW = "DELETE_PROPERTYFUELCATEGORY_PREVIEW"
export const DELETE_PROPERTYFUELCATEGORY_DISMISS = "DELETE_PROPERTYFUELCATEGORY_DISMISS"
export const DELETE_PROPERTYFUELCATEGORY = "DELETE_PROPERTYFUELCATEGORY"
export const DISABLE_PROPERTYFUELCATEGORY_PREVIEW = "DISABLE_PROPERTYFUELCATEGORY_PREVIEW"
export const DISABLE_PROPERTYFUELCATEGORY_DISMISS = "DISABLE_PROPERTYFUELCATEGORY_DISMISS"
export const DISABLE_PROPERTYFUELCATEGORY = "DISABLE_PROPERTYFUELCATEGORY"
export const SET_PROPERTYFUELCATEGORY_ROLES = "SET_PROPERTYFUELCATEGORY_ROLES"
export const SET_PROPERTYFUELCATEGORY_PAGE = "SET_PROPERTYFUELCATEGORY_PAGE"
