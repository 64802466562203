import {
    CREATE_NEW_CONTAINER,
    SET_CONTAINERS_DATA,
    DETAILS_FETCHING_COMPLETE,
    CONTAINERS_FETCHING_COMPLETE,
    SET_CONTAINERS_PAGE,
    CONFIRM_CREATE_CONTAINER,
    PALLETS_OPERATORS_FETCHING_COMPLETE,
    FETCH_ASSET_CONTAINER_FILE_DETAILS,
    PALLET_MAIL_SENT_COMPLETE,
    SET_PRINT_PAGE_DATA,
    DELETE_SNAPSHOT_OBJ_SUCCESS,
    SET_PALLET_ARRIVAL_DATE,
    CONTAINER_UPDATE_SUCCESS,
    DELETE_CONTAINER,
    CLOSE_CREATE_CONTAINER_POPUP,
    CONTAINER_LOCATION_LIST_FETCH_COMPLETE,
    CONTAINER_DETAILS_FETCH_COMPLETE,
    SET_CONTAINER_INSPECTION_STAGE,
    IMAGE_FETCHING_INIT,
    IMAGE_FETCHING_DISMISS
} from "./types";

const initialState = {
    containers_list: [],//holds containers list array
    containerslist_fetching: false,//indicaction for fetch in progress
    containerslist_refetch: false,//indication to refetch current containers list array
    container_details_refetch: false,//indication to refetch current containers details
    containerslist_refetch_force: false,//indication to force refetch containers list array from beginning
    search: "",//search keyword on containers listing
    filter_asset_owner: null, // stores id of filter by customer
    filter_asset_type: null, // stores id of filter by asset
    filter_manufacturer:null, // store id of filter by manufacture
    filter_status_triage_container: null, // stores flag of status for the container
    page: 1,//page number on containers listing
    perpage: 10,//perpage limit on containers listing
    total_containers: 0,//total count
    data_fetching:false,
    create_containers_popup: false, // toggle create containers popup
    container_snapshot_popup: false, // toggle snapshot view popup
    view_container_detail_component: false, // toggle component container detail view
    container_details: null,
    container_id: null,
    asset_file: null,
    container_type: null,
    container_code: "",
    asset_owner: null,
    asset_type: null,
    inspection_stage: null,
    energy_type: null,
    job_id: null,
    manufacturer: null,
    arrival_pallet_quantity: null,
    snapshot_data: {},
    asset_owners_list: [],
    asset_types_list: [],
    jobs_list: [],
    electric_inspection_stages_list: [],
    gas_inspection_stages_list: [],
    energy_types_list: [],
    manufacturer_list:[],
    container_types_list: [],
    operator_list: [],
    pallet_list: [],
    view_container_asset_component: false,
    asset_file_detail_list: [],
    asset_file_page: 1,
    asset_file_perpage: 10,
    asset_file_detail_list_count: null,
    asset_file_details_refetch: false,
    pallet_email_send: false,
    print_data: {},
    update_btn_disable: false,
    btn_grp_disable: false,
    confirm_delete_popup: false,
    delete_container_id: null,
    container_inspection_status: 1,
    container_inspection_status_flag: null,
    eDispatch_date: "",
    eDispatch_date_flag: false,
    eDispatch_location: "",
    eDispatch_location_flag: false,
    container_location_list: [],
    failed_container_flag: true,
    inspection_stage_disable: false,
    loading_flag:false, // view container loading screen
    asset_id:"",
    image_modal:false,
    image_refetch:false,
    image_data_fetching: false,
    image_page:1,
    image_perpage:24,
    total_image_list:0,
    image_list:[],
    showDetails:[],
    showPopup:false,
    Excel_popup_details:[],
    Excel_icon_Popup:false,
    Csv_popup_details:[],
    Csv_icon_Popup:false

};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case DELETE_CONTAINER:
            return { ...state, ...action.payload }
        case SET_CONTAINER_INSPECTION_STAGE:
            return { ...state, ...action.payload }
        case CONTAINER_DETAILS_FETCH_COMPLETE:
            return { ...state, ...action.payload }
        case CONTAINER_LOCATION_LIST_FETCH_COMPLETE:
            return { ...state, ...action.payload }
        case CLOSE_CREATE_CONTAINER_POPUP:
            return { ...state, ...action.payload }
        case CONTAINER_UPDATE_SUCCESS:
            return { ...state, ...action.payload }
        case SET_PALLET_ARRIVAL_DATE:
            return { ...state, ...action.payload }
        case DELETE_SNAPSHOT_OBJ_SUCCESS:
            return { ...state, ...action.payload }
        case SET_PRINT_PAGE_DATA:
            return { ...state, ...action.payload }
        case FETCH_ASSET_CONTAINER_FILE_DETAILS:
            return { ...state, ...action.payload };
        case PALLETS_OPERATORS_FETCHING_COMPLETE:
            return { ...state, ...action.payload };
        case CREATE_NEW_CONTAINER:
            return { ...state, ...action.payload };
        case SET_CONTAINERS_DATA:
            return { ...state, ...action.payload };
        case DETAILS_FETCHING_COMPLETE:
            return { ...state, ...action.payload };
        case CONTAINERS_FETCHING_COMPLETE:
            return { ...state, ...action.payload };
        case SET_CONTAINERS_PAGE:
            return { ...state, ...action.payload };
        case CONFIRM_CREATE_CONTAINER:
            return { ...state, ...action.payload };
        case PALLET_MAIL_SENT_COMPLETE:
            return { ...state, ...action.payload };
            case IMAGE_FETCHING_INIT:
                return { ...state, ...action.payload, image_data_fetching: true };
            case IMAGE_FETCHING_DISMISS:
                return { ...state, ...action.payload, image_data_fetching: false };
        default:
            return state;
    }
};
