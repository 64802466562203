
export const SET_COMPANY_REQUEST_DATA = "SET_COMPANY_REQUEST_DATA"
export const CHANGE_PAGE_NO = "CHANGE_PAGE_NO"
export const FETCH_COMPANY_REQUEST = "FETCH_COMPANY_REQUEST"
export const MARK_REQUEST_DONE = "MARK_REQUEST_DONE"
export const ADD_NEW_COMPANY_REQUEST = "ADD_NEW_COMPANY_REQUEST"
export const SET_EDIT_DATA = "SET_EDIT_DATA"
export const EDIT_COMPANY_REQUEST = "EDIT_COMPANY_REQUEST"
export const DELETE_COMPANY_REQUEST_POPUP = "DELETE_COMPANY_REQUEST_POPUP"
export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST"
