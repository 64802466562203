export const SET_JOBS_DATA = "SET_JOBS_DATA"
export const FETCH_JOBS = "FETCH_JOBS"
export const CREATE_NEW_JOB = "CREATE_NEW_JOB"
export const DETAILS_FETCHING_COMPLETE = "DETAILS_FETCHING_COMPLETE"
export const JOBS_FETCHING_COMPLETE = "JOBS_FETCHING_COMPLETE"
export const SET_JOBS_PAGE = "SET_JOBS_PAGE"
export const CONFIRM_CREATE_JOB = "CONFIRM_CREATE_JOB"
export const PALLETS_OPERATORS_FETCHING_COMPLETE = "PALLETS_OPERATORS_FETCHING_COMPLETE"
export const FETCH_ASSET_FILE_DETAILS = "FETCH_ASSET_FILE_DETAILS"
export const PALLET_MAIL_SENT_COMPLETE = "PALLET_MAIL_SENT_COMPLETE"
export const SET_PRINT_PAGE_DATA = "SET_PRINT_PAGE_DATA"
export const DELETE_SNAPSHOT_OBJ_SUCCESS = "DELETE_SNAPSHOT_OBJ_SUCCESS"
export const SET_PALLET_ARRIVAL_DATE = "SET_PALLET_ARRIVAL_DATE"
export const JOB_UPDATE_SUCCESS = "JOB_UPDATE_SUCCESS"
export const SET_STATE_VALUES = "SET_STATE_VALUES"
export const DELETE_JOB_DISMISS = "DELETE_JOB_DISMISS"
export const DELETE_JOB = "DELETE_JOB"
export const DELETE_JOB_PREVIEW = "DELETE_JOB_PREVIEW"
export const GROUPED_CONTAINERS_FETCHING_COMPLETE = "GROUPED_CONTAINERS_FETCHING_COMPLETE"
export const CREATE_NEW_TRIAGE_PROD_JOB = "CREATE_NEW_TRIAGE_PROD_JOB"
export const FETCH_IMAGE = "FETCH_IMAGE"
export const SET_IMAGE_DATA = "SET_IMAGE_DATA"
export const ADD_NEW_POPUP_PREVIEW = "ADD_NEW_POPUP_PREVIEW"
