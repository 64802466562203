import {
    ADD_NEW_SUPPLIERCODE, DELETE_SUPPLIERCODE, DELETE_SUPPLIERCODE_DISMISS, DELETE_SUPPLIERCODE_PREVIEW,
    DISABLE_SUPPLIERCODE, DISABLE_SUPPLIERCODE_DISMISS, DISABLE_SUPPLIERCODE_PREVIEW,
    EDIT_SUPPLIERCODE, EDIT_SUPPLIERCODE_DISMISS, EDIT_SUPPLIERCODE_PREVIEW, SET_SUPPLIERCODE_DATA,
    SUPPLIERCODE_FETCHING_COMPLETE, SUPPLIERCODE_FETCHING_INIT, SUPPLIERCODE_FETCHING_DISMISS, SET_SUPPLIERCODE_PAGE,
    ADD_NEW_SUPPLIERCODE_DISMISS, ADD_NEW_SUPPLIERCODE_PREVIEW, SET_SUPPLIERCODE_ROLES
} from "./types";

const initialState = {
    suppliercode_list: [],//holds supplier code list array
    suppliercodelist_fetching: false,//indicaction for fetch in progress
    suppliercodelist_refetch: false,//indication to refetch current supplier code list array
    suppliercodelist_refetch_force: false,//indication to force refetch supplier code list array from beginning 
    edit_suppliercode: null,//holds initial data of supplier code being edited
    delete_suppliercode: null,//holds initial data of supplier code opted to be deleted
    disable_suppliercode: null,//holds initial data of supplier code opted to be disabled,
    search: "",//search keyword on supplier code listing
    page: 1,//page number on supplier code listing
    perpage: 10,//perpage limit on supplier code listing
    total_suppliercodes: 0,//total count
    //new supplier code params
    supl_code: "",
    supl_id: "",
    energy_type_id: "",
    //edit supplier code params
    eSupplier_code: "",
    eSupl_id: "",
    eEnergy_type_id: "",
    eSupl_name: "",
    //for add supplier code preview
    Supl_id: "",
    Energy_type_id: "",
    add_suppliercode: null,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_SUPPLIERCODE_DATA:
            return { ...state, ...action.payload };
        case SUPPLIERCODE_FETCHING_INIT: {
            return { ...state, suppliercodelist_fetching: true, suppliercodelist_refetch: false, suppliercodelist_refetch_force: false };
        }
        case SUPPLIERCODE_FETCHING_COMPLETE: {
            return { ...state, suppliercodelist_fetching: false, ...action.payload };
        }
        case SUPPLIERCODE_FETCHING_DISMISS: {
            return { ...state, suppliercodelist_fetching: false };
        }
        case SET_SUPPLIERCODE_PAGE: {
            return { ...state, suppliercodelist_refetch: true, page: action.payload };
        }
        case ADD_NEW_SUPPLIERCODE: {
            return { ...state, suppliercodelist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_SUPPLIERCODE_PREVIEW: {
            return { ...state, new_suppliercode_popup: true, supl_code: "", supl_id: null, energy_type_id: null, ...action.payload }
        }
        case ADD_NEW_SUPPLIERCODE_DISMISS: {
            return { ...state, add_suppliercode: null, new_suppliercode_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_SUPPLIERCODE_PREVIEW: {
            return { ...state, eSupplier_code_error: "", ...action.payload }
        }
        case EDIT_SUPPLIERCODE: {
            return { ...state, suppliercodelist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_SUPPLIERCODE_DISMISS: {
            return { ...state, edit_suppliercode: null }
        }
        case DELETE_SUPPLIERCODE_PREVIEW: {
            return { ...state, delete_suppliercode: action.payload }
        }
        case DELETE_SUPPLIERCODE: {
            return { ...state, suppliercodelist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_SUPPLIERCODE_DISMISS: {
            return { ...state, delete_suppliercode: null }
        }
        case DISABLE_SUPPLIERCODE_PREVIEW: {
            return { ...state, disable_suppliercode: action.payload }
        }
        case DISABLE_SUPPLIERCODE: {
            return { ...state, disable_suppliercode: null, suppliercodelist_refetch: true }
        }
        case DISABLE_SUPPLIERCODE_DISMISS: {
            return { ...state, disable_suppliercode: null }
        }
        case SET_SUPPLIERCODE_ROLES: {
            return { ...state, suppliercode_roles: action.payload }
        }
        default:
            return state;
    }
};
