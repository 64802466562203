import {
    ADD_NEW_ASSETTYPE, DELETE_ASSETTYPE, DELETE_ASSETTYPE_DISMISS, DELETE_ASSETTYPE_PREVIEW,
    DISABLE_ASSETTYPE, DISABLE_ASSETTYPE_DISMISS, DISABLE_ASSETTYPE_PREVIEW,
    EDIT_ASSETTYPE, EDIT_ASSETTYPE_DISMISS, EDIT_ASSETTYPE_PREVIEW, SET_ASSETTYPE_DATA,
    ASSETTYPE_FETCHING_COMPLETE, ASSETTYPE_FETCHING_INIT, ASSETTYPE_FETCHING_DISMISS, SET_ASSETTYPE_PAGE,
    ADD_NEW_ASSETTYPE_DISMISS, ADD_NEW_ASSETTYPE_PREVIEW, SET_ASSETTYPE_ROLES
} from "./types";

const initialState = {
    assettype_list: [],//holds asset type list array
    assettypelist_fetching: false,//indicaction for fetch in progress
    assettypelist_refetch: false,//indication to refetch current asset type list array
    assettypelist_refetch_force: false,//indication to force refetch asset type list array from beginning 
    edit_assettype: null,//holds initial data of asset type being edited
    delete_assettype: null,//holds initial data of asset type opted to be deleted
    disable_assettype: null,//holds initial data of asset type opted to be disabled,
    search: "",//search keyword on asset type listing
    page: 1,//page number on asset type listing
    perpage: 10,//perpage limit on asset type listing
    total_assettypes: 0,//total count
    //new asset type params
    asset_type_desc: "",
    //edit asset type params
    eAsset_type_desc: ""
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_ASSETTYPE_DATA:
            return { ...state, ...action.payload };
        case ASSETTYPE_FETCHING_INIT: {
            return { ...state, assettypelist_fetching: true, assettypelist_refetch: false, assettypelist_refetch_force: false };
        }
        case ASSETTYPE_FETCHING_COMPLETE: {
            return { ...state, assettypelist_fetching: false, ...action.payload };
        }
        case ASSETTYPE_FETCHING_DISMISS: {
            return { ...state, assettypelist_fetching: false };
        }
        case SET_ASSETTYPE_PAGE: {
            return { ...state, assettypelist_refetch: true, page: action.payload };
        }
        case ADD_NEW_ASSETTYPE: {
            return { ...state, assettypelist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_ASSETTYPE_PREVIEW: {
            return { ...state, new_assettype_popup: true, asset_type_desc: "" }
        }
        case ADD_NEW_ASSETTYPE_DISMISS: {
            return { ...state, new_assettype_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_ASSETTYPE_PREVIEW: {
            return { ...state, eAsset_error: "", ...action.payload }
        }
        case EDIT_ASSETTYPE: {
            return { ...state, assettypelist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_ASSETTYPE_DISMISS: {
            return { ...state, edit_assettype: null }
        }
        case DELETE_ASSETTYPE_PREVIEW: {
            return { ...state, delete_assettype: action.payload }
        }
        case DELETE_ASSETTYPE: {
            return { ...state, assettypelist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_ASSETTYPE_DISMISS: {
            return { ...state, delete_assettype: null }
        }
        case DISABLE_ASSETTYPE_PREVIEW: {
            return { ...state, disable_assettype: action.payload }
        }
        case DISABLE_ASSETTYPE: {
            return { ...state, disable_assettype: null, assettypelist_refetch: true }
        }
        case DISABLE_ASSETTYPE_DISMISS: {
            return { ...state, disable_assettype: null }
        }
        case SET_ASSETTYPE_ROLES: {
            return { ...state, assettype_roles: action.payload }
        }
        default:
            return state;
    }
};
