import {
    ADD_NEW_STATUSTYPE, DELETE_STATUSTYPE, DELETE_STATUSTYPE_DISMISS, DELETE_STATUSTYPE_PREVIEW,
    DISABLE_STATUSTYPE, DISABLE_STATUSTYPE_DISMISS, DISABLE_STATUSTYPE_PREVIEW,
    EDIT_STATUSTYPE, EDIT_STATUSTYPE_DISMISS, EDIT_STATUSTYPE_PREVIEW, SET_STATUSTYPE_DATA,
    STATUSTYPE_FETCHING_COMPLETE, STATUSTYPE_FETCHING_INIT, STATUSTYPE_FETCHING_DISMISS, SET_STATUSTYPE_PAGE,
    ADD_NEW_STATUSTYPE_DISMISS, ADD_NEW_STATUSTYPE_PREVIEW, SET_STATUSTYPE_ROLES
} from "./types";

const initialState = {
    statustype_list: [],//holds status type list array
    statustypelist_fetching: false,//indicaction for fetch in progress
    statustypelist_refetch: false,//indication to refetch current status type list array
    statustypelist_refetch_force: false,//indication to force refetch status type list array from beginning 
    edit_statustype: null,//holds initial data of status type being edited
    delete_statustype: null,//holds initial data of status type opted to be deleted
    disable_statustype: null,//holds initial data of status type opted to be disabled,
    search: "",//search keyword on status type listing
    page: 1,//page number on status type listing
    perpage: 10,//perpage limit on status type listing
    total_statustypes: 0,//total count
    //new status type params
    status_type_desc: "",
    //edit status type params
    eStatus_type_desc: ""
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_STATUSTYPE_DATA:
            return { ...state, ...action.payload };
        case STATUSTYPE_FETCHING_INIT: {
            return { ...state, statustypelist_fetching: true, statustypelist_refetch: false, statustypelist_refetch_force: false };
        }
        case STATUSTYPE_FETCHING_COMPLETE: {
            return { ...state, statustypelist_fetching: false, ...action.payload };
        }
        case STATUSTYPE_FETCHING_DISMISS: {
            return { ...state, statustypelist_fetching: false };
        }
        case SET_STATUSTYPE_PAGE: {
            return { ...state, statustypelist_refetch: true, page: action.payload };
        }
        case ADD_NEW_STATUSTYPE: {
            return { ...state, statustypelist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_STATUSTYPE_PREVIEW: {
            return { ...state, new_statustype_popup: true, status_type_desc: "" }
        }
        case ADD_NEW_STATUSTYPE_DISMISS: {
            return { ...state, new_statustype_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_STATUSTYPE_PREVIEW: {
            return { ...state, eStatus_error: "", ...action.payload }
        }
        case EDIT_STATUSTYPE: {
            return { ...state, statustypelist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_STATUSTYPE_DISMISS: {
            return { ...state, edit_statustype: null }
        }
        case DELETE_STATUSTYPE_PREVIEW: {
            return { ...state, delete_statustype: action.payload }
        }
        case DELETE_STATUSTYPE: {
            return { ...state, statustypelist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_STATUSTYPE_DISMISS: {
            return { ...state, delete_statustype: null }
        }
        case DISABLE_STATUSTYPE_PREVIEW: {
            return { ...state, disable_statustype: action.payload }
        }
        case DISABLE_STATUSTYPE: {
            return { ...state, disable_statustype: null, statustypelist_refetch: true }
        }
        case DISABLE_STATUSTYPE_DISMISS: {
            return { ...state, disable_statustype: null }
        }
        case SET_STATUSTYPE_ROLES: {
            return { ...state, statustype_roles: action.payload }
        }
        default:
            return state;
    }
};
