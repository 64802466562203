import React from "react"

function Loading(props) {
    return (
        <div style={{ width: "30%", margin: "100px auto" }}>
            <span style={{ textAlign: "center" }}><div><img src="/images/loading.gif" alt="loading" style={{ maxWidth: 200 }} /></div><div style={{fontSize:18,fontWeight:500}}>Loading...</div></span>
        </div>
    )
}

export default Loading