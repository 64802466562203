export const SET_MANUAL_UPLOAD_DATA = "SET_MANUAL_UPLOAD_DATA";
export const OPEN_NEW_TRIAGE_UPLOAD_POPUP = "OPEN_NEW_TRIAGE_UPLOAD_POPUP";
export const CLOSE_NEW_UPLOAD_POPUP = "CLOSE_NEW_UPLOAD_POPUP";
export const CLOSE_ERR_FILE_DOWNLOAD_POPUP = "CLOSE_ERR_FILE_DOWNLOAD_POPUP";
export const CLOSE_POP_UP ="CLOSE_POP_UP";
export const OPEN_NEW_PROCESS_FAILED = "OPEN_NEW_PROCESS_FAILED";
export const ACCEPT_TRIAGE_VALIDATION_SUCCESS="ACCEPT_TRIAGE_VALIDATION_SUCCESS";
export const HIDE_TRIAGE_VALIDATION_FILE_REJECT="HIDE_TRIAGE_VALIDATION_FILE_REJECT";
export const TRIAGE_REJECT_VALIDATION_COMPLETE="TRIAGE_REJECT_VALIDATION_COMPLETE";
export const DATA_SUBMIT_INIT="DATA_SUBMIT_INIT";
export const DATA_SUBMIT_DISMISS="DATA_SUBMIT_DISMISS";
export const UPLOAD_TRIAGE_FILE_COMPLETE="UPLOAD_TRIAGE_FILE_COMPLETE"
export const DATA_FETCHING_INIT = "DATA_FETCHING_INIT";
export const DATA_FETCHING_DISMISS = "DATA_FETCHING_DISMISS";
export const FETCH_TRIAGE_MANUAL_UPLOAD_FILE_LIST_COMPLETE = "FETCH_TRIAGE_MANUAL_UPLOAD_FILE_LIST_COMPLETE";
export const SET_FILTER_STATUS = "SET_FILTER_STATUS";
export const OPEN_ERR_FILE_DOWNLOAD_POPUP = "OPEN_ERR_FILE_DOWNLOAD_POPUP";
export const TRIAGE_VIEW_FETCHING_COMPLETE = "TRIAGE_VIEW_FETCHING_COMPLETE";
export const TRIAGE_ACCEPT_REJECT_INIT = "TRIAGE_ACCEPT_REJECT_INIT";
export const TRIAGE_ACCEPT_REJECT_COMPLETE = "TRIAGE_ACCEPT_REJECT_COMPLETE";
export const TRIAGE_ACCEPT_REJECT_DISMISS = "TRIAGE_ACCEPT_REJECT_DISMISS";
const manualUploadState = {
    triage_upload_popup:false,
    mpl_files:[],
    clr_files:[],
    add_btn_disable: false,
    search: "",//search keyword on installation file name listing
    page: 1,//page number on file list
    perpage: 10,//perpage limit on file list
    manual_upload_download_data:{},
    details_view: 0,
    data_fetching:false, // for loading
    triage_company:1, // for selecting report types in tabs
    report_file:[],
    triage_files:[],
        disable_mpl_btn:false,
    disable_clr_btn:false,
    open_popup:false,
    download_popup:false,
    triage_list_refetch:false,
    view_confirm_btn_disable : false,
    view_approve_popup:false,
    view_reject_popup : false,
    Triage_filter_list:[],
    filter_status_triage_manuupload:"",
    manual_upload_list:[],
    manual_upload_list_refetch: false,
    view_page: 1,
    view_perpage: 10,
    search_number: "",
    search_job_number: "",
    view_file_traige_list: [],
    triage_view_refetch:false,
    selected_triage_company:"",
    triage_view_details:[],
    triage_file_title:'',
    fileType:0, // 1--> Accepted 2-->Rejected 3-->InProgress Records
    tri_approve_reject_btn_disabled:false,
}  

export default (state = manualUploadState, action = {}) => {

    switch (action.type) {
        case SET_MANUAL_UPLOAD_DATA:
            return {...state, ...action.payload };
        case OPEN_NEW_TRIAGE_UPLOAD_POPUP:
            return {...state, ...action.payload };
        case CLOSE_NEW_UPLOAD_POPUP:
            return { ...state, ...action.payload };
        case CLOSE_ERR_FILE_DOWNLOAD_POPUP:
            return { ...state, ...action.payload };
        case CLOSE_POP_UP:
            return { ...state,...action.payload };
        case OPEN_NEW_PROCESS_FAILED:
            return { ...state,...action.payload };
            case ACCEPT_TRIAGE_VALIDATION_SUCCESS:
                return { ...state, ...action.payload };
            case HIDE_TRIAGE_VALIDATION_FILE_REJECT:
                return { ...state, view_supplier_reject_popup: false, details_view: 1, }
            case TRIAGE_REJECT_VALIDATION_COMPLETE:
                return { ...state, ...action.payload, view_supplier_reject_popup: false, details_view: 0,  }
                case DATA_SUBMIT_INIT:
                    return { ...state, ...action.payload, add_btn_disable:true };
                    case DATA_SUBMIT_DISMISS:
                        return { ...state, ...action.payload, add_btn_disable: false};
                        case UPLOAD_TRIAGE_FILE_COMPLETE:
                            return {...state,...action.payload};
        case SET_FILTER_STATUS:
            return { ...state, ...action.payload };
        case OPEN_ERR_FILE_DOWNLOAD_POPUP:
            return { ...state, ...action.payload };
        case FETCH_TRIAGE_MANUAL_UPLOAD_FILE_LIST_COMPLETE: 
        return { ...state, ...action.payload}
        case DATA_FETCHING_INIT:
            return { ...state, ...action.payload, data_fetching: true, };
        case DATA_FETCHING_DISMISS:
            return { ...state, ...action.payload, manual_upload_list_refetch: false,triage_view_refetch:false,data_fetching:false};
        case TRIAGE_VIEW_FETCHING_COMPLETE:
            return { ...state, ...action.payload, data_fetching: false,triage_view_refetch:false};
        case TRIAGE_ACCEPT_REJECT_INIT:
            return { ...state, ...action.payload,tri_approve_reject_btn_disabled:true};
        case TRIAGE_ACCEPT_REJECT_COMPLETE:
            return { ...state, ...action.payload,tri_approve_reject_btn_disabled:false,triage_view_refetch:true};
        case TRIAGE_ACCEPT_REJECT_DISMISS:
            return { ...state, ...action.payload,tri_approve_reject_btn_disabled:false};
                   
            default:
            return state;
    }
}