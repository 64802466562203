export const SET_REASONS_DATA = "SET_REASONS_DATA"
export const FETCH_REASONS = "FETCH_REASONS"
export const CREATE_NEW_REASON = "CREATE_NEW_REASON"
export const DETAILS_FETCHING_COMPLETE = "DETAILS_FETCHING_COMPLETE"
export const REASONS_FETCHING_COMPLETE = "REASONS_FETCHING_COMPLETE"
export const SET_REASONS_PAGE = "SET_REASONS_PAGE"
export const CONFIRM_CREATE_REASONS = "CONFIRM_CREATE_REASONS"
export const PALLETS_OPERATORS_FETCHING_COMPLETE = "PALLETS_OPERATORS_FETCHING_COMPLETE"
export const FETCH_ASSET_FILE_DETAILS = "FETCH_ASSET_FILE_DETAILS"
export const PALLET_MAIL_SENT_COMPLETE = "PALLET_MAIL_SENT_COMPLETE"
export const SET_PRINT_PAGE_DATA = "SET_PRINT_PAGE_DATA"
export const DELETE_SNAPSHOT_OBJ_SUCCESS = "DELETE_SNAPSHOT_OBJ_SUCCESS"
export const SET_PALLET_ARRIVAL_DATE = "SET_PALLET_ARRIVAL_DATE"
export const REASONS_UPDATE_SUCCESS = "REASONS_UPDATE_SUCCESS"
export const SET_STATE_VALUES = "SET_STATE_VALUES"
export const DELETE_REASONS_DISMISS = "DELETE_REASONS_DISMISS"
export const DELETE_REASONS = "DELETE_REASONS"
export const DELETE_REASONS_PREVIEW = "DELETE_REASONS_PREVIEW"
export const CREATE_NEW_TRIAGE_PROD_REASONS = "CREATE_NEW_TRIAGE_PROD_REASONS"
