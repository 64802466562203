import {
    ADD_NEW_CONTRACTTYPE, DELETE_CONTRACTTYPE, DELETE_CONTRACTTYPE_DISMISS, DELETE_CONTRACTTYPE_PREVIEW,
    DISABLE_CONTRACTTYPE, DISABLE_CONTRACTTYPE_DISMISS, DISABLE_CONTRACTTYPE_PREVIEW,
    EDIT_CONTRACTTYPE, EDIT_CONTRACTTYPE_DISMISS, EDIT_CONTRACTTYPE_PREVIEW, SET_CONTRACTTYPE_DATA,
    CONTRACTTYPE_FETCHING_COMPLETE, CONTRACTTYPE_FETCHING_INIT, CONTRACTTYPE_FETCHING_DISMISS, SET_CONTRACTTYPE_PAGE,
    ADD_NEW_CONTRACTTYPE_DISMISS, ADD_NEW_CONTRACTTYPE_PREVIEW, SET_CONTRACTTYPE_ROLES
} from "./types";

const initialState = {
    contracttype_list: [],//holds contract type list array
    contracttypelist_fetching: false,//indicaction for fetch in progress
    contracttypelist_refetch: false,//indication to refetch current contract type list array
    contracttypelist_refetch_force: false,//indication to force refetch contract type list array from beginning 
    edit_contracttype: null,//holds initial data of contract type being edited
    delete_contracttype: null,//holds initial data of contract type opted to be deleted
    disable_contracttype: null,//holds initial data of contract type opted to be disabled,
    search: "",//search keyword on contract type listing
    page: 1,//page number on contract type listing
    perpage: 10,//perpage limit on contract type listing
    total_contracttypes: 0,//total count
    //new contract type params
    contract_type_desc: "",
    //edit contract type params
    eContract_type_desc: ""
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_CONTRACTTYPE_DATA:
            return { ...state, ...action.payload };
        case CONTRACTTYPE_FETCHING_INIT: {
            return { ...state, contracttypelist_fetching: true, contracttypelist_refetch: false, contracttypelist_refetch_force: false };
        }
        case CONTRACTTYPE_FETCHING_COMPLETE: {
            return { ...state, contracttypelist_fetching: false, ...action.payload };
        }
        case CONTRACTTYPE_FETCHING_DISMISS: {
            return { ...state, contracttypelist_fetching: false };
        }
        case SET_CONTRACTTYPE_PAGE: {
            return { ...state, contracttypelist_refetch: true, page: action.payload };
        }
        case ADD_NEW_CONTRACTTYPE: {
            return { ...state, contracttypelist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_CONTRACTTYPE_PREVIEW: {
            return { ...state, new_contracttype_popup: true, contract_type_desc: "" }
        }
        case ADD_NEW_CONTRACTTYPE_DISMISS: {
            return { ...state, new_contracttype_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_CONTRACTTYPE_PREVIEW: {
            return { ...state, eContract_error: "", ...action.payload }
        }
        case EDIT_CONTRACTTYPE: {
            return { ...state, contracttypelist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_CONTRACTTYPE_DISMISS: {
            return { ...state, edit_contracttype: null }
        }
        case DELETE_CONTRACTTYPE_PREVIEW: {
            return { ...state, delete_contracttype: action.payload }
        }
        case DELETE_CONTRACTTYPE: {
            return { ...state, contracttypelist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_CONTRACTTYPE_DISMISS: {
            return { ...state, delete_contracttype: null }
        }
        case DISABLE_CONTRACTTYPE_PREVIEW: {
            return { ...state, disable_contracttype: action.payload }
        }
        case DISABLE_CONTRACTTYPE: {
            return { ...state, disable_contracttype: null, contracttypelist_refetch: true }
        }
        case DISABLE_CONTRACTTYPE_DISMISS: {
            return { ...state, disable_contracttype: null }
        }
        case SET_CONTRACTTYPE_ROLES: {
            return { ...state, contracttype_roles: action.payload }
        }
        default:
            return state;
    }
};
