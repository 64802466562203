export const SET_SEARCH_LOCATION_SUGGESTION = "SET_SEARCH_LOCATION_SUGGESTION";
export const INIT_LOCATION_SUGGESTION = "INIT_LOCATION_SUGGESTION";
export const SET_ASSET_HISTORY_BY_PROPERTY = "SET_ASSET_HISTORY_BY_PROPERTY";
export const SET_PROPERTY_FILE_DATA = "SET_PROPERTY_FILE_DATA";

const searchHistoryMeterPoint = {
  locationSuggestionLoading: false,
  locationSuggestionList: [],
  assetHistoryList: [],
  historyWareHouse:[],
  assetHistoryListLoading: false,
  messageFlag:true,
  messageFlagwf:true,
  from:"",
  to:"",
  isEmpty:true,
  page:1,
  perpage:10,
  total_wareHousefiles:0,
  refetch_flag:false,
  selectedLocationId:"",
  isWareHouse:false,
  export_support_flag_value:1,
  export_install_flag : false,
  download_support_flag:false,
  download_install_btn_disable:false,
  selectedLocationAddress:""
  
}

export default (state = searchHistoryMeterPoint, action = {}) => {
  switch (action.type) {
    case SET_SEARCH_LOCATION_SUGGESTION:
      return { ...state, ...action.payload };
    case INIT_LOCATION_SUGGESTION:
      return { ...state, ...action.payload };
    case SET_ASSET_HISTORY_BY_PROPERTY:
      return { ...state, ...action.payload };
    case SET_PROPERTY_FILE_DATA:
      return {...state, ...action.payload};
    default:
      return state;
  }
}