import {
    FETCH_ASNCONFIG_DATA_INIT,
    FETCH_ASNCONFIG_DATA_COMPLETE,
    FETCH_ASNCONFIG_DATA_DISMISS,
    UPDATE_ASNCONFIG,
    SET_ASN_CONFIG
} from './types'

const initialState = {
    selected_private_data:[],
    selected_public_data:[],
    privateList:[],
    publicList:[],
    data_fetching:false,
    main_asn_list:[],
    save_btn_disable:false
}

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_ASN_CONFIG:
            return { ...state, ...action.payload };
        case FETCH_ASNCONFIG_DATA_INIT:
            return { ...state,...action.payload,data_fetching:true};
        case FETCH_ASNCONFIG_DATA_DISMISS:
            return { ...state,...action.payload, data_fetching:false};
        case FETCH_ASNCONFIG_DATA_COMPLETE:
            return { ...state, ...action.payload , data_fetching:false};
        case UPDATE_ASNCONFIG:
            return { ...state,...action.payload,asnconfig_fieldnames_fetching:true,   save_btn_disable:false };
        default:
            return state;
    }
}