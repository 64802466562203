export const SET_REPORTS_FILE_DATA = "SET_REPORTS_FILE_DATA";
export const DATA_FETCHING_INIT = "DATA_FETCHING_INIT";
export const DATA_FETCHING_DISMISS = "DATA_FETCHING_DISMISS";
export const FETCH_REPORT_FILE_LIST_COMPLETE = "FETCH_REPORT_FILE_LIST_COMPLETE";
export const DATA_FETCHING_MISSINGTRIAGE_INIT = "DATA_FETCHING_MISSINGTRIAGE_INIT";
export const FETCH_REPORT_MISSINGTRIAGE_LIST_COMPLETE = "FETCH_REPORT_MISSINGTRIAGE_LIST_COMPLETE";
export const DATA_FETCHING_LATE_TO_TRIAGE_INIT ="DATA_FETCHING_LATE_TO_TRIAGE_INIT";
export const FETCH_REPORT_LATE_TO_TRIAGE_LIST_COMPLETE =" FETCH_REPORT_LATE_TO_TRIAGE_LIST_COMPLETE";
export const DATA_FETCHING_IMPLIED_EVENT_INIT ="DATA_FETCHING_IMPLIED_EVENT_INIT";
export const FETCH_REPORT_IMPLIED_EVENT_LIST_COMPLETE = "FETCH_REPORT_IMPLIED_EVENT_LIST_COMPLETE";

const reportState ={
    from:"",
    page:1, 
    perpage:10, 
    to:"",
    data_fetching:false,
    reportfilelist_refetch:true,
    removedasset_list:[],
    from_MT:"",
    page_MT:1,
    perpage_MT:10,
    to_MT:"",
    filter_days_MT:"",
    report_MTlist_refetch:true,
    missingtriage_list:[],
    from_LT:"", 
    page_LT:1, 
    perpage_LT:10, 
    to_LT:"",
    filter_days_LT:"",
    report_LTlist_refetch:true,
    latetriage_list:[],
    from_IE:"",
    page_IE:1, 
    perpage_IE:10, 
    to_IE:"",
    impliedEvent_list:[],
    report_IElist_refetch:true,
    export_support_flag_value:1,
    download_support_flag :false,
    export_install_flag : false,
    download_install_btn_disable : false,
    download_csv :"",
    download_support_flag_MT:false,
    export_support_flag_value_MT:1,
    export_install_flag_MT : false,
    download_install_btn_disable_MT:false,
    download_support_flag_LT:false,
    export_support_flag_value_LT:1,
    export_install_flag_LT : false,
    download_install_btn_disable_LT:false,
    download_support_flag_IE:false,
    export_support_flag_value_IE:1,
    export_install_flag_IE : false,
    download_install_btn_disable_IE:false,
    search:"",
    miss_triage_search:"",
    late_triage_search :"",
    implied_event_search :""

}

export default (state = reportState, action = {}) => {


    switch (action.type) {
        case SET_REPORTS_FILE_DATA:
            return { ...state, ...action.payload };
        case DATA_FETCHING_DISMISS:
            return { ...state, ...action.payload, data_fetching: false };
        case DATA_FETCHING_INIT:
            return { ...state, ...action.payload, data_fetching: true, };
        case FETCH_REPORT_FILE_LIST_COMPLETE:
            return { ...state, ...action.payload };

        case DATA_FETCHING_MISSINGTRIAGE_INIT:
            return { ...state, ...action.payload, data_fetching: true, };
        case FETCH_REPORT_MISSINGTRIAGE_LIST_COMPLETE:
            return { ...state, ...action.payload };

        case DATA_FETCHING_LATE_TO_TRIAGE_INIT:
            return { ...state, ...action.payload, data_fetching: true, };
        case FETCH_REPORT_LATE_TO_TRIAGE_LIST_COMPLETE:
            return { ...state, ...action.payload };

        case DATA_FETCHING_IMPLIED_EVENT_INIT:
            return { ...state, ...action.payload, data_fetching: true, };
        case FETCH_REPORT_IMPLIED_EVENT_LIST_COMPLETE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
