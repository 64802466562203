import {
    ADD_NEW_INVSTATUSTYPE, DELETE_INVSTATUSTYPE, DELETE_INVSTATUSTYPE_DISMISS, DELETE_INVSTATUSTYPE_PREVIEW,
    DISABLE_INVSTATUSTYPE, DISABLE_INVSTATUSTYPE_DISMISS, DISABLE_INVSTATUSTYPE_PREVIEW,
    EDIT_INVSTATUSTYPE, EDIT_INVSTATUSTYPE_DISMISS, EDIT_INVSTATUSTYPE_PREVIEW, SET_INVSTATUSTYPE_DATA,
    INVSTATUSTYPE_FETCHING_COMPLETE, INVSTATUSTYPE_FETCHING_INIT, INVSTATUSTYPE_FETCHING_DISMISS, SET_INVSTATUSTYPE_PAGE,
    ADD_NEW_INVSTATUSTYPE_DISMISS, ADD_NEW_INVSTATUSTYPE_PREVIEW, SET_INVSTATUSTYPE_ROLES
} from "./types";

const initialState = {
    invstatustype_list: [],//holds invoice status type list array
    invstatustypelist_fetching: false,//indicaction for fetch in progress
    invstatustypelist_refetch: false,//indication to refetch current invoice status type list array
    invstatustypelist_refetch_force: false,//indication to force refetch invoice status type list array from beginning 
    edit_invstatustype: null,//holds initial data of invoice status type being edited
    delete_invstatustype: null,//holds initial data of invoice status type opted to be deleted
    disable_invstatustype: null,//holds initial data of invoice status type opted to be disabled,
    search: "",//search keyword on invoice status type listing
    page: 1,//page number on invoice status type listing
    perpage: 10,//perpage limit on invoice status type listing
    total_invstatustypes: 0,//total count
    //new invoice status type params
    inv_status_type_desc: "",
    //edit invoice status type params
    eInv_status_type_desc: ""
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_INVSTATUSTYPE_DATA:
            return { ...state, ...action.payload };
        case INVSTATUSTYPE_FETCHING_INIT: {
            return { ...state, invstatustypelist_fetching: true, invstatustypelist_refetch: false, invstatustypelist_refetch_force: false };
        }
        case INVSTATUSTYPE_FETCHING_COMPLETE: {
            return { ...state, invstatustypelist_fetching: false, ...action.payload };
        }
        case INVSTATUSTYPE_FETCHING_DISMISS: {
            return { ...state, invstatustypelist_fetching: false };
        }
        case SET_INVSTATUSTYPE_PAGE: {
            return { ...state, invstatustypelist_refetch: true, page: action.payload };
        }
        case ADD_NEW_INVSTATUSTYPE: {
            return { ...state, invstatustypelist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_INVSTATUSTYPE_PREVIEW: {
            return { ...state, new_invstatustype_popup: true, inv_status_type_desc: "" }
        }
        case ADD_NEW_INVSTATUSTYPE_DISMISS: {
            return { ...state, new_invstatustype_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_INVSTATUSTYPE_PREVIEW: {
            return { ...state, eInv_status_error: "", ...action.payload }
        }
        case EDIT_INVSTATUSTYPE: {
            return { ...state, invstatustypelist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_INVSTATUSTYPE_DISMISS: {
            return { ...state, edit_invstatustype: null }
        }
        case DELETE_INVSTATUSTYPE_PREVIEW: {
            return { ...state, delete_invstatustype: action.payload }
        }
        case DELETE_INVSTATUSTYPE: {
            return { ...state, invstatustypelist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_INVSTATUSTYPE_DISMISS: {
            return { ...state, delete_invstatustype: null }
        }
        case DISABLE_INVSTATUSTYPE_PREVIEW: {
            return { ...state, disable_invstatustype: action.payload }
        }
        case DISABLE_INVSTATUSTYPE: {
            return { ...state, disable_invstatustype: null, invstatustypelist_refetch: true }
        }
        case DISABLE_INVSTATUSTYPE_DISMISS: {
            return { ...state, disable_invstatustype: null }
        }
        case SET_INVSTATUSTYPE_ROLES: {
            return { ...state, invstatustype_roles: action.payload }
        }
        default:
            return state;
    }
};
