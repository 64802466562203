export const SET_SEARCH_METERPOINT_DATA_HISTORY = "SET_SEARCH_METERPOINT_DATA_HISTORY";
export const DATA_FETCHING_HISTORY_INIT = "DATA_FETCHING_HISTORY_INIT";
export const DATA_HISTORY_FETCHING_DISMISS = "DATA_HISTORY_FETCHING_DISMISS";
export const METERPOINT_HISTORY_SEARCH_LIST_COMPLETE = "METERPOINT_HISTORY_SEARCH_LIST_COMPLETE";
export const OPEN_NEW_UPLOAD_POPUP = "OPEN_NEW_UPLOAD_POPUP";
export const CLOSE_NEW_UPLOAD_POPUP = "CLOSE_NEW_UPLOAD_POPUP";

const searchHistoryMeterPoint={
    metersearch:0,
    refresh_flag:false,
    meterPointHistory:{},
    page:1, 
    perpage:10,
    meterpointHistory_refetch:false,
    export_support_flag_value:1,
    viewImagepopup:false,
    tableFlag:false,
    messegeFlag:true,
    energySuplier:false,
    ownerShip:false,
    finance:false,
    firmware:false,
    option:[
       { key: '1', text: 'Energy Supplier'},{ key: '2', text: 'Ownership'},{ key: '3', text: 'Finance'},
        { key: '4', text: 'Firmware'}
      ],
    dissable_button:false,
    defaultValue:[],
    header:['date','event','details','locations','energy_supplier'],
    download_support_flag :false,

    meterPointHistoryViewData:{},
    meterviewSearch:"",
    meterpointHistory_view_refetch:true,
    tableFlag_view:false,
    messegeFlag_view:true,
    download_support_flag_view:false,
    export_support_flag_value_view:1,
    view:0,
    Status:0,
    download_csv:"",
    download_csv_view:"",
    count:500,
    total_meterpoint_history:"",

}

export default (state=searchHistoryMeterPoint,action={})=>{
    switch (action.type) {
        case SET_SEARCH_METERPOINT_DATA_HISTORY:
            return { ...state, ...action.payload };
        case DATA_FETCHING_HISTORY_INIT:
            return { ...state, ...action.payload, refresh_flag: true,dissable_button:true };
        case DATA_HISTORY_FETCHING_DISMISS:
            return { ...state, ...action.payload, refresh_flag: false,dissable_button:false};
        case METERPOINT_HISTORY_SEARCH_LIST_COMPLETE:
            return { ...state, ...action.payload };
        case OPEN_NEW_UPLOAD_POPUP:
            return { ...state, ...action.payload };
        case CLOSE_NEW_UPLOAD_POPUP:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}