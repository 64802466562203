import {
    ADD_NEW_POSTALCODE, DELETE_POSTALCODE, DELETE_POSTALCODE_DISMISS, DELETE_POSTALCODE_PREVIEW,
    DISABLE_POSTALCODE, DISABLE_POSTALCODE_DISMISS, DISABLE_POSTALCODE_PREVIEW,
    EDIT_POSTALCODE, EDIT_POSTALCODE_DISMISS, EDIT_POSTALCODE_PREVIEW, SET_POSTALCODE_DATA,
    POSTALCODE_FETCHING_COMPLETE, POSTALCODE_FETCHING_INIT, POSTALCODE_FETCHING_DISMISS, SET_POSTALCODE_PAGE,
    ADD_NEW_POSTALCODE_DISMISS, ADD_NEW_POSTALCODE_PREVIEW, SET_POSTALCODE_ROLES
} from "./types";

const initialState = {
    postalcode_list: [],//holds postal code list array
    postalcodelist_fetching: false,//indicaction for fetch in progress
    postalcodelist_refetch: false,//indication to refetch current postal code list array
    postalcodelist_refetch_force: false,//indication to force refetch postal code list array from beginning 
    edit_postalcode: null,//holds initial data of postal code being edited
    delete_postalcode: null,//holds initial data of postal code opted to be deleted
    disable_postalcode: null,//holds initial data of postal code opted to be disabled,
    search: "",//search keyword on postal code listing
    page: 1,//page number on postal code listing
    perpage: 10,//perpage limit on postal code listing
    total_postalcodes: 0,//total count
    //new postal code params
    hydropostalcode: "",
    //edit postal code params
    ePostalcode: ""
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_POSTALCODE_DATA:
            return { ...state, ...action.payload };
        case POSTALCODE_FETCHING_INIT: {
            return { ...state, postalcodelist_fetching: true, postalcodelist_refetch: false, postalcodelist_refetch_force: false };
        }
        case POSTALCODE_FETCHING_COMPLETE: {
            return { ...state, postalcodelist_fetching: false, ...action.payload };
        }
        case POSTALCODE_FETCHING_DISMISS: {
            return { ...state, postalcodelist_fetching: false };
        }
        case SET_POSTALCODE_PAGE: {
            return { ...state, postalcodelist_refetch: true, page: action.payload };
        }
        case ADD_NEW_POSTALCODE: {
            return { ...state, postalcodelist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_POSTALCODE_PREVIEW: {
            return { ...state, new_postalcode_popup: true, hydropostalcode: "" }
        }
        case ADD_NEW_POSTALCODE_DISMISS: {
            return { ...state, new_postalcode_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_POSTALCODE_PREVIEW: {
            return { ...state, ePostalCode_error: "", ...action.payload }
        }
        case EDIT_POSTALCODE: {
            return { ...state, postalcodelist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_POSTALCODE_DISMISS: {
            return { ...state, edit_postalcode: null }
        }
        case DELETE_POSTALCODE_PREVIEW: {
            return { ...state, delete_postalcode: action.payload }
        }
        case DELETE_POSTALCODE: {
            return { ...state, postalcodelist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_POSTALCODE_DISMISS: {
            return { ...state, delete_postalcode: null }
        }
        case DISABLE_POSTALCODE_PREVIEW: {
            return { ...state, disable_postalcode: action.payload }
        }
        case DISABLE_POSTALCODE: {
            return { ...state, disable_postalcode: null, postalcodelist_refetch: true }
        }
        case DISABLE_POSTALCODE_DISMISS: {
            return { ...state, disable_postalcode: null }
        }
        case SET_POSTALCODE_ROLES: {
            return { ...state, postalcode_roles: action.payload }
        }
        default:
            return state;
    }
};
