import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App.js";
import store from "./redux/store";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./assets/i18n" //multi-language
import "./styles/app.css";
import "./styles/overrides.scss";
import "./styles/style.scss";
import 'bootstrap/dist/css/bootstrap.min.css';

Sentry.init({
  dsn: "https://d4a019b8f46743edb9aa48734c6bcb4b@o962061.ingest.sentry.io/5910559",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});  

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
