import {
    ADD_NEW_LOCATIONTYPE, DELETE_LOCATIONTYPE, DELETE_LOCATIONTYPE_DISMISS, DELETE_LOCATIONTYPE_PREVIEW,
    DISABLE_LOCATIONTYPE, DISABLE_LOCATIONTYPE_DISMISS, DISABLE_LOCATIONTYPE_PREVIEW,
    EDIT_LOCATIONTYPE, EDIT_LOCATIONTYPE_DISMISS, EDIT_LOCATIONTYPE_PREVIEW, SET_LOCATIONTYPE_DATA,
    LOCATIONTYPE_FETCHING_COMPLETE, LOCATIONTYPE_FETCHING_INIT, LOCATIONTYPE_FETCHING_DISMISS, SET_LOCATIONTYPE_PAGE,
    ADD_NEW_LOCATIONTYPE_DISMISS, ADD_NEW_LOCATIONTYPE_PREVIEW, SET_LOCATIONTYPE_ROLES
} from "./types";

const initialState = {
    locationtype_list: [],//holds location type list array
    locationtypelist_fetching: false,//indicaction for fetch in progress
    locationtypelist_refetch: false,//indication to refetch current location type list array
    locationtypelist_refetch_force: false,//indication to force refetch location type list array from beginning 
    edit_locationtype: null,//holds initial data of location type being edited
    delete_locationtype: null,//holds initial data of location type opted to be deleted
    disable_locationtype: null,//holds initial data of location type opted to be disabled,
    search: "",//search keyword on location type listing
    page: 1,//page number on location type listing
    perpage: 10,//perpage limit on location type listing
    total_locationtypes: 0,//total count
    //new location type params
    location_type_desc: "",
    //edit location type params
    eLocation_type_desc: ""
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_LOCATIONTYPE_DATA:
            return { ...state, ...action.payload };
        case LOCATIONTYPE_FETCHING_INIT: {
            return { ...state, locationtypelist_fetching: true, locationtypelist_refetch: false, locationtypelist_refetch_force: false };
        }
        case LOCATIONTYPE_FETCHING_COMPLETE: {
            return { ...state, locationtypelist_fetching: false, ...action.payload };
        }
        case LOCATIONTYPE_FETCHING_DISMISS: {
            return { ...state, locationtypelist_fetching: false };
        }
        case SET_LOCATIONTYPE_PAGE: {
            return { ...state, locationtypelist_refetch: true, page: action.payload };
        }
        case ADD_NEW_LOCATIONTYPE: {
            return { ...state, locationtypelist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_LOCATIONTYPE_PREVIEW: {
            return { ...state, new_locationtype_popup: true, location_type_desc: "" }
        }
        case ADD_NEW_LOCATIONTYPE_DISMISS: {
            return { ...state, new_locationtype_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_LOCATIONTYPE_PREVIEW: {
            return { ...state, eLocation_error: "", ...action.payload }
        }
        case EDIT_LOCATIONTYPE: {
            return { ...state, locationtypelist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_LOCATIONTYPE_DISMISS: {
            return { ...state, edit_locationtype: null }
        }
        case DELETE_LOCATIONTYPE_PREVIEW: {
            return { ...state, delete_locationtype: action.payload }
        }
        case DELETE_LOCATIONTYPE: {
            return { ...state, locationtypelist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_LOCATIONTYPE_DISMISS: {
            return { ...state, delete_locationtype: null }
        }
        case DISABLE_LOCATIONTYPE_PREVIEW: {
            return { ...state, disable_locationtype: action.payload }
        }
        case DISABLE_LOCATIONTYPE: {
            return { ...state, disable_locationtype: null, locationtypelist_refetch: true }
        }
        case DISABLE_LOCATIONTYPE_DISMISS: {
            return { ...state, disable_locationtype: null }
        }
        case SET_LOCATIONTYPE_ROLES: {
            return { ...state, locationtype_roles: action.payload }
        }
        default:
            return state;
    }
};
