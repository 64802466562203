import {
    ADD_NEW_SUPPLIERTYPE, DELETE_SUPPLIERTYPE, DELETE_SUPPLIERTYPE_DISMISS, DELETE_SUPPLIERTYPE_PREVIEW,
    DISABLE_SUPPLIERTYPE, DISABLE_SUPPLIERTYPE_DISMISS, DISABLE_SUPPLIERTYPE_PREVIEW,
    EDIT_SUPPLIERTYPE, EDIT_SUPPLIERTYPE_DISMISS, EDIT_SUPPLIERTYPE_PREVIEW, SET_SUPPLIERTYPE_DATA,
    SUPPLIERTYPE_FETCHING_COMPLETE, SUPPLIERTYPE_FETCHING_INIT, SUPPLIERTYPE_FETCHING_DISMISS, SET_SUPPLIERTYPE_PAGE,
    ADD_NEW_SUPPLIERTYPE_DISMISS, ADD_NEW_SUPPLIERTYPE_PREVIEW, SET_SUPPLIERTYPE_ROLES
} from "./types";

const initialState = {
    suppliertype_list: [],//holds supplier type list array
    suppliertypelist_fetching: false,//indicaction for fetch in progress
    suppliertypelist_refetch: false,//indication to refetch current supplier type list array
    suppliertypelist_refetch_force: false,//indication to force refetch supplier type list array from beginning 
    edit_suppliertype: null,//holds initial data of supplier type being edited
    delete_suppliertype: null,//holds initial data of supplier type opted to be deleted
    disable_suppliertype: null,//holds initial data of supplier type opted to be disabled,
    search: "",//search keyword on supplier type listing
    page: 1,//page number on supplier type listing
    perpage: 10,//perpage limit on supplier type listing
    total_suppliertypes: 0,//total count
    //new supplier type params
    supl_type_desc: "",
    //edit supplier type params
    eSupl_type_desc: ""
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_SUPPLIERTYPE_DATA:
            return { ...state, ...action.payload };
        case SUPPLIERTYPE_FETCHING_INIT: {
            return { ...state, suppliertypelist_fetching: true, suppliertypelist_refetch: false, suppliertypelist_refetch_force: false };
        }
        case SUPPLIERTYPE_FETCHING_COMPLETE: {
            return { ...state, suppliertypelist_fetching: false, ...action.payload };
        }
        case SUPPLIERTYPE_FETCHING_DISMISS: {
            return { ...state, suppliertypelist_fetching: false };
        }
        case SET_SUPPLIERTYPE_PAGE: {
            return { ...state, suppliertypelist_refetch: true, page: action.payload };
        }
        case ADD_NEW_SUPPLIERTYPE: {
            return { ...state, suppliertypelist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_SUPPLIERTYPE_PREVIEW: {
            return { ...state, new_suppliertype_popup: true, supl_type_desc: "" }
        }
        case ADD_NEW_SUPPLIERTYPE_DISMISS: {
            return { ...state, new_suppliertype_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_SUPPLIERTYPE_PREVIEW: {
            return { ...state, eSupplier_error: "", ...action.payload }
        }
        case EDIT_SUPPLIERTYPE: {
            return { ...state, suppliertypelist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_SUPPLIERTYPE_DISMISS: {
            return { ...state, edit_suppliertype: null }
        }
        case DELETE_SUPPLIERTYPE_PREVIEW: {
            return { ...state, delete_suppliertype: action.payload }
        }
        case DELETE_SUPPLIERTYPE: {
            return { ...state, suppliertypelist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_SUPPLIERTYPE_DISMISS: {
            return { ...state, delete_suppliertype: null }
        }
        case DISABLE_SUPPLIERTYPE_PREVIEW: {
            return { ...state, disable_suppliertype: action.payload }
        }
        case DISABLE_SUPPLIERTYPE: {
            return { ...state, disable_suppliertype: null, suppliertypelist_refetch: true }
        }
        case DISABLE_SUPPLIERTYPE_DISMISS: {
            return { ...state, disable_suppliertype: null }
        }
        case SET_SUPPLIERTYPE_ROLES: {
            return { ...state, suppliertype_roles: action.payload }
        }
        default:
            return state;
    }
};
