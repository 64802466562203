export const SET_CONTRACTTYPE_DATA = "SET_CONTRACTTYPE_DATA";
export const CONTRACTTYPE_FETCHING_INIT = "CONTRACTTYPE_FETCHING_INIT"
export const CONTRACTTYPE_FETCHING_COMPLETE = "CONTRACTTYPE_FETCHING_COMPLETE"
export const CONTRACTTYPE_FETCHING_DISMISS = "CONTRACTTYPE_FETCHING_DISMISS"
export const ADD_NEW_CONTRACTTYPE = "ADD_NEW_CONTRACTTYPE"
export const ADD_NEW_CONTRACTTYPE_PREVIEW = "ADD_NEW_CONTRACTTYPE_PREVIEW"
export const ADD_NEW_CONTRACTTYPE_DISMISS = "ADD_NEW_CONTRACTTYPE_DISMISS"
export const EDIT_CONTRACTTYPE_PREVIEW = "EDIT_CONTRACTTYPE_PREVIEW"
export const EDIT_CONTRACTTYPE = "EDIT_CONTRACTTYPE"
export const EDIT_CONTRACTTYPE_DISMISS = "EDIT_CONTRACTTYPE_DISMISS"
export const DELETE_CONTRACTTYPE_PREVIEW = "DELETE_CONTRACTTYPE_PREVIEW"
export const DELETE_CONTRACTTYPE_DISMISS = "DELETE_CONTRACTTYPE_DISMISS"
export const DELETE_CONTRACTTYPE = "DELETE_CONTRACTTYPE"
export const DISABLE_CONTRACTTYPE_PREVIEW = "DISABLE_CONTRACTTYPE_PREVIEW"
export const DISABLE_CONTRACTTYPE_DISMISS = "DISABLE_CONTRACTTYPE_DISMISS"
export const DISABLE_CONTRACTTYPE = "DISABLE_CONTRACTTYPE"
export const SET_CONTRACTTYPE_ROLES = "SET_CONTRACTTYPE_ROLES"
export const SET_CONTRACTTYPE_PAGE = "SET_CONTRACTTYPE_PAGE"
