import {
    ADD_NEW_COSTTYPE, DELETE_COSTTYPE, DELETE_COSTTYPE_DISMISS, DELETE_COSTTYPE_PREVIEW,
    DISABLE_COSTTYPE, DISABLE_COSTTYPE_DISMISS, DISABLE_COSTTYPE_PREVIEW,
    EDIT_COSTTYPE, EDIT_COSTTYPE_DISMISS, EDIT_COSTTYPE_PREVIEW, SET_COSTTYPE_DATA,
    COSTTYPE_FETCHING_COMPLETE, COSTTYPE_FETCHING_INIT, COSTTYPE_FETCHING_DISMISS, SET_COSTTYPE_PAGE,
    ADD_NEW_COSTTYPE_DISMISS, ADD_NEW_COSTTYPE_PREVIEW, SET_COSTTYPE_ROLES
} from "./types";

const initialState = {
    costtype_list: [],//holds cost type list array
    costtypelist_fetching: false,//indicaction for fetch in progress
    costtypelist_refetch: false,//indication to refetch current cost type list array
    costtypelist_refetch_force: false,//indication to force refetch cost type list array from beginning 
    edit_costtype: null,//holds initial data of cost type being edited
    delete_costtype: null,//holds initial data of cost type opted to be deleted
    disable_costtype: null,//holds initial data of cost type opted to be disabled,
    search: "",//search keyword on cost type listing
    page: 1,//page number on cost type listing
    perpage: 10,//perpage limit on cost type listing
    total_costtypes: 0,//total count
    //new cost type params
    cost_type_desc: "",
    //edit cost type params
    eCost_type_desc: ""
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_COSTTYPE_DATA:
            return { ...state, ...action.payload };
        case COSTTYPE_FETCHING_INIT: {
            return { ...state, costtypelist_fetching: true, costtypelist_refetch: false, costtypelist_refetch_force: false };
        }
        case COSTTYPE_FETCHING_COMPLETE: {
            return { ...state, costtypelist_fetching: false, ...action.payload };
        }
        case COSTTYPE_FETCHING_DISMISS: {
            return { ...state, costtypelist_fetching: false };
        }
        case SET_COSTTYPE_PAGE: {
            return { ...state, costtypelist_refetch: true, page: action.payload };
        }
        case ADD_NEW_COSTTYPE: {
            return { ...state, costtypelist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_COSTTYPE_PREVIEW: {
            return { ...state, new_costtype_popup: true, cost_type_desc: "" }
        }
        case ADD_NEW_COSTTYPE_DISMISS: {
            return { ...state, new_costtype_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_COSTTYPE_PREVIEW: {
            return { ...state, eCost_error: "", ...action.payload }
        }
        case EDIT_COSTTYPE: {
            return { ...state, costtypelist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_COSTTYPE_DISMISS: {
            return { ...state, edit_costtype: null }
        }
        case DELETE_COSTTYPE_PREVIEW: {
            return { ...state, delete_costtype: action.payload }
        }
        case DELETE_COSTTYPE: {
            return { ...state, costtypelist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_COSTTYPE_DISMISS: {
            return { ...state, delete_costtype: null }
        }
        case DISABLE_COSTTYPE_PREVIEW: {
            return { ...state, disable_costtype: action.payload }
        }
        case DISABLE_COSTTYPE: {
            return { ...state, disable_costtype: null, costtypelist_refetch: true }
        }
        case DISABLE_COSTTYPE_DISMISS: {
            return { ...state, disable_costtype: null }
        }
        case SET_COSTTYPE_ROLES: {
            return { ...state, costtype_roles: action.payload }
        }
        default:
            return state;
    }
};
