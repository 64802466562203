import {
    ADD_NEW_VAT, DELETE_VAT, DELETE_VAT_DISMISS, DELETE_VAT_PREVIEW,
    DISABLE_VAT, DISABLE_VAT_DISMISS, DISABLE_VAT_PREVIEW,
    EDIT_VAT, EDIT_VAT_DISMISS, EDIT_VAT_PREVIEW, SET_VAT_DATA,
    VAT_FETCHING_COMPLETE, VAT_FETCHING_INIT, VAT_FETCHING_DISMISS, SET_VAT_PAGE,
    ADD_NEW_VAT_DISMISS, ADD_NEW_VAT_PREVIEW, SET_VAT_ROLES
} from "./types";

const initialState = {
    vat_list: [],//holds vat list array
    vatlist_fetching: false,//indicaction for fetch in progress
    vatlist_refetch: false,//indication to refetch current vat list array
    vatlist_refetch_force: false,//indication to force refetch vat list array from beginning 
    edit_vat: null,//holds initial data of vat being edited
    delete_vat: null,//holds initial data of vat opted to be deleted
    disable_vat: null,//holds initial data of vat opted to be disabled,
    page: 1,//page number on vat listing
    perpage: 10,//perpage limit on vat listing
    total_vats: 0,//total count
    //new vat params
    vat_id: null,
    vat_desc: "",
    vat: "",
    vat_efd: "",
    vat_etd: "",
    current_vat: null,
    vatUpdateRefetch: false,

    
    search: "",//search keyword on vat listing
    //edit vat params
    eVat_desc: "",
    eVat: "",
    eVat_efd: "",
    eVat_etd: "",
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_VAT_DATA:
            return { ...state, ...action.payload };
        case VAT_FETCHING_INIT: {
            return { ...state, vatlist_fetching: true, vatlist_refetch: false, vatlist_refetch_force: false };
        }
        case VAT_FETCHING_COMPLETE: {
            return { ...state, vatlist_fetching: false, ...action.payload };
        }
        case VAT_FETCHING_DISMISS: {
            return { ...state, vatlist_fetching: false };
        }
        case SET_VAT_PAGE: {
            return { ...state, vatlist_refetch: true, page: action.payload };
        }
        case ADD_NEW_VAT: {
            return { ...state, vatlist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_VAT_PREVIEW: {
            return { ...state, new_vat_popup: true, vat_desc: "", vat_efd: "", vat: "", vat_etd: "" }
        }
        case ADD_NEW_VAT_DISMISS: {
            return { ...state, new_vat_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_VAT_PREVIEW: {
            return { ...state, eVat_error: "", ...action.payload }
        }
        case EDIT_VAT: {
            return { ...state, vatlist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_VAT_DISMISS: {
            return { ...state, edit_vat: null }
        }
        case DELETE_VAT_PREVIEW: {
            return { ...state, delete_vat: action.payload }
        }
        case DELETE_VAT: {
            return { ...state, vatlist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_VAT_DISMISS: {
            return { ...state, delete_vat: null }
        }
        case DISABLE_VAT_PREVIEW: {
            return { ...state, disable_vat: action.payload }
        }
        case DISABLE_VAT: {
            return { ...state, disable_vat: null, vatlist_refetch: true }
        }
        case DISABLE_VAT_DISMISS: {
            return { ...state, disable_vat: null }
        }
        case SET_VAT_ROLES: {
            return { ...state, vat_roles: action.payload }
        }
        default:
            return state;
    }
};
