import {
    ADD_NEW_INSTALLATIONJOBTYPE,
    DELETE_INSTALLATIONJOBTYPE,
    DELETE_INSTALLATIONJOBTYPE_DISMISS,
    DELETE_INSTALLATIONJOBTYPE_PREVIEW,
    EDIT_INSTALLATIONJOBTYPE,
    EDIT_INSTALLATIONJOBTYPE_DISMISS,
    EDIT_INSTALLATIONJOBTYPE_PREVIEW,
    SET_INSTALLATIONJOBTYPE_DATA,
    INSTALLATIONJOBTYPE_FETCHING_COMPLETE,
    INSTALLATIONJOBTYPE_FETCHING_INIT,
    INSTALLATIONJOBTYPE_FETCHING_DISMISS,
    ADD_NEW_INSTALLATIONJOBTYPE_DISMISS,
    ADD_NEW_INSTALLATIONJOBTYPE_PREVIEW,
    SET_INSTALLATIONJOBTYPE_PAGE,
    INSTALLATIONJOBTYPE_CREATE_DETAILS_FETCHING_COMPLETE,
  } from "./types";
  const initialState={

    search: "",
    page: 1,
    perpage: 10,
    installationjobtype_list: [],
    title_name: "",
    dependency_array: [],
    new_installationjobtype_popup: null, 
    total_installationjobtype: "",//total count
    file_column_list: [],
    installationjobtype_refetch:false,
    edit_installationjobtype:null,
    title:"",eInstallationJobType_error:"",
    // eDependency_arr: [],
    eIO_name:"",
    eDependencyArr:[],
    delete_installationjobtype: null,
    installationjobtype_refetch_force: false,
    installationjobtype_fetching: false,//indicaction for fetch in progress

  };

  export default (state = initialState, action = {}) => {
    switch (action.type) {
        case INSTALLATIONJOBTYPE_CREATE_DETAILS_FETCHING_COMPLETE:
            return { ...state, ...action.payload }
        case SET_INSTALLATIONJOBTYPE_DATA:
            return { ...state, ...action.payload };
        case INSTALLATIONJOBTYPE_FETCHING_INIT: {
            return { ...state, installationjobtype_fetching: true, installationjobtype_refetch: false, installationjobtype_refetch_force: false };
        }
        case INSTALLATIONJOBTYPE_FETCHING_COMPLETE: {
            return { ...state, installationjobtype_fetching: false, ...action.payload };
        }
        case INSTALLATIONJOBTYPE_FETCHING_DISMISS: {
            return { ...state, installationjobtype_fetching: false };
        }
        case SET_INSTALLATIONJOBTYPE_PAGE: {
            return { ...state, installationjobtype_refetch: true, page: action.payload };
        }
        case ADD_NEW_INSTALLATIONJOBTYPE: {
            return { ...state, installationjobtype_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_INSTALLATIONJOBTYPE_PREVIEW: {
            return { ...state, new_installationjobtype_popup: true, title_name: "" }
        }
        case ADD_NEW_INSTALLATIONJOBTYPE_DISMISS: {
            return { ...state, new_installationjobtype_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_INSTALLATIONJOBTYPE_PREVIEW: {
            return { ...state, eInstallationJobType_error: "", ...action.payload }
        }
        case EDIT_INSTALLATIONJOBTYPE: {
            return { ...state, installationjobtype_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_INSTALLATIONJOBTYPE_DISMISS: {
            return { ...state, edit_installationjobtype: null,edit_btn_disable: false }
        }
        case DELETE_INSTALLATIONJOBTYPE_PREVIEW: {
            return { ...state, ...action.payload }
        }
        case DELETE_INSTALLATIONJOBTYPE: {
            return { ...state, installationjobtype_refetch: true, search: "", del_btn_disable: false }
        }
        case DELETE_INSTALLATIONJOBTYPE_DISMISS: {
            return { ...state, delete_installationjobtype: null }
        }
        default:
            return state;
    }
};
