import {
    FETCH_ASNFILES, FETCH_ASNFILES_DISMISS, FETCH_ASNFILES_INIT, SET_ASN_RECORDS_PAGE,SET_VALIDATIONREJECTED_DATA
} from "./types";

const initialState = {
    asnfiles_list: [],//holds asn files list array
    asnfileslist_fetching: false,//indicaction for fetch in progress
    asnfileslist_refetch: false,//indication to refetch current asn files list array
    asnfileslist_refetch_force: false,//indication to force refetch asn files list array from beginning 
    search: "",//search keyword on asn files listing
    page: 1,//page number on asn files listing
    perpage: 10,//perpage limit on asn files listing
    total_asnfiles: 0,//total count
    data_fetching:false,
    asn_file_id: null,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_ASN_RECORDS_PAGE:
            return { ...state, asnfileslist_refetch: true, page: action.payload };
        case FETCH_ASNFILES_INIT:
            return { ...state, asnfileslist_refetch: false, asnfileslist_refetch_force: false };
        case FETCH_ASNFILES_DISMISS:
            return { ...state, };
        case FETCH_ASNFILES:
            return { ...state, asnfileslist_refetch: false, ...action.payload };
        case SET_VALIDATIONREJECTED_DATA:
            return {...state, ...action.payload }
        default:
            return state;
    }
};
