import {
    FETCH_PERMISSION_DATA_INIT,
    FETCH_PERMISSION_DATA_DISMISS,
    FETCH_PERMISSION_DATA_COMPLETE,
    SET_ROLE_PERMISSION,
    SET_TABLE_CELLS,
    FETCH_EXISTING_PERMISSION_DATA_INIT,
    FETCH_EXISTING_PERMISSION_DATA_COMPLETE,
    FETCH_EXISTING_PERMISSION_DATA_DISMISS,
    FETCH_USER_ROLES_INIT,
    FETCH_USER_ROLES_COMPLETE,
    FETCH_USER_ROLES_DISMISS,
    SET_ROLE_PERMISSION_UNCHANGED,
    SET_PERMISSION_MODULE_DATA
} from './types'

const initialState = {
    module_list: [],//holds module list array
    tableCells: [], //holds initial access status
    role_id: null, //holds initial role id
    role_list: [], //holds inital roles list array
    organisation_type_id: null, //holds initial organisation id
    module_id: null, //holds initial module id
    color_cells: null, //holds initial colored table cells
    role_enable_btn_diable:false,
    roles_fetching:false
}

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_ROLE_PERMISSION_UNCHANGED:
            return { ...state };
        case FETCH_PERMISSION_DATA_INIT:
            return { ...state };
        case FETCH_PERMISSION_DATA_DISMISS:
            return { ...state };
        case FETCH_PERMISSION_DATA_COMPLETE:
            return { ...state, ...action.payload };
        case SET_PERMISSION_MODULE_DATA:
            return { ...state, ...action.payload };
        case SET_ROLE_PERMISSION:
            return { ...state, ...action.payload };
        case SET_TABLE_CELLS:
            return { ...state, ...action.payload };
        case FETCH_EXISTING_PERMISSION_DATA_INIT:
            return { ...state };
        case FETCH_EXISTING_PERMISSION_DATA_COMPLETE:
            return { ...state, ...action.payload };
        case FETCH_EXISTING_PERMISSION_DATA_DISMISS:
            return { ...state };
        case FETCH_USER_ROLES_INIT:
            return { ...state,...action.payload,roles_fetching:true }
        case FETCH_USER_ROLES_COMPLETE:
            return { ...state, ...action.payload }
        case FETCH_USER_ROLES_DISMISS:
            return { ...state, }
        default:
            return state;
    }
}