export const SET_INVSTATUSTYPE_DATA = "SET_INVSTATUSTYPE_DATA";
export const INVSTATUSTYPE_FETCHING_INIT = "INVSTATUSTYPE_FETCHING_INIT"
export const INVSTATUSTYPE_FETCHING_COMPLETE = "INVSTATUSTYPE_FETCHING_COMPLETE"
export const INVSTATUSTYPE_FETCHING_DISMISS = "INVSTATUSTYPE_FETCHING_DISMISS"
export const ADD_NEW_INVSTATUSTYPE = "ADD_NEW_INVSTATUSTYPE"
export const ADD_NEW_INVSTATUSTYPE_PREVIEW = "ADD_NEW_INVSTATUSTYPE_PREVIEW"
export const ADD_NEW_INVSTATUSTYPE_DISMISS = "ADD_NEW_INVSTATUSTYPE_DISMISS"
export const EDIT_INVSTATUSTYPE_PREVIEW = "EDIT_INVSTATUSTYPE_PREVIEW"
export const EDIT_INVSTATUSTYPE = "EDIT_INVSTATUSTYPE"
export const EDIT_INVSTATUSTYPE_DISMISS = "EDIT_INVSTATUSTYPE_DISMISS"
export const DELETE_INVSTATUSTYPE_PREVIEW = "DELETE_INVSTATUSTYPE_PREVIEW"
export const DELETE_INVSTATUSTYPE_DISMISS = "DELETE_INVSTATUSTYPE_DISMISS"
export const DELETE_INVSTATUSTYPE = "DELETE_INVSTATUSTYPE"
export const DISABLE_INVSTATUSTYPE_PREVIEW = "DISABLE_INVSTATUSTYPE_PREVIEW"
export const DISABLE_INVSTATUSTYPE_DISMISS = "DISABLE_INVSTATUSTYPE_DISMISS"
export const DISABLE_INVSTATUSTYPE = "DISABLE_INVSTATUSTYPE"
export const SET_INVSTATUSTYPE_ROLES = "SET_INVSTATUSTYPE_ROLES"
export const SET_INVSTATUSTYPE_PAGE = "SET_INVSTATUSTYPE_PAGE"
