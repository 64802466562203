export const SET_SCRAPPED_REPORT_FETCH_INTI = "SET_SCRAPPED_REPORT_FETCH_INTI";
export const REPORT_FETCHING_INIT = "REPORT_FETCHING_INIT";
export const FETCH_SCRAP_REPORT_FILE_LIST_COMPLETE = "FETCH_SCRAP_REPORT_FILE_LIST_COMPLETE";
export const REPORT_FETCHING_DISMISS = "REPORT_FETCHING_DISMISS";

const setScrappedReport = {
    scrap_report_fetching:true,
    report_list:[],
    page:1,
    perpage:10,
    search:"",
    date:[],
    scrap_report_list_refetch:true,
    export_support_flag_value_report:1,
    download_support_flag:false,
    search_cod_no:"",
    report_location:""
}

export default (state = setScrappedReport, action = {}) => {
    switch (action.type) {
        case SET_SCRAPPED_REPORT_FETCH_INTI:
            return { ...state, ...action.payload };
        case REPORT_FETCHING_INIT:
            return { ...state, scrap_report_fetching:true}
        case FETCH_SCRAP_REPORT_FILE_LIST_COMPLETE:
            return { ...state, ...action.payload}
        case REPORT_FETCHING_DISMISS:
            return {...state, scrap_report_fetching:false,scrap_report_list_refetch:false }
        default:
            return state;
    }
}