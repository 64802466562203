export const SET_AUTH_DATA = "SET_AUTH_DATA";
export const USER_AUTHENTICATED = "USER_AUTHENTICATED"
export const FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD"
export const MFA_CONFIRMATION_REQUIRED = "MFA_CONFIRMATION_REQUIRED"
export const USER_INFO = "USER_INFO"
export const USER_SIGNOUT = "USER_SIGNOUT"
export const SETUP_TOTP = "SETUP_TOTP"
export const TOTP_COMPLETE = "TOTP_COMPLETE"
export const SET_USR_ROLE_PERMISSION = "SET_USR_ROLE_PERMISSION"

const initialState = {
  authLoading: true, // initial status that indicates user checking is executed
  passwordResetLinkValidation: true,
  profileUpdateBtnDisable: false,
  passwordUpdateBtnDisable: false,
  emailSent: false,
  role_permission: [],
  disable_signIn_button:false,
  password: '',
  confirm_password: '',
  profileEdit:false,
  company_id: null,
  validation_count: "",
  validation_approve_flag: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_AUTH_DATA:
      return { ...state, ...action.payload };
    case USER_AUTHENTICATED: {
      return { ...state, authenticated: true, changePassword: false, mfa: false, temp_user_info: {}, user: action.payload };
    }
    case MFA_CONFIRMATION_REQUIRED: {
      return { ...state, authenticated: false, mfa: true, changePassword: false, ...action.payload };
    }
    case FORCE_CHANGE_PASSWORD: {
      return { ...state, authenticated: false, mfa: false, changePassword: true, ...action.payload };
    }
    case USER_INFO: {
      return { ...state, authenticated: false, authLoading: false, ...action.payload };
    }
    // case USER_INFO: {
    //   return { ...state, authenticated: false, user: null };
    // }
    case USER_SIGNOUT: {
      return { ...state, authenticated: false, mfa: false, temp_user_info: {}, user: {}, passwordAttempts: 0 };
    }
    case SETUP_TOTP: {
      return { ...state, totp_popup: true, totp_connection_string: action.payload }
    }
    case TOTP_COMPLETE: {
      return { ...state, totp_popup: false, totp_connection_string: "" }
    }
    case SET_USR_ROLE_PERMISSION:
      return { ...state, ...action.payload }
    default:
      return state;
  }
};
