export const SET_INS_REM_REPORT_DATA = "SET_INS_REM_REPORT_DATA";
export const REPORT_FETCHING_INIT = "REPORT_FETCHING_INIT";
export const REPORT_FETCHING_COMPLETE = "REPORT_FETCHING_COMPLETE";
export const REPORT_FETCHING_DISMISS = "REPORT_FETCHING_DISMISS";

const initialState = {
    inst_rem_report_fetching:true,
    inst_rem_report_list:[],
    total_inst_rem_report_list:0,
    page:1,
    perpage:10,
    from:"",
    to:"",
    inst_rem_report_list_refetch:true,
    export_support_flag_value_report:1,
    download_support_flag:false,
    search_mpxn:"",
}

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_INS_REM_REPORT_DATA:
            return { ...state, ...action.payload };
        case REPORT_FETCHING_INIT:
            return { ...state, inst_rem_report_fetching:true}
        case REPORT_FETCHING_COMPLETE:
            return { ...state, ...action.payload}
        case REPORT_FETCHING_DISMISS:
            return {...state, inst_rem_report_fetching:false,inst_rem_report_list_refetch:false }
        default:
            return state;
    }
}