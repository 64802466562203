export const SET_SUPPLIER_DATA = "SET_SUPPLIER_DATA";
export const SUPPLIER_FETCHING_INIT = "SUPPLIER_FETCHING_INIT"
export const SUPPLIER_FETCHING_COMPLETE = "SUPPLIER_FETCHING_COMPLETE"
export const SUPPLIER_FETCHING_DISMISS = "SUPPLIER_FETCHING_DISMISS"
export const ADD_NEW_SUPPLIER = "ADD_NEW_SUPPLIER"
export const ADD_NEW_SUPPLIER_PREVIEW = "ADD_NEW_SUPPLIER_PREVIEW"
export const ADD_NEW_SUPPLIER_DISMISS = "ADD_NEW_SUPPLIER_DISMISS"
export const EDIT_SUPPLIER_PREVIEW = "EDIT_SUPPLIER_PREVIEW"
export const EDIT_SUPPLIER = "EDIT_SUPPLIER"
export const EDIT_SUPPLIER_DISMISS = "EDIT_SUPPLIER_DISMISS"
export const DELETE_SUPPLIER_PREVIEW = "DELETE_SUPPLIER_PREVIEW"
export const DELETE_SUPPLIER_DISMISS = "DELETE_SUPPLIER_DISMISS"
export const DELETE_SUPPLIER = "DELETE_SUPPLIER"
export const DISABLE_SUPPLIER_PREVIEW = "DISABLE_SUPPLIER_PREVIEW"
export const DISABLE_SUPPLIER_DISMISS = "DISABLE_SUPPLIER_DISMISS"
export const DISABLE_SUPPLIER = "DISABLE_SUPPLIER"
export const SET_SUPPLIER_ROLES = "SET_SUPPLIER_ROLES"
export const SET_SUPPLIER_PAGE = "SET_SUPPLIER_PAGE"
export const SET_NEW_SUPPLIER_LOCATION = "SET_NEW_SUPPLIER_LOCATION"
export const REMOVE_SUPPLIER_LOCATION = "REMOVE_SUPPLIER_LOCATION"
