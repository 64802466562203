import {
  SET_AUTH_DATA,
  USER_AUTHENTICATED,
  USER_INFO,
  SET_USR_ROLE_PERMISSION,
} from "./reducer";
import cogoToast from "cogo-toast";
import i18n from "../../assets/i18n";
import http from "../../config/http";
import secureLocalStorage  from  "react-secure-storage";
import CryptoJS from 'crypto-js';

// Function to pad or truncate a string to 32 bytes (256 bits)
const padStringTo32Bytes = (str) => {
  if (typeof str !== 'string') {
    throw new Error('Key must be a string'); // Error if the input is not a string
  }
  const paddingChar = '0'; // Use '0' for padding
  if (str.length >= 32) {
    return str.substring(0, 32); // Truncate if the string is too long
  }
  return str.padEnd(32, paddingChar); // Pad if the string is too short
};

//SET user info to redux
export const getCurrentUser = () => async (dispatch) => {
  //check whether user has an active session
  // if yes load it to redux
  try {

    let authData = secureLocalStorage.getItem("auth") ? secureLocalStorage.getItem("auth") : '';

    let userData = authData.user;

    if (userData) {
      dispatch({
        type: USER_INFO,
        payload: {
          user: userData.user_id,
          profile: userData,
          user_name: userData.user_name,
          user_role: userData.user_role,
          user_email: userData.user_email,
          first_name: userData.first_name,
          last_name: userData.last_name,
          user_mobile: userData.user_mobile,
        }
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: {
        }
      });
    }

  } catch (err) {
    dispatch(setAuthData("authLoading", false));
  }
};

export const setAuthData = (name, value) => (dispatch) => {
  dispatch({
    type: SET_AUTH_DATA,
    payload: { [name]: value },
  });
};

export const fetchRolePermission = (user) => async (dispatch) => {
  try {
    const doRequest = http.get(`list-role-permission?role_id=${user.role_id}&company_id=${user.company_id}&role_name=${user.role_name}`);
    doRequest.then(
      (res) => {
        let permissions = res.data.data;
        let validation_count = { validation_req: res.data.validationCount ? res.data.validationCount : "" };
        dispatch({
          type: SET_USR_ROLE_PERMISSION,
          payload: { 
            validation_count,
            role_permission: permissions
          }
        })
        dispatch({
          type: USER_AUTHENTICATED,
          payload: {
            user: user.user_id,
            user_name: user.user_name,
            email: user.user_email
          }
        });
      },
      (err) => {
        console.log(err);
      }
    )
  } catch (err) {
    console.log(err);
  }
}

export const signIn = ({ username, password, history }) => async (
  dispatch
) => {
  try {
    dispatch(setAuthData("disable_signIn_button", true));

    // Generate a 32-byte key (use one of the methods above)
    const secretKey = CryptoJS.enc.Utf8.parse(padStringTo32Bytes('myParonMapAsset')); // or use randomKey

    // Encrypt the message using AES with the 32-byte key
    const encPassword = CryptoJS.AES.encrypt(password, secretKey,{iv:secretKey}).toString();

    let postData = "";
    postData = {
      email: username,
      password: encPassword,
    };

    const doRequest = http.post(`/users/session`, postData);
    doRequest.then(
      (res) => {

        secureLocalStorage.setItem("auth", res.data.data);

        dispatch(fetchRolePermission(res.data.data.user))
        dispatch(setAuthData("releaseVersion",res.data.data.user.release_version))
        dispatch({
          type: USER_INFO,
          payload: res.data.data.user
        });

        // user authenticated, lets navigate to /dashboard route
        toastFix();
        cogoToast.success("Welcome to Paragon");
        //set user session to redux

        setAuthData("authLoading", false)
        setAuthData("company_id", res.data.data.user?.company_id)

        history.push("/dashboard");
        dispatch(setAuthData("disable_signIn_button", false));
      },
      (err) => {
        dispatch(setAuthData("disable_signIn_button", false));
        let errMessage =
          err?.response?.data?.error_message || i18n.t("Something went wrong, try again");
        toastFix(); 
        cogoToast.error(errMessage);
      }
    )


  } catch (error) {
    toastFix();
    cogoToast.error(error.message || i18n.t("Something went wrong, try again"));
    dispatch(setAuthData("disable_signIn_button", false));
  }
};

export const resendVerificationCode = ({
  username,
  password,
}) => async (dispatch) => {
  try {
    console.log();
  } catch (error) {
    toastFix();
    cogoToast.error(i18n.t("Maximum Retries Reached"));
  }
};

export const requestPasswordReset = ({ username }) => async (dispatch) => {
  try {
    let postData = "";
    postData = {
      email: username,
    };

    const doRequest = http.post(`/users/forgot-password`, postData);
    doRequest.then(
      (res) => {
        toastFix();
        cogoToast.success(res.data.success_message);
        dispatch(setAuthData("emailSent", true))
      },
      (error) => {
        toastFix();
        cogoToast.error(error?.response?.data?.error_message || i18n.t("Sorry, Failed To Send Mail Link. Please Try Again"));
      }
    )
  } catch (error) {
    toastFix();
    cogoToast.error(error.message || i18n.t("Something went wrong, try again"));
  }
};

export const resetPassword = ({ code, password, confirm_password, history }) => async (
  dispatch
) => {
  try {
    let postData = '';
    postData = {
      new_password: password,
      confirm_password: confirm_password,
    }
    
    const doRequest = http.post(`/users/reset-password/${code}`, postData)
    doRequest.then(
      (res) => {
        
        dispatch(setAuthData("passwordResetComplete", true));

      }, (err) => {
        dispatch(setAuthData('password',''))
        dispatch(setAuthData('confirm_password',''))
        toastFix();
        cogoToast.error(i18n.t("Your Password Reset Link Expired, Please Generate A New One"))
        dispatch(setAuthData("passwordResetLinkExpired", true));
        history.push('/forgot-password')
        
      }
    )

  } catch (error) {
    dispatch(setAuthData("passwordResetLinkExpired", true));
    toastFix();
    cogoToast.error(i18n.t("Something went wrong, please try again or generate new reset link."), { hideAfter: 5 });
  }
};


export const logout = (history) => async (dispatch) => {
  setAuthData("authLoading", true)
  try {
    const doRequest = http.delete(`/users/session`);
    doRequest.then(
      (res) => {

        if (res.data.success == 'logout_success') {
          secureLocalStorage.clear();
          toastFix();
          location.reload();
          cogoToast.success("Logout Successfully");
        } else {
          toastFix();
          cogoToast.success("Logout Failed. Please try again");
        }
      },
      (error) => {
        //Invalid authenticated error message
        toastFix();
        cogoToast.error(error.error_message || i18n.t("Logout Failed. Please try again"));
      }
    )
  } catch (err) {
    toastFix();
    cogoToast.error(err.message || i18n.t("Something went wrong, try again"));
  }
};



export const updateProfile = (profile) => async (dispatch) => {
  try {

    dispatch(setAuthData("profileUpdateBtnDisable", true));

    let authData = secureLocalStorage.getItem("auth") ? secureLocalStorage.getItem("auth") : '';

    if (!authData) {
      history.push("/");
    }

    let userData = authData.user;

    let postData = "";

    postData = {
      username: profile.user_name,
      // user_email: profile.user_email,
      first_name: profile.first_name,
      last_name: profile.last_name,
      mobile: profile.user_mobile,
      password: profile.password,
    };

    const doRequest = http.put(`/users/${userData.user_id}/profile`, postData);
    doRequest.then(
      (res) => {

        authData.user.user_name = profile.user_name;
        authData.user.first_name = profile.first_name;
        authData.user.last_name = profile.last_name;

        let modifiedAuthData = authData;

        secureLocalStorage.setItem('auth', modifiedAuthData);

        toastFix();
        cogoToast.success(i18n.t("Profile Updated Successfully"));
      },
      (error) => {
        toastFix();
        cogoToast.error(error.error_message || i18n.t("Failed to Update. Please try again!."));
      }
    )


    dispatch(setAuthData("profileUpdateBtnDisable", false));

  } catch (err) {
    let errMessage =
      err?.response?.data?.message || i18n.t("Something went wrong, try again");
    toastFix();
    cogoToast.error(errMessage);
    dispatch(setAuthData("profileUpdateBtnDisable", false));
  }
};


export const updatePassword = ({ currentPassword, newPassword, confirmPassword }) => async (dispatch) => {
  try {
    dispatch(setAuthData("passwordUpdateBtnDisable", true));

    let authData = secureLocalStorage.getItem("auth") ? secureLocalStorage.getItem("auth") : '';

    if (!authData) {
      history.push("/");
    }

    let userData = authData.user;

    let postData = "";
    postData = {
      current_password: currentPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    
    const doRequest = http.put(`/users/${userData.user_id}/change/password`, postData);
    doRequest.then(
      (res) => {

        toastFix();
        cogoToast.success(i18n.t("Password Updated Successfully"));
        setTimeout(function () {
          location.reload();
        }, 1000);

      },
      (error) => {
        toastFix();
        cogoToast.error(error?.response?.data?.error_message || i18n.t("Failed to Update. Please try again!."));
      }
    )

    dispatch({
      type: SET_AUTH_DATA,
      payload: {
        passwordUpdateBtnDisable: false
      }
    });
  } catch (err) {
    let errMessage =
      err?.message || i18n.t("Something went wrong, try again");
    toastFix();
    cogoToast.error(errMessage);
    dispatch(setAuthData("passwordUpdateBtnDisable", false));
  }
};