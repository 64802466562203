import {
    ADD_NEW_ENERGYTYPE, DELETE_ENERGYTYPE, DELETE_ENERGYTYPE_DISMISS, DELETE_ENERGYTYPE_PREVIEW,
    DISABLE_ENERGYTYPE, DISABLE_ENERGYTYPE_DISMISS, DISABLE_ENERGYTYPE_PREVIEW,
    EDIT_ENERGYTYPE, EDIT_ENERGYTYPE_DISMISS, EDIT_ENERGYTYPE_PREVIEW, SET_ENERGYTYPE_DATA,
    ENERGYTYPE_FETCHING_COMPLETE, ENERGYTYPE_FETCHING_INIT, ENERGYTYPE_FETCHING_DISMISS, SET_ENERGYTYPE_PAGE,
    ADD_NEW_ENERGYTYPE_DISMISS, ADD_NEW_ENERGYTYPE_PREVIEW, SET_ENERGYTYPE_ROLES
} from "./types";

const initialState = {
    energytype_list: [],//holds energy type list array
    energytypelist_fetching: false,//indicaction for fetch in progress
    energytypelist_refetch: false,//indication to refetch current energy type list array
    energytypelist_refetch_force: false,//indication to force refetch energy type list array from beginning 
    edit_energytype: null,//holds initial data of energy type being edited
    delete_energytype: null,//holds initial data of energy type opted to be deleted
    disable_energytype: null,//holds initial data of energy type opted to be disabled,
    search: "",//search keyword on energy type listing
    page: 1,//page number on energy type listing
    perpage: 10,//perpage limit on energy type listing
    total_energytypes: 0,//total count
    //new energy type params
    energy_type_desc: "",
    //edit energy type params
    eEnergy_type_desc: ""
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_ENERGYTYPE_DATA:
            return { ...state, ...action.payload };
        case ENERGYTYPE_FETCHING_INIT: {
            return { ...state, energytypelist_fetching: true, energytypelist_refetch: false, energytypelist_refetch_force: false };
        }
        case ENERGYTYPE_FETCHING_COMPLETE: {
            return { ...state, energytypelist_fetching: false, ...action.payload };
        }
        case ENERGYTYPE_FETCHING_DISMISS: {
            return { ...state, energytypelist_fetching: false };
        }
        case SET_ENERGYTYPE_PAGE: {
            return { ...state, energytypelist_refetch: true, page: action.payload };
        }
        case ADD_NEW_ENERGYTYPE: {
            return { ...state, energytypelist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_ENERGYTYPE_PREVIEW: {
            return { ...state, new_energytype_popup: true, energy_type_desc: "" }
        }
        case ADD_NEW_ENERGYTYPE_DISMISS: {
            return { ...state, new_energytype_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_ENERGYTYPE_PREVIEW: {
            return { ...state, eEnergy_error: "", ...action.payload }
        }
        case EDIT_ENERGYTYPE: {
            return { ...state, energytypelist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_ENERGYTYPE_DISMISS: {
            return { ...state, edit_energytype: null }
        }
        case DELETE_ENERGYTYPE_PREVIEW: {
            return { ...state, delete_energytype: action.payload }
        }
        case DELETE_ENERGYTYPE: {
            return { ...state, energytypelist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_ENERGYTYPE_DISMISS: {
            return { ...state, delete_energytype: null }
        }
        case DISABLE_ENERGYTYPE_PREVIEW: {
            return { ...state, disable_energytype: action.payload }
        }
        case DISABLE_ENERGYTYPE: {
            return { ...state, disable_energytype: null, energytypelist_refetch: true }
        }
        case DISABLE_ENERGYTYPE_DISMISS: {
            return { ...state, disable_energytype: null }
        }
        case SET_ENERGYTYPE_ROLES: {
            return { ...state, energytype_roles: action.payload }
        }
        default:
            return state;
    }
};
