export const SET_SCRAP_LOCATION_DATA = "SET_SCRAP_LOCATION_DATA";
export const DATA_FETCHING_INIT = "DATA_FETCHING_INIT";
export const DATA_FETCHING_DISMISS = "DATA_FETCHING_DISMISS";
export const FETCH_SCRAP_LOCATION_LIST_COMPLETE = "FETCH_SCRAP_LOCATION_LIST_COMPLETE";
export const ADD_SCRAP_LOCATION_PREVIEW = "ADD_SCRAP_LOCATION_PREVIEW";
export const ADD_SCRAP_LOCATION = "ADD_SCRAP_LOCATION";
export const ADD_SCRAP_LOCATION_DISMISS = "ADD_SCRAP_LOCATION_DISMISS";
export const EDIT_SCRAP_LOCATION_PREVIEW = "EDIT_SCRAP_LOCATION_PREVIEW";
export const EDIT_SCRAP_LOCATION = "EDIT_SCRAP_LOCATION";
export const EDIT_SCRAP_LOCATION_DISMISS = "EDIT_SCRAP_LOCATION_DISMISS";

const scrapLocationState = {
    search: "",
    page: 1,
    perpage: 10,
    total_scrap_location_list: 0,
    scrap_location_list: [],
    list_refetch: false,
    location_data_fetching: false,
    add_btn_disable: false,
    scrap_location_popup: false,
    editLocation: null,
    edit_btn_disable: false,
    e_scrap_location: "",
    scrap_location: ""
}

export default (state = scrapLocationState, action = {}) => {
    switch (action.type) {
        case SET_SCRAP_LOCATION_DATA:
            return { ...state, ...action.payload };
        case DATA_FETCHING_INIT:
            return { ...state, ...action.payload, location_data_fetching: true };
        case DATA_FETCHING_DISMISS:
            return { ...state, ...action.payload, location_data_fetching: false };
        case FETCH_SCRAP_LOCATION_LIST_COMPLETE:
            return { ...state, ...action.payload, location_data_fetching: false };
        case ADD_SCRAP_LOCATION:
            return { ...state, list_refetch: true, add_btn_disable: false, scrap_location_popup: false, };
        case ADD_SCRAP_LOCATION_PREVIEW: {
            return {
                ...state,
                scrap_location: "",
                scrap_location_popup: true,
            }
        }
        case ADD_SCRAP_LOCATION_DISMISS: {
            return { ...state, scrap_location_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_SCRAP_LOCATION_PREVIEW: {
            return { ...state, ...action.payload }
        }
        case EDIT_SCRAP_LOCATION_DISMISS: {
            return { ...state, editLocation: null }
        }
        case EDIT_SCRAP_LOCATION: {
            return { ...state, list_refetch: true, edit_btn_disable: false }
        }
        default:
            return state;
    }
};