import {
    ADD_NEW_METERTYPE, DELETE_METERTYPE, DELETE_METERTYPE_DISMISS, DELETE_METERTYPE_PREVIEW,
    DISABLE_METERTYPE, DISABLE_METERTYPE_DISMISS, DISABLE_METERTYPE_PREVIEW,
    EDIT_METERTYPE, EDIT_METERTYPE_DISMISS, EDIT_METERTYPE_PREVIEW, SET_METERTYPE_DATA,
    METERTYPE_FETCHING_COMPLETE, METERTYPE_FETCHING_INIT, METERTYPE_FETCHING_DISMISS, SET_METERTYPE_PAGE,
    ADD_NEW_METERTYPE_DISMISS, ADD_NEW_METERTYPE_PREVIEW, SET_METERTYPE_ROLES
} from "./types";

const initialState = {
    metertype_list: [],//holds meter type list array
    metertypelist_fetching: false,//indicaction for fetch in progress
    metertypelist_refetch: false,//indication to refetch current meter type list array
    metertypelist_refetch_force: false,//indication to force refetch meter type list array from beginning 
    edit_metertype: null,//holds initial data of meter type being edited
    delete_metertype: null,//holds initial data of meter type opted to be deleted
    disable_metertype: null,//holds initial data of meter type opted to be disabled,
    search: "",//search keyword on meter type listing
    page: 1,//page number on meter type listing
    perpage: 10,//perpage limit on meter type listing
    total_metertypes: 0,//total count
    //new meter type params
    meter_type_desc: "",
    meter_type_code: "",
    //edit meter type params
    eMeter_type_desc: ""
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_METERTYPE_DATA:
            return { ...state, ...action.payload };
        case METERTYPE_FETCHING_INIT: {
            return { ...state, metertypelist_fetching: true, metertypelist_refetch: false, metertypelist_refetch_force: false };
        }
        case METERTYPE_FETCHING_COMPLETE: {
            return { ...state, metertypelist_fetching: false, ...action.payload };
        }
        case METERTYPE_FETCHING_DISMISS: {
            return { ...state, metertypelist_fetching: false };
        }
        case SET_METERTYPE_PAGE: {
            return { ...state, metertypelist_refetch: true, page: action.payload };
        }
        case ADD_NEW_METERTYPE: {
            return { ...state, metertypelist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_METERTYPE_PREVIEW: {
            return { ...state, new_metertype_popup: true, meter_type_desc: "", meter_type_code: "" }
        }
        case ADD_NEW_METERTYPE_DISMISS: {
            return { ...state, new_metertype_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_METERTYPE_PREVIEW: {
            return { ...state, eMeter_error: "", ...action.payload }
        }
        case EDIT_METERTYPE: {
            return { ...state, metertypelist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_METERTYPE_DISMISS: {
            return { ...state, edit_metertype: null }
        }
        case DELETE_METERTYPE_PREVIEW: {
            return { ...state, delete_metertype: action.payload }
        }
        case DELETE_METERTYPE: {
            return { ...state, metertypelist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_METERTYPE_DISMISS: {
            return { ...state, delete_metertype: null }
        }
        case DISABLE_METERTYPE_PREVIEW: {
            return { ...state, disable_metertype: action.payload }
        }
        case DISABLE_METERTYPE: {
            return { ...state, disable_metertype: null, metertypelist_refetch: true }
        }
        case DISABLE_METERTYPE_DISMISS: {
            return { ...state, disable_metertype: null }
        }
        case SET_METERTYPE_ROLES: {
            return { ...state, metertype_roles: action.payload }
        }
        default:
            return state;
    }
};
