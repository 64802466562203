import {
    ADD_NEW_ASSETMODEL, DELETE_ASSETMODEL, DELETE_ASSETMODEL_DISMISS, DELETE_ASSETMODEL_PREVIEW,
    DISABLE_ASSETMODEL, DISABLE_ASSETMODEL_DISMISS, DISABLE_ASSETMODEL_PREVIEW,
    EDIT_ASSETMODEL, EDIT_ASSETMODEL_DISMISS, EDIT_ASSETMODEL_PREVIEW, SET_ASSETMODEL_DATA,
    ASSETMODEL_FETCHING_COMPLETE, ASSETMODEL_FETCHING_INIT, ASSETMODEL_FETCHING_DISMISS, SET_ASSETMODEL_PAGE,
    ADD_NEW_ASSETMODEL_DISMISS, ADD_NEW_ASSETMODEL_PREVIEW, SET_ASSETMODEL_ROLES,
    ASSETMODEL_CREATE_DETAILS_FETCHING_COMPLETE
} from "./types";

const initialState = {
    assetmodel_list: [],//holds asset model list array
    assetmodellist_fetching: false,//indicaction for fetch in progress
    assetmodellist_refetch: false,//indication to refetch current asset model list array
    assetmodellist_refetch_force: false,//indication to force refetch assset model list array from beginning 
    edit_assetmodel: null,//holds initial data of asset model being edited
    new_assetmodel_popup: null, //holds initial data of asset model being added
    delete_assetmodel: null,//holds initial data of asset model opted to be deleted
    disable_assetmodel: null,//holds initial data of asset model opted to be disabled,
    search: "",//search keyword on asset model listing
    page: 1,//page number on asset model listing
    perpage: 10,//perpage limit on asset model listing
    total_assetmodels: 0,//total count
    //new asset model type params
    asset_model_desc: "",
    asset_type: "",
    asset_manu: "",
    //edit asset model params
    eAsset_model_id: "",
    eAsset_model_desc: "",
    eAsset_type: "",
    eAsset_manu: "",
    asset_manus_list: [],
    asset_types_list: [],
    //delete asset model 
    dAsset_model_id: "",
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ASSETMODEL_CREATE_DETAILS_FETCHING_COMPLETE:
            return { ...state, ...action.payload }
        case SET_ASSETMODEL_DATA:
            return { ...state, ...action.payload };
        case ASSETMODEL_FETCHING_INIT: {
            return { ...state, assetmodellist_fetching: true, assetmodellist_refetch: false, assetmodellist_refetch_force: false };
        }
        case ASSETMODEL_FETCHING_COMPLETE: {
            return { ...state, assetmodellist_fetching: false, ...action.payload };
        }
        case ASSETMODEL_FETCHING_DISMISS: {
            return { ...state, assetmodellist_fetching: false };
        }
        case SET_ASSETMODEL_PAGE: {
            return { ...state, assetmodellist_refetch: true, page: action.payload };
        }
        case ADD_NEW_ASSETMODEL: {
            return { ...state, assetmodellist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_ASSETMODEL_PREVIEW: {
            return { ...state, new_assetmodel_popup: true, asset_model_desc: "" }
        }
        case ADD_NEW_ASSETMODEL_DISMISS: {
            return { ...state, new_assetmodel_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_ASSETMODEL_PREVIEW: {
            return { ...state, eAsset_error: "", ...action.payload }
        }
        case EDIT_ASSETMODEL: {
            return { ...state, assetmodellist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_ASSETMODEL_DISMISS: {
            return { ...state, edit_assetmodel: null }
        }
        case DELETE_ASSETMODEL_PREVIEW: {
            return { ...state, ...action.payload }
        }
        case DELETE_ASSETMODEL: {
            return { ...state, assetmodellist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_ASSETMODEL_DISMISS: {
            return { ...state, delete_assetmodel: null }
        }
        case DISABLE_ASSETMODEL_PREVIEW: {
            return { ...state, disable_assetmodel: action.payload }
        }
        case DISABLE_ASSETMODEL: {
            return { ...state, disable_assetmodel: null, assetmodellist_refetch: true }
        }
        case DISABLE_ASSETMODEL_DISMISS: {
            return { ...state, disable_assetmodel: null }
        }
        case SET_ASSETMODEL_ROLES: {
            return { ...state, assetmodel_roles: action.payload }
        }
        default:
            return state;
    }
};
