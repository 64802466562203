import React from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Loading from "../components/Loading/index"
function PrivateRoute(props) {
  const { component: Component, ...rest } = props;
  //load user authentication status from redux 
  const isAuthenticated = props.authenticated || false;
  // props.authLoading -> indicates user authentication inprogress
  if (props.authLoading) return <Loading />;

  return (
    <Route
      {...rest}
      render={(componentProps) => {
        return isAuthenticated ? (
          <Component {...componentProps} />
        ) : (
            <Redirect
              to={{
                pathname: "/sign-in",
              }}
            />
          );
      }}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  authenticated: PropTypes.bool,
  authLoading: PropTypes.bool,
};

const mapStateToProps = ({ auth }) => ({
  ...auth,
});

export default connect(mapStateToProps)(withRouter(PrivateRoute));
