export const SET_USER_DATA = "SET_USER_DATA";
export const USER_FETCHING_INIT = "USER_FETCHING_INIT"
export const USER_FETCHING_COMPLETE = "USER_FETCHING_COMPLETE"
export const USER_FETCHING_DISMISS = "USER_FETCHING_DISMISS"
export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_NEW_USER_PREVIEW = "ADD_NEW_USER_PREVIEW"
export const ADD_NEW_USER_DISMISS = "ADD_NEW_USER_DISMISS"
export const EDIT_USER_PREVIEW = "EDIT_USER_PREVIEW"
export const EDIT_USER = "EDIT_USER"
export const EDIT_USER_DISMISS = "EDIT_USER_DISMISS"
export const DELETE_USER_PREVIEW = "DELETE_USER_PREVIEW"
export const DELETE_USER_DISMISS = "DELETE_USER_DISMIS"
export const DELETE_USER = "DELETE_USER"
export const DISABLE_USER_PREVIEW = "DISABLE_USER_PREVIEW"
export const DISABLE_USER_DISMISS = "DISABLE_USER_DISMISS"
export const DISABLE_USER = "DISABLE_USER"
export const SET_USER_ROLES = "SET_USER_ROLES"
export const SET_USER_PAGE = "SET_USER_PAGE"
export const ADD_NEW_USER_ERROR = "ADD_NEW_USER_ERROR"
export const SET_USER_COMPANY = "SET_USER_COMPANY"
