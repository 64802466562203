import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import * as History from "history";
import createRootReducer from "./rootReducer";

export const history = History.createBrowserHistory();

const initialState = {};
const enhancers = [];
const middleware = [thunk,];//routerMiddleware(history)

//load redux dev tools if in dev mode
if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  middleware.push(createLogger());
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

export default createStore(
  createRootReducer(history),
  initialState,
  composedEnhancers
);
