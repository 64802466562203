export const FETCH_PERMISSION_DATA_INIT = "FETCH_PERMISSION_DATA_INIT";
export const FETCH_PERMISSION_DATA_COMPLETE = "FETCH_PERMISSION_DATA_COMPLETE";
export const FETCH_PERMISSION_DATA_DISMISS = "FETCH_PERMISSION_DATA_DISMISS";
export const SET_ROLE_PERMISSION = "SET_ROLE_PERMISSION";
export const FETCH_EXISTING_PERMISSION_DATA_INIT = "FETCH_EXISTING_PERMISSION_DATA_INIT";
export const FETCH_EXISTING_PERMISSION_DATA_COMPLETE = "FETCH_EXISTING_PERMISSION_DATA_COMPLETE";
export const FETCH_EXISTING_PERMISSION_DATA_DISMISS = "FETCH_EXISTING_PERMISSION_DATA_DISMISS";
export const SET_TABLE_CELLS = "SET_TABLE_CELLS";
export const FETCH_USER_ROLES_INIT = "FETCH_USER_ROLES_INIT";
export const FETCH_USER_ROLES_COMPLETE = "FETCH_USER_ROLES_COMPLETE";
export const FETCH_USER_ROLES_DISMISS = "FETCH_USER_ROLES_DISMISS";
export const SET_ROLE_PERMISSION_UNCHANGED = "SET_ROLE_PERMISSION_UNCHANGED";
export const SET_PERMISSION_MODULE_DATA = "SET_PERMISSION_MODULE_DATA";
