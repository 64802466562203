import {
    ADD_NEW_RENTALTYPE, DELETE_RENTALTYPE, DELETE_RENTALTYPE_DISMISS, DELETE_RENTALTYPE_PREVIEW,
    DISABLE_RENTALTYPE, DISABLE_RENTALTYPE_DISMISS, DISABLE_RENTALTYPE_PREVIEW,
    EDIT_RENTALTYPE, EDIT_RENTALTYPE_DISMISS, EDIT_RENTALTYPE_PREVIEW, SET_RENTALTYPE_DATA,
    RENTALTYPE_FETCHING_COMPLETE, RENTALTYPE_FETCHING_INIT, RENTALTYPE_FETCHING_DISMISS, SET_RENTALTYPE_PAGE,
    ADD_NEW_RENTALTYPE_DISMISS, ADD_NEW_RENTALTYPE_PREVIEW, SET_RENTALTYPE_ROLES
} from "./types";

const initialState = {
    rentaltype_list: [],//holds rental type list array
    rentaltypelist_fetching: false,//indicaction for fetch in progress
    rentaltypelist_refetch: false,//indication to refetch current rental type list array
    rentaltypelist_refetch_force: false,//indication to force refetch rental type list array from beginning 
    edit_rentaltype: null,//holds initial data of rental type being edited
    delete_rentaltype: null,//holds initial data of rental type opted to be deleted
    disable_rentaltype: null,//holds initial data of rental type opted to be disabled,
    search: "",//search keyword on rental type listing
    page: 1,//page number on rental type listing
    perpage: 10,//perpage limit on rental type listing
    total_rentaltypes: 0,//total count
    //new rental type params
    rental_type_desc: "",
    rental_type_code: "",
    //edit rental type params
    eRental_type_desc: ""
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_RENTALTYPE_DATA:
            return { ...state, ...action.payload };
        case RENTALTYPE_FETCHING_INIT: {
            return { ...state, rentaltypelist_fetching: true, rentaltypelist_refetch: false, rentaltypelist_refetch_force: false };
        }
        case RENTALTYPE_FETCHING_COMPLETE: {
            return { ...state, rentaltypelist_fetching: false, ...action.payload };
        }
        case RENTALTYPE_FETCHING_DISMISS: {
            return { ...state, rentaltypelist_fetching: false };
        }
        case SET_RENTALTYPE_PAGE: {
            return { ...state, rentaltypelist_refetch: true, page: action.payload };
        }
        case ADD_NEW_RENTALTYPE: {
            return { ...state, rentaltypelist_refetch: true, search: "", add_btn_disable: false }
        }
        case ADD_NEW_RENTALTYPE_PREVIEW: {
            return { ...state, new_rentaltype_popup: true, rental_type_desc: "", rental_type_code: "" }
        }
        case ADD_NEW_RENTALTYPE_DISMISS: {
            return { ...state, new_rentaltype_popup: false, ...action.payload, add_btn_disable: false }
        }
        case EDIT_RENTALTYPE_PREVIEW: {
            return { ...state, eRental_error: "", ...action.payload }
        }
        case EDIT_RENTALTYPE: {
            return { ...state, rentaltypelist_refetch: true, edit_btn_disable: false, search: "" }
        }
        case EDIT_RENTALTYPE_DISMISS: {
            return { ...state, edit_rentaltype: null }
        }
        case DELETE_RENTALTYPE_PREVIEW: {
            return { ...state, delete_rentaltype: action.payload }
        }
        case DELETE_RENTALTYPE: {
            return { ...state, rentaltypelist_refetch_force: true, search: "", del_btn_disable: false }
        }
        case DELETE_RENTALTYPE_DISMISS: {
            return { ...state, delete_rentaltype: null }
        }
        case DISABLE_RENTALTYPE_PREVIEW: {
            return { ...state, disable_rentaltype: action.payload }
        }
        case DISABLE_RENTALTYPE: {
            return { ...state, disable_rentaltype: null, rentaltypelist_refetch: true }
        }
        case DISABLE_RENTALTYPE_DISMISS: {
            return { ...state, disable_rentaltype: null }
        }
        case SET_RENTALTYPE_ROLES: {
            return { ...state, rentaltype_roles: action.payload }
        }
        default:
            return state;
    }
};
